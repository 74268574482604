import { uploadImage } from 'api/upload';
import ConfirmPopup from 'components/ConfirmPopup';
import Media from 'components/Media';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useRef, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { AiFillEye } from 'react-icons/ai';
import { BiCalendarEvent } from 'react-icons/bi';
import { BsDashLg, BsFillImageFill } from 'react-icons/bs';
import Resizer from 'react-image-file-resizer';
import { useHistory } from 'react-router-dom';

function OccasionForm(props) {
    const occasionImageRef = useRef();
    const [showModal, setShowModal] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const history = useHistory();

    const uploadOccasionImage = useCallback(async (image) => {
        try {
            setUploading(true);

            const uploadResponse = await uploadImage(image);

            props.setValue('image', uploadResponse);
        } finally {
            setUploading(false);
        }
    }, [props]);

    const occasionImageHandler = useCallback(async (event) => {
        if (event.target.files[0]) {
            try {
                Resizer.imageFileResizer(event.target.files[0], 2048, 2048, 'PNG', 85, 360, (uri) => uploadOccasionImage(uri));
            } catch (err) {
            }
        }
    }, [uploadOccasionImage]);

    const renderLanguage = useCallback(() => {
        if (props.fields?.language === 'en') {
            return <div className='challenge-type selected-type-text'>English</div>;
        } else if (props.fields?.language === 'id') {
            return <div className='challenge-type selected-type-text'>Indo</div>;
        } else {
            return <div className='challenge-type selected-type-text'>All</div>;
        }
    }, [props.fields?.language]);

    return (
        <div className='form-wrapper'>
            <Form>
                <div className='dates-input-wrapper'>
                    <Form.Group className='mb-3' controlId='challengeHashtag'>
                        <Form.Label className='form-label'>Start Date</Form.Label>
                        <div className='date-wrapper'>
                            <DatePicker className='date-wrapper-input' selected={props.fields?.from_date}
                                onSelect={(date) => props.setValue('from_date', date)}
                                onChange={(date) => props.setValue('from_date', date)} />
                            <BiCalendarEvent size={18} color='#838383' />
                        </div>
                        {props.errors?.from_date?.message && <Form.Text className='form-error-message'>{props.errors?.from_date?.message}</Form.Text>}
                    </Form.Group>
                    <BsDashLg size={18} color='#838383' />
                    <Form.Group className='mb-3' controlId='challengeHashtag'>
                        <Form.Label className='form-label'>End Date</Form.Label>
                        <div className='date-wrapper'>
                            <DatePicker className='date-wrapper-input' selected={props.fields?.to_date}
                                onSelect={(date) => props.setValue('to_date', date)}
                                onChange={(date) => props.setValue('to_date', date)} />
                            <BiCalendarEvent size={18} color='#838383' />
                        </div>
                    </Form.Group>
                </div>

                <Form.Group className='mb-3' controlId='challengeName'>
                    <Form.Label className='form-label'>Collection Name</Form.Label>
                    <input value={props.fields?.name} onChange={(e) => props.setValue('name', e.target.value)}
                        className='form-input' type='text' placeholder='Enter Collection Name' />
                    {props.errors?.name?.message && <Form.Text className='form-error-message'>{props.errors?.name?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeType'>
                    <Form.Label className='form-label'>Language</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>{renderLanguage()}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => props.setValue('language', null)}><span className={!props.fields?.language ? 'color-brand-imp' : ''}>All</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setValue('language', 'en')}><span className={props.fields?.language === 1 ? 'color-brand-imp' : ''}>English</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setValue('language', 'id')}><span className={props.fields?.language === 2 ? 'color-brand-imp' : ''}>Indo</span></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeName'>
                    <Form.Label className='form-label'>Order</Form.Label>
                    <input value={props.fields?.order} onChange={(e) => props.setValue('order', e.target.value)}
                        className='form-input' type='number' placeholder='Enter Order' />
                    {props.errors?.order?.message && <Form.Text className='form-error-message'>{props.errors?.order?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeName'>
                    <Form.Label className='form-label'>Recipe IDs</Form.Label>
                    <input value={props.fields?.recipes} onChange={(e) => props.setValue('recipes', e.target.value)}
                        className='form-input' type='text' placeholder='Enter Recipe Ids in {id,id,id} format.' />
                    {props.errors?.recipes?.message && <Form.Text className='form-error-message'>{props.errors?.recipes?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Image <span className='form-label-info'>(Less than 1mb, 1:1 or Square)</span></Form.Label>
                    {props.fields?.image
                        ? <Media onClose={() => props.setValue('image', '')} src={props.fields?.image} alt='Challenge'
                            className='form-banner-image' />
                        : <div onClick={() => occasionImageRef.current.click()} className={`image-placeholder-wrapper hoverable ${isUploading ? 'disabled' : ''}`}>
                            <BsFillImageFill color='#bdbdbd' size={40} />
                            <span>Upload imgae.</span>
                        </div>}
                    <input type='file' multiple={false} ref={occasionImageRef}
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={occasionImageHandler} />
                    {props.errors?.image?.message && <Form.Text className='form-error-message'>{props.errors?.image?.message}</Form.Text>}
                </Form.Group>
            </Form>
            <div className='form-footer'>
                <Button variant='brand-red-hollow' onClick={props.showPreview} ><AiFillEye color='#FF7268' />  Preview</Button>
                <div>
                    <Button variant='brand-gray mx-2' onClick={() => setShowModal(true)}>Cancel</Button>
                    <Button variant='brand-red mx-2' onClick={props.saveHandler}>Save Collection</Button>
                </div>
            </div>
            <ConfirmPopup
                isVisible={showModal}
                onClose={() => setShowModal(false)}
                confirmTitle='Leave'
                onConfirm={history.goBack}
                title='Unsaved Changes'
                description='Are you sure want to discard this changes? Changes you made may not be saved.'
                type={1} />
        </div>
    );
}

OccasionForm.propTypes = {
    fields: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    saveHandler: PropTypes.func.isRequired,
    showPreview: PropTypes.func.isRequired
};

export default memo(OccasionForm);
