import { getById } from 'api/seller';
import { imageEndpoints } from 'config/constants';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getParsedDate } from 'utils/helpers';

function BrandPortal() {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            const response = await getById(id);

            if (response) {
                setData(response);
            }
        } catch {
            toast.error('Unable to fetch Brand', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function openInNewTab(url) {
        if (url) {
            window.open(url, '_blank').focus();
        }
    }

    const promoClickHandler = useCallback(() => {
        if (data?.brand_link) {
            openInNewTab(data?.brand_link);
        }
    }, [data?.brand_link]);

    return (
        <>
            <Helmet>
                <title>{`${data?.name} - Admin YoRipe`}</title>
                <meta name='title' content={`${data?.name} - Admin YoRipe`} />
                <meta name='description' content={'Brand page to manage all existing Brands and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>View Brand Portal</div>
                    {isLoading && <div className='challenge-header'><Spinner animation='border' variant='red-1' /></div>}
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Brand Name</div>
                        <div className='challenge-section-content'>{data?.name}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Brand Portal Logo</div>
                        <div className='image-grid-container'><img src={imageEndpoints.brandPortalSellerUrl + data?.brand_portal_logo_image} alt='recipe media' /></div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Brand Portal Image</div>
                        <div className='image-grid-container'><img src={imageEndpoints.brandPortalCoverUrl + data?.brand_portal_image} alt='recipe media' /></div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Description</div>
                        <p className='challenge-section-content'>{data?.description}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Language</div>
                        <p className='challenge-section-content'>{!data?.language && 'All'}{data?.language === 'en' && 'English'}{data?.language === 'id' && 'Indo'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Veg Only</div>
                        <p className='challenge-section-content'>{data?.veg_only ? 'True' : 'False'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Delivery Time</div>
                        <p className='challenge-section-content'>{data?.delivery_time ? data?.delivery_time : 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Discount Code</div>
                        <p className='challenge-section-content'>{data?.discount_code ? data?.discount_code : 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Disc. Percentage</div>
                        <p className='challenge-section-content'>{data?.discount_percentage ? data?.discount_percentage : 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Expiration Date</div>
                        <p className='challenge-section-content'>{data?.expiration_date ? getParsedDate(data?.expiration_date) : 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Promo site</div>
                        <p onClick={promoClickHandler} className='challenge-section-content'>{data?.brand_link ? data?.brand_link : 'null'}</p>
                    </div>
                    <div className='challenge-footer'>
                        <Link to={`/brand-portal-edit/${data?.id}`}><Button variant='brand-red mx-2'>Edit Brand</Button></Link>
                        <Button variant='brand-gray mx-2' onClick={history.goBack}>Back</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(BrandPortal);
