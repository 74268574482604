import { getById } from 'api/occasion';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getParsedDate } from 'utils/helpers';

function Recipe() {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            const response = await getById(id);

            if (response) {
                setData(response);
            }
        } catch {
            toast.error('Unable to fetch Collection', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title>{`${data?.name} - Admin YoRipe`}</title>
                <meta name='title' content={`${data?.name} - Admin YoRipe`} />
                <meta name='description' content={'Occasions Form page to manage all existing Occasions and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>View Top Collection</div>
                    {isLoading && <div className='challenge-header'><Spinner animation='border' variant='red-1' /></div>}
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>From When</div>
                        <div className='challenge-section-content'>{data?.from_date ? getParsedDate(data.from_date) : 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>To When</div>
                        <div className='challenge-section-content'>{data?.to_date ? getParsedDate(data.to_date) : 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Collection Name</div>
                        <div className='challenge-section-content'>{data?.name}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Orders</div>
                        <div className='challenge-section-content'>{data?.order}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Recipe IDs</div>
                        <div className='challenge-section-content'>{data?.recipes?.map(recipe => recipe.recipe_id).toString() ?? null}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Image</div>
                        <div className='image-grid-container'><img src={data?.image} alt='recipe media' /></div>
                    </div>
                    <div className='challenge-footer'>
                        <Link to={`/top-collection-edit/${data?.id}`}><Button variant='brand-red mx-2'>Edit Top Collection</Button></Link>
                        <Button variant='brand-gray mx-2' onClick={history.goBack}>Back</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(Recipe);
