import { createBanner, getById, updateBanner } from 'api/banner';
import BannerPreview from 'components/BannerPreview';
import React, { memo, useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import DataForm from './components/DataForm';

const validateNavigation = {
    validate: value => {
        if (value.challengeId && value.hashtag) {
            return true;
        }

        return 'Please select a Challenge';
    }
};

const getParsedLocation = (location) => {
    if (location === 1) {
        return 'Shop';
    } else if (location === 2) {
        return 'Challenge';
    } else if (location === 3) {
        return 'Search';
    } else {
        return '';
    }
};

function BannerForm() {
    const [showPreview, setShowPreview] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const [parsedData, setParsedData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { id } = useParams();
    const history = useHistory();
    const { register, watch, setValue, clearErrors, trigger, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            image: null,
            location: 2,
            from_date: new Date(),
            to_date: null,
            order: null,
            navigation_params: { challengeId: null, hashtag: null },
            type: 2,
            url: null,
            language: null
        }
    });

    const fields = watch();

    useEffect(() => {
        register('location', { required: 'This field is Required' });
        register('image', { required: 'This field is Required' });
        register('navigation_params', validateNavigation);
        register('order', { required: 'This field is Required' });
        register('from_date', { required: 'This field is Required' });
        register('language');
        register('to_date');
        register('url', { required: 'Please enter a URL' });
        register('type');
    }, [register]);

    const fetchData = useCallback(async () => {
        try {
            const data = await getById(id);

            if (data) {
                const start = data?.from_date?.split('-').map(item => parseInt(item));
                const end = data?.to_date?.split('-').map(item => parseInt(item));
                const fromDate = data?.from_date ? new Date(start[0], start[1] - 1, start[2]) : new Date();
                const toDate = data?.to_date ? new Date(end[0], end[1] - 1, end[2]) : null;

                setValue('id', id);
                setValue('location', data?.location);
                setValue('image', data?.image);
                setValue('order', data?.order);
                setValue('from_date', fromDate);
                setValue('to_date', toDate);
                setValue('language', data?.language);
                setValue('type', data?.type);
                setValue('url', data?.url);
                setValue('navigation_params', data?.navigation_params ? JSON.parse(data?.navigation_params) : { challengeId: null, hashtag: null });
            }
        } catch {
            toast.error('Unable to fetch Banner', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }, [id, setValue]);

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [fetchData, id]);

    const parseChallengeData = useCallback(() => {
        if (fields?.type === 1) {
            const parsedData = {
                location: fields?.location,
                url: fields?.url,
                image: fields?.image,
                order: parseInt(fields?.order),
                from_date: fields?.from_date,
                to_date: fields?.to_date,
                language: fields?.language,
                type: fields?.type,
                app_page: getParsedLocation(fields?.location)
            };

            return parsedData;
        } else if (fields?.type === 2) {
            const parsedData = {
                location: fields?.location,
                navigation_params: fields?.navigation_params,
                image: fields?.image,
                order: parseInt(fields?.order),
                from_date: fields?.from_date,
                to_date: fields?.to_date,
                language: fields?.language,
                type: fields?.type,
                app_page: getParsedLocation(fields?.location),
                challenge_id: fields?.navigation_params?.challengeId
            };

            return parsedData;
        }
    }, [fields]);

    const previewHandler = useCallback(async () => {
        setParsedData(parseChallengeData());
        setShowPreview(true);
    }, [parseChallengeData]);

    const saveHandler = useCallback(async () => {
        clearErrors();

        const testArr = fields?.type === 2 ? ['location', 'image', 'order', 'from_date', 'to_date', 'navigation_params'] : ['location', 'image', 'url', 'order', 'from_date', 'to_date'];

        const isValid = await trigger(testArr);

        if (isValid) {
            previewHandler();
            setIsValidated(true);
        }
    }, [clearErrors, fields?.type, previewHandler, trigger]);

    const editSaveHandler = useCallback(async () => {
        try {
            setIsSubmitting(true);

            const data = await updateBanner(id, parsedData);

            if (data.id) {
                toast.success('Thank you. Your Changes has been saved.', {
                    position: toast.POSITION.BOTTOM_LEFT
                });

                history.push(`/banner/${data.id}`);
            }
        } catch {
            toast.error('Unable to save the changes ', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsSubmitting(false);
        }
    }, [history, id, parsedData]);

    const submitBannerHandler = useCallback(async () => {
        if (id) {
            editSaveHandler();
        } else {
            try {
                setIsSubmitting(true);

                const data = await createBanner(parsedData);

                if (data.id) {
                    toast.success('Thank you. Your Banner has been submitted.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    });

                    history.push(`/banner/${data.id}`);
                }
            } catch {
                toast.error('Unable to publish the banner ', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            } finally {
                setIsSubmitting(false);
            }
        }
    }, [editSaveHandler, history, id, parsedData]);

    return (
        <>
            <Helmet>
                <title>Create Banners - YoRipe</title>
                <meta name='title' content='Create Banners - YoRipe' />
                <meta name='description' content={'Banners Form page to manage all existing banners and create more!'} />
            </Helmet>
            <div className={`page-wrapper ${isSubmitting ? 'disabled' : ''}`}>
                {showPreview
                    ? <BannerPreview
                        data={parsedData}
                        isValidated={isValidated}
                        onBack={() => setShowPreview(false)}
                        onSubmit={submitBannerHandler} />
                    : <DataForm
                        fields={fields}
                        errors={errors}
                        setValue={setValue}
                        saveHandler={saveHandler}
                        showPreview={previewHandler}/>}
            </div>
        </>
    );
}

export default memo(BannerForm);
