import { searchReview } from 'api/review';
import ConfirmPopup from 'components/ConfirmPopup';
import ROUTE_PATHS from 'config/routePaths';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import { deleteStory } from 'utils/adminDelete';

function RecipeScreen() {
    const [query, setQuery] = useState('');
    const [searchData, setSerachData] = useState({ page: 1, isLoading: false, data: [] });
    const [selectedItems, setSelectedItems] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();

    const visiblePages = useMemo(() => {
        const startPages = searchData?.currentPage > 2 ? [1, searchData?.currentPage - 1] : [1];
        const endPages = (searchData?.lastPage - searchData?.currentPage) > 1 ? [searchData?.currentPage + 1, searchData?.lastPage] : [searchData?.lastPage];

        if (searchData?.lastPage < 4) {
            return [...Array.from({ length: searchData?.lastPage }, (_, i) => i + 1)];
        } else {
            return [...startPages, searchData?.currentPage, ...endPages];
        }
    }, [searchData?.currentPage, searchData?.lastPage]);

    const deleteCallback = useCallback((ids) => {
        const newArr = searchData.data.filter(item => !ids.includes(item.id));

        setSerachData((prev) => ({ ...prev, data: newArr }));
        setSelectedItems([]);
        setShowModal(false);
    }, [searchData]);

    const deleteHandler = useCallback(() => {
        const data = searchData.data.filter((item, index) => {
            return selectedItems.includes(index);
        });

        const ids = data.map(item => item.id);

        deleteStory(ids, () => deleteCallback(ids));
    }, [deleteCallback, searchData, selectedItems]);

    const selectHandler = (index) => {
        if (selectedItems.includes(index)) {
            const filtered = selectedItems.filter((item) => item !== index);

            setSelectedItems([...filtered]);
        } else {
            setSelectedItems((prev) => [...prev, index]);
        }
    };

    useEffect(() => {
        if (query) {
            const timeoutId = setTimeout(() => {
                fetchStoriesSearch(1);
            }, 500);

            return () => clearTimeout(timeoutId);
        } else {
            fetchStoriesSearch(1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const fetchStoriesSearch = useCallback(async (page) => {
        try {
            setSerachData((prev) => ({ ...prev, isLoading: true }));

            const { data, totalPages } = await searchReview(query, page);

            setSerachData({ currentPage: page, lastPage: totalPages, isLoading: false, data: [...data] });
        } catch {
            setSerachData((prev) => ({ ...prev, isLoading: false }));
        }
    }, [query]);

    const handleQueryChange = (e) => {
        setQuery(e.target.value);
    };

    const nextPageHandler = () => {
        if (searchData.currentPage < searchData.lastPage) {
            fetchStoriesSearch(searchData.currentPage + 1);
            setSelectedItems([]);
        }
    };

    const prevPageHandler = () => {
        if (searchData.currentPage > 1) {
            fetchStoriesSearch(searchData.currentPage - 1);
            setSelectedItems([]);
        }
    };

    const pageSelectHandler = useCallback((page) => {
        fetchStoriesSearch(page);
    }, [fetchStoriesSearch]);

    const renderImages = (images) => {
        return (
            <div className='recipe-image-container'>
                {images?.slice(0, 3)?.map((image) => <img src={image} key={image} className='recipe-image-icon'
                    alt='recipe' />)}
                {images?.length > 3 && <div className='recipe-image-more'>{images.length - 3}+</div>}
            </div>
        );
    };

    const returnPhotos = (photos, thumbnail) => {
        let arr = [];

        if (photos) {
            arr = [...photos];
        }

        if (thumbnail) {
            arr = [...arr, thumbnail];
        }

        return arr;
    };

    const renderChallenge = (item, index) => {
        const images = returnPhotos(item?.photos, item?.video_thumbnail);

        const openItem = () => {
            history.push(`/cooking-story/${item.id}`);
        };

        return (
            <tr onClick={openItem} key={item.id}>
                <td><input onClick={(e) => e.stopPropagation()} type='checkbox' checked={selectedItems.includes(index)}
                    onChange={() => selectHandler(index)} /></td>
                <td className='align-left'>{renderImages(images)}</td>
                <td><Link to={`/cooking-story/${item.id}`} className='story-description'>{item.title ?? 'null'}</Link></td>
                <td><span className='story-description'>{item.comment ?? 'null'}</span></td>
                <td><span className='story-description'>{item.challenges?.length > 0 ? item.challenges[0]?.hashtag : 'null'}</span></td>
                <td><span className='story-description'>{item.recipe?.recipename ?? 'null'}</span></td>
            </tr>
        );
    };

    const renderPages = useCallback(() => {
        if (searchData?.lastPage) {
            const pages = Array.from({ length: searchData?.lastPage }, (_, i) => i + 1);

            return (
                <>{pages.map(item => {
                    if (visiblePages.includes(item)) {
                        return <Button key={item} variant={searchData.currentPage === item ? 'red-hollow' : 'gray_hollow'} onClick={() => pageSelectHandler(item)}>{item}</Button>;
                    } else if (searchData?.currentPage - 2 === item || searchData.currentPage + 2 === item) {
                        return <span key={item} className='gray_hollow'>...</span>;
                    }

                    return null;
                })}</>
            );
        }
    }, [pageSelectHandler, searchData.currentPage, searchData?.lastPage, visiblePages]);

    return (
        <>
            <Helmet>
                <title>Admin Cooking Story - YoRipe</title>
                <meta name='title' content='Admin Cooking Story - YoRipe' />
                <meta name='description' content={'Admin page to manage all existing Cooking Stories and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenges-header'>
                    <div className='search-input-wrapper'>
                        <input value={query} onChange={handleQueryChange} placeholder='Search' />
                        <BiSearch color='#818181' size={20} />
                    </div>
                    <Link to={ROUTE_PATHS.COOKING_STORY_CREATE} className='no-link challenges-header-create hoverable'>+ Add Cooking Story</Link>
                </div>
                <div className='data-actions-wrapper'>
                    <Button variant='danger' onClick={() => setShowModal(true)} disabled={selectedItems.length < 1}>Delete</Button>
                </div>
                {searchData.isLoading && <div className='data-actions-wrapper'><Spinner animation='border' variant='red-1' /></div>}
                <div className='challenge-data-wrapper'>
                    <Table bordered={false} hover={true}>
                        <thead>
                            <tr>
                                <th/>
                                <th>Image Assets</th>
                                <th>Title</th>
                                <th>Caption</th>
                                <th>Challenge Tag</th>
                                <th>Recipe Tag</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchData.data.map(renderChallenge)}
                        </tbody>
                    </Table>
                </div>
                <div className='data-table-footer'>
                    <div className='page-info'>Showing ({10 * (searchData.currentPage - 1) + 1} to {10 * (searchData.currentPage - 1) + searchData.data.length}) - Page {searchData.currentPage} out of {searchData.lastPage}</div>
                    <div className='pagination-wrapper'>
                        <Button variant='red-hollow' disabled={searchData.currentPage === 1} onClick={prevPageHandler}>Prev</Button>{' '}
                        {renderPages()}
                        <Button variant='red-hollow' disabled={searchData.currentPage === searchData.lastPage} onClick={nextPageHandler}>Next</Button>
                    </div>
                </div>
                <ConfirmPopup
                    isVisible={showModal}
                    onClose={() => setShowModal(false)}
                    onConfirm={deleteHandler}
                    confirmTitle='Delete'
                    title='Delete confirmation'
                    description='Items once deleted cannot be recovered! Are you sure you want to delete?'
                    type={1} />
            </div></>
    );
}

export default memo(RecipeScreen);
