import { AdminApi } from './api';

/**
 * Get searched organizers
 * @param {String} query
 * @param {Number} page
 * @param {Number} perPage
 * @returns {Promise}
 */
export function searchOrganizers(query, page, perPage = 15) {
    return AdminApi.get('/v1/organizers/search', { params: { query, page, perPage } });
}

/**
 * Get searched ingredients
 * @param {String} query
 * @param {Number} page
 * @param {Number} perPage
 * @returns {Promise}
 */
export function searchIngredients(query, page, perPage = 15) {
    return AdminApi.get('/v1/ingredients/search', { params: { query, page, perPage } });
}

/**
 * Get all common ingredients
 * @returns {Promise}
 */
export function getAllCommonIngredients() {
    return AdminApi.get('/v1/common-ingredients/all');
}

/**
 * Get all cuisines
 * @returns {Promise}
 */
export function getAllCuisines() {
    return AdminApi.get('/v1/cuisines/all');
}

/**
 * Get all equipments
 * @returns {Promise}
 */
export function getAllEquipments() {
    return AdminApi.get('/v1/equipments/all');
}

/**
 * Get all feelings
 * @returns {Promise}
 */
export function getAllFeelings() {
    return AdminApi.get('/v1/feelings/all');
}

/**
 * Get all meals
 * @returns {Promise}
 */
export function getAllMeals() {
    return AdminApi.get('/v1/meals/all');
}
