import { createProduct, getById, updateProduct } from 'api/product';
import ProductPreview from 'components/ProductPreview';
import React, { memo, useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import DataForm from './components/DataForm';

function validateSection(data) {
    if (data.length === 0) {
        return 'This field is Required';
    }

    let isEmpty = false;

    data.forEach(section => {
        if (!section.title || !section.body) {
            isEmpty = true;
        }
    });

    if (isEmpty) {
        return 'Please fill all the sections';
    }

    return undefined;
}

function ProductCreate() {
    const [showPreview, setShowPreview] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const { id } = useParams();
    const [parsedData, setParsedData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();
    const { register, watch, setValue, clearErrors, trigger, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            name: '',
            description: '',
            seller_id: null,
            seller: null,
            equipment: null,
            ingredient: null,
            ingredient_id: null,
            equipment_id: null,
            price: '',
            link: '',
            dynamic_sections: [{ title: '', body: '' }],
            recipes: '',
            images: [],
            currency: '$'
        }
    });

    const fields = watch();

    useEffect(() => {
        register('name', { required: 'This field is Required' });
        register('description', { required: 'This field is Required' });
        register('seller_id', { required: 'This field is Required' });
        register('dynamic_sections', validateSection);
        register('price', { required: 'This field is Required' });
        register('link', { required: 'This field is Required' });
        register('ingredient_id');
        register('equipment_id');
        register('recipes');
        register('images', { required: 'This field is Required' });
    }, [register]);

    const fetchData = useCallback(async () => {
        try {
            const data = await getById(id);

            if (data) {
                const dynamicSection = data?.dynamic_sections?.map(item => {
                    return { title: item.title, body: item?.body ? JSON.parse(item.body) : '' };
                });

                setValue('id', id);
                setValue('name', data?.name);
                setValue('description', data?.description);
                setValue('currency', data?.currency);
                setValue('price', data?.price);
                setValue('link', data?.link);
                setValue('equipment', data?.equipment);
                setValue('ingredient', data?.ingredient);
                setValue('seller', data?.seller);
                setValue('dynamic_sections', dynamicSection);
                setValue('recipes', data?.recipes);
                setValue('images', data?.images ? JSON.parse(data?.images) : []);
                setValue('order', data?.order);
                setValue('language', data?.language);
                setValue('type', data?.type);
                setValue('url', data?.url);
                setValue('navigation_params', data?.navigation_params ? JSON.parse(data?.navigation_params) : { challengeId: null, hashtag: null });
            }
        } catch {
            toast.error('Unable to fetch Product', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }, [id, setValue]);

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [fetchData, id]);

    const parseChallengeData = useCallback(() => {
        const parsedData = {
            name: fields?.name,
            description: fields?.description,
            currency: fields?.price ? fields?.currency : '',
            price: fields?.price,
            link: fields?.link,
            ingredient_id: fields?.ingredient?.id,
            seller: fields?.seller,
            equipment: fields?.equipment,
            ingredient: fields?.ingredient,
            images: fields?.images,
            seller_id: fields?.seller?.id,
            equipment_id: fields?.equipment?.id,
            dynamic_title1: fields?.dynamic_sections[0]?.title,
            dynamic_body1: fields?.dynamic_sections[0]?.body,
            dynamic_title2: fields?.dynamic_sections[1]?.title,
            dynamic_body2: fields?.dynamic_sections[1]?.body,
            dynamic_title3: fields?.dynamic_sections[2]?.title,
            dynamic_body3: fields?.dynamic_sections[2]?.body,
            dynamic_title4: fields?.dynamic_sections[3]?.title,
            dynamic_body4: fields?.dynamic_sections[3]?.body,
            dynamic_title5: fields?.dynamic_sections[4]?.title,
            dynamic_body5: fields?.dynamic_sections[4]?.body,
            recipes: fields?.recipes
        };

        return parsedData;
    }, [fields]);

    const previewHandler = useCallback(async () => {
        setParsedData(parseChallengeData());
        setShowPreview(true);
    }, [parseChallengeData]);

    const saveHandler = useCallback(async () => {
        clearErrors();

        const isValid = await trigger(['name', 'description', 'price', 'dynamic_sections', 'link', 'images']);

        if (isValid) {
            previewHandler();
            setIsValidated(true);
        }
    }, [clearErrors, previewHandler, trigger]);

    const editSaveHandler = useCallback(async () => {
        try {
            setIsSubmitting(true);

            const submitData = {
                name: fields?.name,
                description: fields?.description,
                currency: fields?.price ? fields?.currency : '',
                price: fields?.price,
                link: fields?.link,
                ingredient_id: fields?.ingredient?.id,
                images: fields?.images,
                seller_id: fields?.seller?.id,
                equipment_id: fields?.equipment?.id,
                dynamic_title1: fields?.dynamic_sections[0]?.title,
                dynamic_body1: fields?.dynamic_sections[0]?.body ?? undefined,
                dynamic_title2: fields?.dynamic_sections[1]?.title,
                dynamic_body2: fields?.dynamic_sections[1]?.body ?? undefined,
                dynamic_title3: fields?.dynamic_sections[2]?.title,
                dynamic_body3: fields?.dynamic_sections[2]?.body ?? undefined,
                dynamic_title4: fields?.dynamic_sections[3]?.title,
                dynamic_body4: fields?.dynamic_sections[3]?.body ?? undefined,
                dynamic_title5: fields?.dynamic_sections[4]?.title,
                dynamic_body5: fields?.dynamic_sections[4]?.body ?? undefined,
                recipes: fields?.recipes
            };

            const data = await updateProduct(id, submitData);

            if (data.id) {
                toast.success('Thank you. Your Changes has been saved.', {
                    position: toast.POSITION.BOTTOM_LEFT
                });

                history.push(`/products/${data.id}`);
            }
        } catch {
            toast.error('Unable to save the changes ', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsSubmitting(false);
        }
    }, [fields?.currency, fields?.description, fields?.dynamic_sections, fields?.equipment?.id, fields?.images, fields?.ingredient?.id, fields?.link, fields?.name, fields?.price, fields?.recipes, fields?.seller?.id, history, id]);

    const submitDataHandler = useCallback(async () => {
        if (id) {
            editSaveHandler();
        } else {
            try {
                setIsSubmitting(true);

                const submitData = {
                    name: fields?.name,
                    description: fields?.description,
                    currency: fields?.price ? fields?.currency : '',
                    price: fields?.price ? parseFloat(fields?.price) : undefined,
                    link: fields?.link,
                    ingredient_id: fields?.ingredient?.id,
                    images: fields?.images,
                    seller_id: fields?.seller?.id,
                    equipment_id: fields?.equipment?.id,
                    dynamic_title1: fields?.dynamic_sections[0]?.title,
                    dynamic_body1: fields?.dynamic_sections[0]?.body ?? undefined,
                    dynamic_title2: fields?.dynamic_sections[1]?.title,
                    dynamic_body2: fields?.dynamic_sections[1]?.body ?? undefined,
                    dynamic_title3: fields?.dynamic_sections[2]?.title,
                    dynamic_body3: fields?.dynamic_sections[2]?.body ?? undefined,
                    dynamic_title4: fields?.dynamic_sections[3]?.title,
                    dynamic_body4: fields?.dynamic_sections[3]?.body ?? undefined,
                    dynamic_title5: fields?.dynamic_sections[4]?.title,
                    dynamic_body5: fields?.dynamic_sections[4]?.body ?? undefined,
                    recipes: fields?.recipes
                };

                const data = await createProduct(submitData);

                if (data.id) {
                    toast.success('Thank you. Your Product has been submitted.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    });

                    history.push(`/products/${data.id}`);
                }
            } catch (err) {
                toast.error('Unable to publish the Product ', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            } finally {
                setIsSubmitting(false);
            }
        }
    }, [editSaveHandler, fields?.currency, fields?.description, fields?.dynamic_sections, fields?.equipment?.id, fields?.images, fields?.ingredient?.id, fields?.link, fields?.name, fields?.price, fields?.recipes, fields?.seller?.id, history, id]);

    return (
        <>
            <Helmet>
                <title>Create Product - YoRipe</title>
                <meta name='title' content='Create Product - YoRipe' />
                <meta name='description' content={'Admin Form page to manage all existing Product and create more!'} />
            </Helmet>
            <div className={`page-wrapper ${isSubmitting ? 'disabled' : ''}`}>
                {showPreview
                    ? <ProductPreview
                        data={parsedData}
                        isValidated={isValidated}
                        onBack={() => setShowPreview(false)}
                        onSubmit={submitDataHandler} />
                    : <DataForm
                        fields={fields}
                        errors={errors}
                        setValue={setValue}
                        saveHandler={saveHandler}
                        showPreview={previewHandler}/>}
            </div>
        </>
    );
}

export default memo(ProductCreate);
