import './index.scss';

import YoripeLogo from 'images/YoRipe_logo_icon_black.png';
import React, { memo, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { validateEmail } from 'utils/helpers';
import { handleLoginViaEmail } from 'utils/redux/modules/authentication';

function Login() {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const onSignInEmail = () => {
        dispatch(handleLoginViaEmail(email, pass, loginCallback));
    };

    const loginCallback = () => {
    };

    const signInHadnler = () => {
        const isValidEmail = validateEmail(email);

        if (!isValidEmail) {
            toast.error('Please enter a valid Email', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (pass === '') {
            toast.error('Invalid Password', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else {
            onSignInEmail();
        }
    };

    const passChangeHandler = (val) => {
        if (val[val.length - 1] !== ' ') {
            setPass(val);
        }
    };

    return (
        <div className='fullscreen-page-container'><div className='login-wrapper'>
            <img src={YoripeLogo} alt='yoripe logo'className='login-wrapper-logo' />
            <div className='login-wrapper-title'>Welcome to Yoripe</div>
            <div className='login-wrapper-info'>Admin Panel, Please log in. :)</div>
            <div className='input-wrapper'>
                <div className='input-title'>
                    <span>Email</span>
                </div>
                <input value={email} onChange={(e) => setEmail(e.target.value)} className='input-base' />
            </div>
            <div className='input-wrapper'>
                <div className='input-title'>
                    <span>Password</span>
                </div>
                <input type={showPassword ? 'text' : 'password'} value={pass} onChange={(e) => passChangeHandler(e.target.value)}
                    className='input-base' />
                <div onClick={() => setShowPassword(prev => !prev)} className='input-wrapper-icon'>
                    {showPassword
                        ? <AiOutlineEyeInvisible style={{ width: '20px', height: '20px' }}/>
                        : <AiOutlineEye style={{ width: '20px', height: '20px' }}/>}
                </div>
            </div>
            <button onClick={signInHadnler} className='login-button hoverable'>Login</button>
        </div></div>
    );
}

export default memo(Login);
