import { AdminApi } from './api';

/**
 * Get searched events
 * @param {String} query
 * @param {Number} page
 * @param {Number} perPage
 * @returns {Promise}
 */
export function searchEvents(query, page, perPage = 15) {
    return AdminApi.get('/v1/events/search', { params: { query, page, perPage } });
}

/**
 * Delete by ids
 * @param {Array} ids
 * @returns {Promise}
 */
export function eventsDelete(ids) {
    return AdminApi.post('/v1/events/delete', { ids });
}

/**
 * Get One By Id
 * @param {Number} id
 * @returns {Promise}
 */
export function getById(id) {
    return AdminApi.get(`/v1/events/${id}`);
}

/**
 * Create events
 * @param {Object} data
 * @returns {Promise}
 */
export function createEvent(data) {
    return AdminApi.post('/v1/events/', data);
}

/**
 * Create events
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateEvent(id, data) {
    return AdminApi.post(`/v1/events/update/${id}`, data);
}
