export const APP_URL = process.env.REACT_APP_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const S3_MEDIA_BUCKET_URL = process.env.REACT_APP_S3_MEDIA_BUCKET_URL;
export const LARAVEL_API = process.env.REACT_APP_LARAVEL_API;
export const WEB_URL = process.env.REACT_APP_WEB_URL;

// Local Storage Keys
export const LOGIN_TOKEN_STORAGE_KEY = 'YoripeLoginToken';

export const imageEndpoints = {
    rootS3Bucket: `${S3_MEDIA_BUCKET_URL}`,
    recipeUrl: `${S3_MEDIA_BUCKET_URL}recipe/`,
    tipsUrl: `${S3_MEDIA_BUCKET_URL}tips/`,
    publishersUrl: `${S3_MEDIA_BUCKET_URL}publishers/`,
    ingredientsUrl: `${S3_MEDIA_BUCKET_URL}ingredients/`,
    announcementUrl: `${S3_MEDIA_BUCKET_URL}announcements/`,
    packsUrl: `${S3_MEDIA_BUCKET_URL}packs/`,
    sellersUrl: `${S3_MEDIA_BUCKET_URL}sellers/`,
    webinarsUrl: `${S3_MEDIA_BUCKET_URL}webinars/`,
    chefsUrl: `${S3_MEDIA_BUCKET_URL}chefs/`,
    logoUrl: `${S3_MEDIA_BUCKET_URL}assets/logo/`,
    dietsUrl: `${S3_MEDIA_BUCKET_URL}diets/`,
    equipmentsUrl: `${S3_MEDIA_BUCKET_URL}equipments/`,
    learnMoreUrl: `${S3_MEDIA_BUCKET_URL}learn-more/`,
    commonIngredientsUrl: `${S3_MEDIA_BUCKET_URL}common-ingredients/`,
    defaultCookingNoteShare: `${S3_MEDIA_BUCKET_URL}cooking-note-share/image.png`,
    bannersUrl: `${S3_MEDIA_BUCKET_URL}banners/`,
    eventsUrl: `${S3_MEDIA_BUCKET_URL}events/`,
    challengeUrl: `${S3_MEDIA_BUCKET_URL}challenges/`,
    challengeStatusIconUrl: `${S3_MEDIA_BUCKET_URL}challenges/status_icons/`,
    challengeIconUrl: `${S3_MEDIA_BUCKET_URL}challenges/icons/`,
    organizerUrl: `${S3_MEDIA_BUCKET_URL}organizer/`,
    brandPortalSellerUrl: `${S3_MEDIA_BUCKET_URL}brand_portal/seller/`,
    brandPortalCoverUrl: `${S3_MEDIA_BUCKET_URL}brand_portal/cover_image/`,
    brandPortalProductUrl: `${S3_MEDIA_BUCKET_URL}brand_portal/product/`
};

export const challengeCategories = [
    { id: 1, name: 'Creative' },
    { id: 2, name: 'Partnership' },
    { id: 3, name: 'Community' },
    { id: 4, name: 'Ingredient' },
    { id: 5, name: 'Baking' },
    { id: 6, name: 'Daily' },
    { id: 7, name: 'Home Chef Idol' },
    { id: 8, name: 'SG HOME CHEF IDOL' },
    { id: 9, name: 'Junior Bake-Off 2024' },
    { id: 10, name: 'YoRipe Junior Chef Season 4' }
];

export const eventsCategories = [
    { id: 1, name: 'Competition' },
    { id: 2, name: 'Academy' },
    { id: 3, name: 'Webinar' }
];

export const queryKeys = {
    challengeSubmissions: 'challengeSubmissions',
    recipeUnits: 'recipeUnits'
};

export const units = [
    'gram', 'grams', 'g', 'tablespoon', 'tablespoons', 'tbsp', 'teaspoon', 'teaspoons', 'tsp', 'cup', 'cups', 'ml', 'piece', 'pieces', 'pc', 'pcs', 'clove', 'cloves', 'pinch', 'To Taste', 'slice', 'slices', 'stalk', 'large', 'packet', 'packets', 'handful', 'small', 'dash', 'whole', 'medium', 'pack', 'packs', 'can', 'cans', 'cm', 'litre', 'litres', 'inch', 'inches', 'bunch', 'bunches', 'big', 'kilogram', 'kilograms', 'kg', 'bowl', 'serving', 'servings', 'sprig', 'block', 'blocks', 'box', 'boxes', 'sheet', 'sheets', 'lb', 'lbs', 'head', 'large packet', 'bulb', 'bottle', 'bottles', 'package', 'packages', 'sachet', 'sachets', 'bag', 'bags', 'drop', 'drops', 'tael', 'bundle', 'bundles', 'stick', 'strip', 'layer', 'layers', 'thumb sized', 'brick', 'floret', 'knot', 'pound', 'pounds', 'strand', 'peel', 'squeeze', 'splash', 'ounce', 'cc', 'chamach', 'sendok teh', 'buah', 'sendok makan', 'siung', 'lembar', 'ekor', 'butir', 'Secukupnya', 'batang', 'ruas', 'sejumput', 'bungkus', 'liter(s)', 'potong', 'papan', 'tetes', 'ikat', 'segenggam', 'utuh', 'ukuran jempol', 'keping', 'gelas', 'centong', 'lapis', 'kotak', 'blok', 'kaleng', 'saset', 'kantong', 'lebar', 'bongkah', 'mangkuk', 'bundel', 'bungkus besar', 'bonggol', 'tangkai', 'ons', 'porsi', 'simpul', 'pon', 'inci', 'botol', 'sdm', 'sdt', 'lbr', 'ltr', 'bh', 'btr', 'bgks', 'bks', 'piring', 'iris', 'sisir', 'keranjang'
];
