
import { createOccasion, getById, updateOccasion } from 'api/occasion';
import CollectionPreview from 'components/CollectionPreview';
import React, { memo, useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import DataForm from './components/DataForm';

function CollectionCreate() {
    const [showPreview, setShowPreview] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const { id } = useParams();
    const [parsedData, setParsedData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();
    const { register, watch, setValue, clearErrors, trigger, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            name: '',
            order: '',
            recipes: null,
            image: '',
            from_date: new Date(),
            to_date: null,
            language: null
        }
    });

    const fields = watch();

    useEffect(() => {
        register('name', { required: 'This field is Required' });
        register('from_date', { required: 'This field is Required' });
        register('order', { required: 'This field is Required' });
        register('recipes', { required: 'This field is Required' });
        register('to_date');
        register('language');
        register('image', { required: 'This field is Required' });
    }, [register]);

    const fetchData = useCallback(async () => {
        try {
            const data = await getById(id);

            if (data) {
                const start = data?.from_date?.split('-').map(item => parseInt(item));
                const end = data?.to_date?.split('-').map(item => parseInt(item));
                const fromDate = data?.from_date ? new Date(start[0], start[1] - 1, start[2]) : new Date();
                const toDate = data?.to_date ? new Date(end[0], end[1] - 1, end[2]) : null;

                setValue('id', id);
                setValue('name', data?.name);
                setValue('image', data?.image);
                setValue('order', data?.order);
                setValue('from_date', fromDate);
                setValue('to_date', toDate);
                setValue('language', data?.language);
                setValue('recipes', data?.recipes?.map(recipe => recipe.recipe_id).toString() ?? '');
            }
        } catch {
            toast.error('Unable to fetch Top Collection', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }, [id, setValue]);

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [fetchData, id]);

    const parseChallengeData = useCallback(() => {
        const parsedData = {
            name: fields?.name,
            from_date: fields?.from_date,
            image: fields?.image,
            to_date: fields?.to_date,
            order: fields?.order ? parseInt(fields.order) : undefined,
            recipes: fields?.recipes.split(',').map(id => parseInt(id)),
            language: fields?.language
        };

        return parsedData;
    }, [fields]);

    const previewHandler = useCallback(async () => {
        setParsedData(parseChallengeData());
        setShowPreview(true);
    }, [parseChallengeData]);

    const saveHandler = useCallback(async () => {
        clearErrors();

        const isValid = await trigger(['name', 'image', 'order', 'recipes', 'from_date']);

        if (isValid) {
            previewHandler();
            setIsValidated(true);
        }
    }, [clearErrors, previewHandler, trigger]);

    const editSaveHandler = useCallback(async () => {
        try {
            setIsSubmitting(true);

            const data = await updateOccasion(id, parsedData);

            if (data.id) {
                toast.success('Thank you. Your Changes has been saved.', {
                    position: toast.POSITION.BOTTOM_LEFT
                });

                history.push(`/top-collection/${data.id}`);
            }
        } catch {
            toast.error('Unable to save the changes ', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsSubmitting(false);
        }
    }, [history, id, parsedData]);

    const submitBrandHandler = useCallback(async () => {
        if (id) {
            editSaveHandler();
        } else {
            try {
                setIsSubmitting(true);

                const data = await createOccasion(parsedData);

                if (data.id) {
                    toast.success('Thank you. Your Ocassion has been submitted.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    });

                    history.push(`/top-collection/${data.id}`);
                }
            } catch {
                toast.error('Unable to publish the Ocassion ', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            } finally {
                setIsSubmitting(false);
            }
        }
    }, [editSaveHandler, history, id, parsedData]);

    return (
        <>
            <Helmet>
                <title>Create Collection - YoRipe</title>
                <meta name='title' content='Create Collection - YoRipe' />
                <meta name='description' content={'Collection Form page to manage all existing collections and create more!'} />
            </Helmet>
            <div className={`page-wrapper ${isSubmitting ? 'disabled' : ''}`}>
                {showPreview
                    ? <CollectionPreview
                        data={parsedData}
                        isValidated={isValidated}
                        onBack={() => setShowPreview(false)}
                        onSubmit={submitBrandHandler} />
                    : <DataForm
                        fields={fields}
                        errors={errors}
                        setValue={setValue}
                        saveHandler={saveHandler}
                        showPreview={previewHandler}/>}
            </div>
        </>
    );
}

export default memo(CollectionCreate);
