import axios from 'axios';
import { API_URL } from 'config/constants';

const axiosApi = axios.create({
    baseURL: API_URL,
    headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        common: {
            channel: 'app'
        }
    },
    timeout: 60000
});

axiosApi.interceptors.request.use((config) => {
    return config;
});

axiosApi.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response?.status === 401) {
            // should we logout?
        }

        return Promise.reject(error);
    }
);

let bearer;

const handleApiError = (e) => {
    if (e.name === 'AxiosError') {
        if (e.code === 'ECONNABORTED') {
            // eslint-disable-next-line no-throw-literal
            throw { code: 'Error.Unknown' };
        }

        // eslint-disable-next-line no-throw-literal
        throw { code: e.response.data.code, message: e.response.data.message };
    }

    throw e;
};

export const AdminApi = {
    set token(newToken) {
        bearer = newToken;

        if (newToken) {
            axiosApi.defaults.headers.Authorization = `Bearer ${newToken}`;
        } else {
            delete axiosApi.defaults.headers.Authorization;
        }
    },

    get token() {
        return bearer;
    },

    get client() {
        return axiosApi;
    },

    async logout() {
        delete axiosApi.defaults.headers.Authorization;
    },

    async get(url, config) {
        try {
            const response = await axiosApi.get(url, config);

            return response.data;
        } catch (e) {
            throw handleApiError(e);
        }
    },

    async post(url, data, config) {
        try {
            const response = await axiosApi.post(url, data, config);

            return response.data;
        } catch (e) {
            throw handleApiError(e);
        }
    },

    async patch(url, data, config) {
        try {
            const response = await axiosApi.patch(url, data, config);

            return response.data;
        } catch (e) {
            throw handleApiError(e);
        }
    },

    async put(url, data, config) {
        try {
            const response = await axiosApi.put(url, data, config);

            return response.data;
        } catch (e) {
            throw handleApiError(e);
        }
    },

    async delete(url, config) {
        try {
            const response = await axiosApi.delete(url, config);

            return response.data;
        } catch (e) {
            throw handleApiError(e);
        }
    }
};

export const publicGet = async (url) => {
    try {
        const response = await axiosApi.get(url, {
            transformRequest: [
                (data, headers) => {
                    delete headers.Authorization;

                    return data;
                }
            ]
        });

        return response.data;
    } catch (e) {
        throw handleApiError(e);
    }
};
