import { useQuery } from '@tanstack/react-query';
import { searchSubmissions } from 'api/challenge';
import { queryKeys } from 'config/constants';

/**
 * API Call to search challenge submissions
 * @param {Number} id
 * @param {String} query
 * @returns {Object}
 */
const fetchChallengeWinners = async (id, query) => {
    try {
        const response = await searchSubmissions(id, query);

        return {
            data: response.data
        };
    } catch {
        return {
            data: []
        };
    }
};

/**
 * React Query hook for searching challenge submissions
 * @param {Number} id
 * @param {String} query
 * @returns {Object}
 */
export function useFetchChallengeSubmissions(id, query) {
    const { data } = useQuery(
        [queryKeys.challengeSubmissions, query],
        () => fetchChallengeWinners(id, query),
        { keepPreviousData: true }
    );

    return { data: data?.data || [] };
}
