import { imageEndpoints } from 'config/constants';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { getParsedDate } from 'utils/helpers';

function BrandPortal({ data, onBack, isValidated, onSubmit }) {
    function openInNewTab(url) {
        if (url) {
            window.open(url, '_blank').focus();
        }
    }

    const promoClickHandler = useCallback(() => {
        if (data?.brand_link) {
            openInNewTab(data?.brand_link);
        }
    }, [data?.brand_link]);

    return (
        <>
            <Helmet>
                <title>{`${data?.name} - Admin YoRipe`}</title>
                <meta name='title' content={`${data?.name} - Admin YoRipe`} />
                <meta name='description' content={'Brand page to manage all existing Brands and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>Brand Portal Preview</div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Brand Name</div>
                        <div className='challenge-section-content'>{data?.name}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Brand Portal Logo</div>
                        <div className='image-grid-container'><img src={imageEndpoints.brandPortalSellerUrl + data?.brand_portal_logo_image} alt='recipe media' /></div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Brand Portal Image</div>
                        <div className='image-grid-container'><img src={imageEndpoints.brandPortalCoverUrl + data?.brand_portal_image} alt='recipe media' /></div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Description</div>
                        <p className='challenge-section-content'>{data?.description}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Language</div>
                        <p className='challenge-section-content'>{!data?.language && 'All'}{data?.language === 'en' && 'English'}{data?.language === 'id' && 'Indo'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Delivery Time</div>
                        <p className='challenge-section-content'>{data?.delivery_time ? data?.delivery_time : 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Discount Code</div>
                        <p className='challenge-section-content'>{data?.discount_code ? data?.discount_code : 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Disc. Percentage</div>
                        <p className='challenge-section-content'>{data?.discount_percentage ? data?.discount_percentage : 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Expiration Date</div>
                        <p className='challenge-section-content'>{data?.expiration_date ? getParsedDate(data?.expiration_date) : 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Promo site</div>
                        <p onClick={promoClickHandler} className='challenge-section-content'>{data?.brand_link ? data?.brand_link : 'null'}</p>
                    </div>
                    <div className='challenge-footer'>
                        <Button variant='brand-red mx-2' onClick={onSubmit} disabled={!isValidated}>Save Brand</Button>
                        <Button variant='brand-gray mx-2' onClick={onBack}>Edit Form</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

BrandPortal.propTypes = {
    data: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isValidated: PropTypes.bool.isRequired
};

export default memo(BrandPortal);
