import { createChallenge, getById, getWinners, updateChallenge } from 'api/challenge';
import ChallengePreview from 'components/ChallengePreview';
import React, { memo, useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getParsedDate } from 'utils/helpers';
import { validateOrganizer } from 'utils/recipeHelpers';

import ChallengeForm from './components/ChallengeForm';

function ChallengeFormContainer() {
    const [categories, setCategories] = useState([]);
    const [winnersList, setWinnersList] = useState([]);
    const [categoryError, setCategoryError] = useState('');
    const [currentType, setCurrentType] = useState(0);
    const [typeError, setTypeError] = useState('');
    const { id } = useParams();
    const [showPreview, setShowPreview] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const [parsedChallenge, setParsedChallenge] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();
    const { register, watch, setValue, clearErrors, trigger, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            name: '',
            hashtag: '',
            description: '',
            image: null,
            organizer: { name: '', image: null },
            short_description: '',
            start_date: new Date(),
            end_date: null,
            challenge_organizer_id: null,
            rewards: '',
            promo_code: '',
            promo_info: '',
            promo_website: 'https://',
            language: null
        }
    });

    const fields = watch();

    useEffect(() => {
        register('name', { required: 'This field is Required' });
        register('hashtag', { required: 'This field is Required' });
        register('image', { required: 'This field is Required' });
        register('organizer', validateOrganizer);
        register('description', { required: 'This field is Required' });
        register('short_description', { required: 'This field is Required' });
        register('start_date', { required: 'This field is Required' });
        register('end_date');
        register('challenge_organizer_id');
        register('rewards');
        register('promo_code');
        register('promo_info');
        register('promo_website');
        register('language');
    }, [register]);

    const parseChallengeData = useCallback(() => {
        const parsedData = {
            name: fields?.name,
            hashtag: fields?.hashtag,
            description: fields?.description,
            image: fields?.image,
            organizer: fields?.organizer?.id ? fields?.organizer : { ...fields?.organizer, id: 0 },
            short_description: fields?.short_description,
            start_date: getParsedDate(fields?.start_date),
            end_date: getParsedDate(fields?.end_date),
            rewards: fields?.rewards ? fields?.rewards?.split('\n') : null,
            promo_code: fields?.promo_code,
            promo_website: fields?.promo_website === 'https://' ? '' : fields?.promo_website,
            promo_info: fields?.promo_info,
            type: currentType,
            category: categories[0],
            language: fields?.language
        };

        return parsedData;
    }, [categories, currentType, fields]);

    const parseWinnersData = useCallback(() => {
        let winners = [];

        winnersList.forEach((item) => {
            let arr = [];

            item?.winners.forEach((winr, idx) => {
                if (winr.id) {
                    const newData = winr;

                    delete newData.placeholder;

                    arr = [...arr, { ...newData, rank: item.winner_category === 'Equal Winners' ? 100 : idx + 1 }];
                }
            });

            winners = [...winners, ...arr];
        });

        return winners;
    }, [winnersList]);

    const processWinnersArray = useCallback(async (winners) => {
        const finalList = [];
        const equalWinners = [];
        const rankedWinners = [];
        const topLiked = [];

        winners.forEach(item => {
            const isRecipe = item.recipe_id !== null;
            const placeholder = isRecipe ? `${item.recipe?.recipe_publisher} | ${item.recipe?.recipename}` : `${item.review?.user?.name} | ${item.review?.comment}`;

            switch (item.winner_category) {
                case 'Equal Winners':
                    equalWinners.push({ placeholder: placeholder, rank: item.rank, winner_category: item.winner_category, id: isRecipe ? item.recipe_id : item.review_id, type: isRecipe ? 'recipe' : 'review' });
                    break;

                case 'Ranking':
                    rankedWinners.push({ placeholder: placeholder, rank: item.rank, winner_category: item.winner_category, id: isRecipe ? item.recipe_id : item.review_id, type: isRecipe ? 'recipe' : 'review' });
                    break;

                case 'Top Votes':
                    topLiked.push({ placeholder: placeholder, rank: item.rank, winner_category: item.winner_category, id: isRecipe ? item.recipe_id : item.review_id, type: isRecipe ? 'recipe' : 'review' });
                    break;

                default:
                    break;
            }
        });

        equalWinners.length > 0 && finalList.push({ winner_category: 'Equal Winners', winners: equalWinners });
        rankedWinners.length > 0 && finalList.push({ winner_category: 'Ranking', winners: rankedWinners });
        topLiked.length > 0 && finalList.push({ winner_category: 'Top Votes', winners: topLiked });

        if (finalList.length > 0) {
            setWinnersList(finalList);
        } else if (new Date() > fields?.start_date) {
            setWinnersList([{ winner_category: '', winners: [] }]);
        }
    }, [fields?.start_date]);

    const fetchWinners = useCallback(async (id) => {
        try {
            const wnrs = await getWinners(id);

            if (wnrs) {
                processWinnersArray(wnrs);
            }
        } catch {
            toast.error('Unable to fetch Challenge Winners', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }, [processWinnersArray]);

    const fetchData = useCallback(async () => {
        try {
            const data = await getById(id);

            if (data) {
                const start = data?.start_date?.split('-').map(item => parseInt(item));
                const end = data?.end_date?.split('-').map(item => parseInt(item));
                const fromDate = data?.start_date ? new Date(start[0], start[1] - 1, start[2]) : new Date();
                const toDate = data?.end_date ? new Date(end[0], end[1] - 1, end[2]) : null;
                const rewards = data?.rewards ? JSON.parse(data?.rewards) : '';

                setValue('id', data?.id);
                setValue('name', data?.name);
                setValue('hashtag', data?.hashtag);
                setValue('description', data?.description);
                setValue('short_description', data?.short_description);
                setValue('rewards', rewards.length > 1 ? rewards.join('\n') : rewards[0]);
                setValue('promo_code', data?.promo_code);
                setValue('promo_website', data?.promo_website);
                setValue('promo_info', data?.promo_info);
                setValue('image', data?.image);
                setValue('challenge_organizer_id', data?.challenge_organizer_id);
                setValue('organizer', data?.organizer);
                setValue('start_date', fromDate);
                setValue('end_date', toDate);
                setValue('language', data?.language);

                if (data?.type) {
                    setCurrentType(parseInt(data?.type));
                }

                if (data?.category) {
                    setCategories([data?.category]);
                }

                fetchWinners(data?.id);
            }
        } catch {
            toast.error('Unable to fetch Challenge', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }, [fetchWinners, id, setValue]);

    useEffect(() => {
        if (id) {
            fetchData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const previewHandler = useCallback(async () => {
        setParsedChallenge(parseChallengeData());
        setShowPreview(true);
    }, [parseChallengeData]);

    const saveHandler = useCallback(async () => {
        clearErrors();

        let isValid = await trigger(['name', 'hashtag', 'image', 'description', 'short_description', 'start_date', 'organizer']);

        if (currentType !== 1 && currentType !== 2 && currentType !== 3) {
            isValid = false;
            setTypeError('Please select from the dropdown.');
        } else {
            setTypeError('');
        }

        if (categories.length === 0) {
            isValid = false;
            setCategoryError('Please select atleast 1 category.');
        } else {
            setCategoryError('');
        }

        if (isValid) {
            previewHandler();
            setIsValidated(true);
        }
    }, [categories.length, clearErrors, currentType, previewHandler, trigger]);

    const parseOrganizerData = (data) => {
        if (data?.id) {
            return data;
        } else if (data?.name === '' && data?.image === null) {
            return {};
        } else {
            return data;
        }
    };

    const editSaveHandler = useCallback(async () => {
        try {
            setIsSubmitting(true);

            const winners = parseWinnersData();

            const submitData = {
                name: fields?.name,
                hashtag: fields?.hashtag,
                description: fields?.description,
                image: fields?.image,
                short_description: fields?.short_description,
                start_date: getParsedDate(fields?.start_date),
                end_date: getParsedDate(fields?.end_date),
                rewards: fields?.rewards ? fields?.rewards?.split('\n') : null,
                promo_code: fields?.promo_code,
                promo_website: fields?.promo_website === 'https://' ? '' : fields?.promo_website,
                promo_info: fields?.promo_info,
                challenge_organizer_id: fields?.challenge_organizer_id,
                type: currentType,
                category: categories[0],
                language: fields?.language,
                winners,
                organizer: parseOrganizerData(fields?.organizer)
            };

            const data = await updateChallenge(fields?.id, submitData);

            if (data.id) {
                toast.success('Thank you. Your Changes has been saved.', {
                    position: toast.POSITION.BOTTOM_LEFT
                });

                history.push(`/challenge/${data.id}`);
            }
        } catch {
            toast.error('Unable to save the changes ', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsSubmitting(false);
        }
    }, [categories, currentType, fields?.challenge_organizer_id, fields?.description, fields?.end_date, fields?.hashtag, fields?.id, fields?.image, fields?.language, fields?.name, fields?.organizer, fields?.promo_code, fields?.promo_info, fields?.promo_website, fields?.rewards, fields?.short_description, fields?.start_date, history, parseWinnersData]);

    const submitChallengeHandler = useCallback(async () => {
        if (id) {
            editSaveHandler();
        } else {
            try {
                setIsSubmitting(true);

                const submitData = {
                    name: fields?.name,
                    hashtag: fields?.hashtag,
                    description: fields?.description,
                    image: fields?.image,
                    short_description: fields?.short_description,
                    start_date: getParsedDate(fields?.start_date),
                    end_date: getParsedDate(fields?.end_date),
                    rewards: fields?.rewards ? fields?.rewards?.split('\n') : null,
                    promo_code: fields?.promo_code,
                    promo_website: fields?.promo_website === 'https://' ? '' : fields?.promo_website,
                    promo_info: fields?.promo_info,
                    challenge_organizer_id: fields?.challenge_organizer_id,
                    type: currentType,
                    category: categories[0],
                    language: fields?.language,
                    organizer: parseOrganizerData(fields?.organizer)
                };

                const data = await createChallenge(submitData);

                if (data.id) {
                    toast.success('Thank you. Your Challenge has been submitted.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    });

                    history.push(`/challenge/${data.id}`);
                }
            } catch {
                toast.error('Unable to publish the challenge ', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            } finally {
                setIsSubmitting(false);
            }
        }
    }, [categories, currentType, editSaveHandler, fields?.challenge_organizer_id, fields?.description, fields?.end_date, fields?.hashtag, fields?.image, fields?.language, fields?.name, fields?.organizer, fields?.promo_code, fields?.promo_info, fields?.promo_website, fields?.rewards, fields?.short_description, fields?.start_date, history, id]);

    return (
        <>
            <Helmet>
                <title>Create Challenges - YoRipe</title>
                <meta name='title' content='Create Challenges - YoRipe' />
                <meta name='description' content={'Challenge Form page to manage all existing challenges and create more!'} />
            </Helmet>
            <div className={`page-wrapper ${isSubmitting ? 'disabled' : ''}`}>
                {showPreview
                    ? <ChallengePreview
                        challenge={parsedChallenge}
                        isValidated={isValidated}
                        winners={winnersList}
                        onBack={() => setShowPreview(false)}
                        onSubmit={submitChallengeHandler} />
                    : <ChallengeForm
                        fields={fields}
                        errors={errors}
                        setValue={setValue}
                        categories={categories}
                        setCategories={setCategories}
                        winnersList={winnersList}
                        setWinnersList={setWinnersList}
                        currentType={currentType}
                        setCurrentType={setCurrentType}
                        typeError={typeError}
                        saveHandler={saveHandler}
                        showPreview={previewHandler}
                        categoryError={categoryError}/>}
            </div>
        </>
    );
}

export default memo(ChallengeFormContainer);
