import { AdminApi } from './api';

/**
 * API to Admin Login
 * @param {String} email
 * @param {String} password
 * @returns {Promise}
 */
export const login = async (email, password) => {
    return AdminApi.post('/v1/auth/login', { email, password });
};

/**
 * Get User imformation
 * @returns {Promise}
 */
export const getProfile = async () => {
    return AdminApi.get('/v1/auth/me');
};
