import 'styles/styles.scss';
import 'styles/create_form.scss';
import 'styles/view_styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LeftNav from 'components/LeftNav';
import TopHeader from 'components/TopHeader';
import Routes from 'config/routes';
import Login from 'containers/Login';
import useAppPreparation from 'hooks/useAppPreparation';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

const mapStateToProps = ({ authentication }) => {
    return {
        profileData: authentication.profileData
    };
};

const queryClient = new QueryClient();

function App() {
    const isAppReady = useAppPreparation();
    const { profileData } = useSelector(mapStateToProps);

    return (
        <React.Fragment>
            <QueryClientProvider client={queryClient}>
                {isAppReady && <>
                    <LeftNav />
                    <TopHeader />
                    <div className='content-pages-wrapper invis-scroll'>
                        <Routes />
                    </div>
                    {!profileData.id && <Login />}
                </>}
            </QueryClientProvider>
            <ToastContainer />
        </React.Fragment>
    );
}

export default memo(App);
