import './index.scss';

import { getById } from 'api/event';
import { imageEndpoints } from 'config/constants';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getParsedDate } from 'utils/helpers';

function Event() {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const startDate = useMemo(() => getParsedDate(data?.start_date), [data?.start_date]);
    const endDate = useMemo(() => getParsedDate(data?.end_date), [data?.end_date]);

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            const response = await getById(id);

            if (response) {
                setData(response);
            }
        } catch {
            toast.error('Unable to fetch Event', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title>Event - Admin YoRipe</title>
                <meta name='title' content='Event - Admin YoRipe' />
                <meta name='description' content={'Event page to manage all existing events and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>View Event</div>
                    {isLoading && <div className='challenge-header'><Spinner animation='border' variant='red-1' /></div>}
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Event Name</div>
                        <p className='challenge-section-content'>{data?.name}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Banner</div>
                        <img className='banner-image-form' src={imageEndpoints.eventsUrl + data?.banner_image} alt='recipe media' />
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Event Description</div>
                        <p className='challenge-section-content'>{data?.description}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Language</div>
                        <p className='challenge-section-content'>{!data?.language && 'All'}{data?.language === 'en' && 'English'}{data?.language === 'id' && 'Indo'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Category</div>
                        <p className='challenge-section-content'>{data?.category?.split(' ').map((item, index) => <span key={index} className='challenge-category'>{item}</span>)}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Event Link</div>
                        {data?.website_link
                            ? <a className='challenge-section-content' href={data?.website_link} target='_blank'
                                rel='noreferrer'>{data?.website_link}</a>
                            : <p className='challenge-section-content'>null</p>}
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Event Embed Link</div>
                        {data?.embed_link
                            ? <a className='challenge-section-content' href={data?.embed_link} target='_blank'
                                rel='noreferrer'>{data?.embed_link}</a>
                            : <p className='challenge-section-content'>null</p>}
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Event Button Text</div>
                        <p className='challenge-section-content'>{data?.button_text || 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Event Button Link</div>
                        {data?.group_link
                            ? <a className='challenge-section-content' href={data?.group_link} target='_blank'
                                rel='noreferrer'>{data?.group_link}</a>
                            : <p className='challenge-section-content'>null</p>}
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Start Time</div>
                        <p className='challenge-section-content'>{startDate}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>End Time</div>
                        <p className='challenge-section-content'>{endDate}</p>
                    </div>
                    <div className='challenge-footer'>
                        <Link to={`/event-edit/${data?.id}`}><Button variant='brand-red mx-2'>Edit Event</Button></Link>
                        <Button variant='brand-gray mx-2' onClick={history.goBack}>Back</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(Event);
