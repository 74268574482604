import { searchEvents } from 'api/event';
import ConfirmPopup from 'components/ConfirmPopup';
import { imageEndpoints } from 'config/constants';
import ROUTE_PATHS from 'config/routePaths';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import { deleteEvents } from 'utils/adminDelete';
import { getParsedDate } from 'utils/helpers';

function EventsScreen() {
    const [query, setQuery] = useState('');
    const [searchData, setSerachData] = useState({ page: 1, isLoading: false, data: [] });
    const [selectedItems, setSelectedItems] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();

    const visiblePages = useMemo(() => {
        const startPages = searchData?.currentPage > 2 ? [1, searchData?.currentPage - 1] : [1];
        const endPages = (searchData?.lastPage - searchData?.currentPage) > 1 ? [searchData?.currentPage + 1, searchData?.lastPage] : [searchData?.lastPage];

        if (searchData?.lastPage < 4) {
            return [...Array.from({ length: searchData?.lastPage }, (_, i) => i + 1)];
        } else {
            return [...startPages, searchData?.currentPage, ...endPages];
        }
    }, [searchData?.currentPage, searchData?.lastPage]);

    const deleteCallback = useCallback((ids) => {
        const newArr = searchData.data.filter(item => !ids.includes(item.id));

        setSerachData((prev) => ({ ...prev, data: newArr }));
        setSelectedItems([]);
        setShowModal(false);
    }, [searchData]);

    const deleteHandler = useCallback(() => {
        const data = searchData.data.filter((item, index) => {
            return selectedItems.includes(index);
        });

        const ids = data.map(item => item.id);

        deleteEvents(ids, () => deleteCallback(ids));
    }, [deleteCallback, searchData, selectedItems]);

    const selectHandler = (index) => {
        if (selectedItems.includes(index)) {
            const filtered = selectedItems.filter((item) => item !== index);

            setSelectedItems([...filtered]);
        } else {
            setSelectedItems((prev) => [...prev, index]);
        }
    };

    useEffect(() => {
        if (query) {
            const timeoutId = setTimeout(() => {
                fetchEventsSearch(1);
            }, 500);

            return () => clearTimeout(timeoutId);
        } else {
            fetchEventsSearch(1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const fetchEventsSearch = useCallback(async (page) => {
        try {
            setSerachData((prev) => ({ ...prev, isLoading: true }));

            const { data, totalPages } = await searchEvents(query, page, 10);

            setSerachData({ currentPage: page, lastPage: totalPages, isLoading: false, data: [...data] });
            setSelectedItems([]);
        } catch {
            setSerachData((prev) => ({ ...prev, isLoading: false }));
        }
    }, [query]);

    const handleQueryChange = (e) => {
        setQuery(e.target.value);
    };

    const nextPageHandler = () => {
        if (searchData.currentPage < searchData.lastPage) {
            fetchEventsSearch(searchData.currentPage + 1);
        }
    };

    const prevPageHandler = () => {
        if (searchData.currentPage > 1) {
            fetchEventsSearch(searchData.currentPage - 1);
        }
    };

    const pageSelectHandler = useCallback((page) => {
        fetchEventsSearch(page);
    }, [fetchEventsSearch]);

    const renderChallenge = (item, index) => {
        const startDate = getParsedDate(item?.start_date);
        const endDate = getParsedDate(item?.end_date);

        const openItem = () => {
            history.push(`/event/${item.id}`);
        };

        return (
            <tr onClick={openItem} key={item.id}>
                <td><input onClick={(e) => e.stopPropagation()} type='checkbox' checked={selectedItems.includes(index)}
                    onChange={() => selectHandler(index)} /></td>
                <td><Link to={`/event/${item.id}`}><img src={imageEndpoints.eventsUrl + item?.banner_image} className='banner-image' alt='recipe' /></Link></td>
                <td><Link className='story-description' to={`/event/${item.id}`}>{item?.name}</Link></td>
                <td>{item.category}</td>
                <td>{startDate}</td>
                <td>{endDate}</td>
                <td>{item?.website_link
                    ? <a className='story-description' href={item?.website_link} rel='noreferrer'
                        target='_blank'>{item?.website_link}</a>
                    : 'null'}</td>
                <td>{item?.group_link
                    ? <a className='story-description' href={item?.group_link} rel='noreferrer'
                        target='_blank'>{item?.group_link}</a>
                    : 'null'}</td>
            </tr>
        );
    };

    const renderPages = useCallback(() => {
        if (searchData?.lastPage) {
            const pages = Array.from({ length: searchData?.lastPage }, (_, i) => i + 1);

            return (
                <>{pages.map(item => {
                    if (visiblePages.includes(item)) {
                        return <Button key={item} variant={searchData.currentPage === item ? 'red-hollow' : 'gray_hollow'} onClick={() => pageSelectHandler(item)}>{item}</Button>;
                    } else if (searchData?.currentPage - 2 === item || searchData.currentPage + 2 === item) {
                        return <span key={item} className='gray_hollow'>...</span>;
                    }

                    return null;
                })}</>
            );
        }
    }, [pageSelectHandler, searchData.currentPage, searchData?.lastPage, visiblePages]);

    return (
        <>
            <Helmet>
                <title>Admin Events - YoRipe</title>
                <meta name='title' content='Admin Events - YoRipe' />
                <meta name='description' content={'Events page to manage all existing Events and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenges-header'>
                    <div className='search-input-wrapper'>
                        <input value={query} onChange={handleQueryChange} placeholder='Search' />
                        <BiSearch color='#818181' size={20} />
                    </div>
                    <Link to={ROUTE_PATHS.EVENTS_CREATE} className='no-link challenges-header-create hoverable'>+ Add Event</Link>
                </div>
                <div className='data-actions-wrapper'>
                    <Button variant='danger' onClick={() => setShowModal(true)} disabled={selectedItems.length < 1}>Delete</Button>
                </div>
                {searchData.isLoading && <div className='data-actions-wrapper'><Spinner animation='border' variant='red-1' /></div>}
                <div className='challenge-data-wrapper'>
                    <Table bordered={false} hover={true}>
                        <thead>
                            <tr>
                                <th/>
                                <th>Banner</th>
                                <th>Event Name</th>
                                <th>Category</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Website Link</th>
                                <th>Group Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchData.data.map(renderChallenge)}
                        </tbody>
                    </Table>
                </div>
                <div className='data-table-footer'>
                    <div className='page-info'>Showing ({10 * (searchData.currentPage - 1) + 1} to {10 * (searchData.currentPage - 1) + searchData.data.length}) - Page {searchData.currentPage} out of {searchData.lastPage}</div>
                    <div className='pagination-wrapper'>
                        <Button variant='red-hollow' disabled={searchData.currentPage === 1} onClick={prevPageHandler}>Prev</Button>{' '}
                        {renderPages()}
                        <Button variant='red-hollow' disabled={searchData.currentPage === searchData.lastPage} onClick={nextPageHandler}>Next</Button>
                    </div>
                </div>
                <ConfirmPopup
                    isVisible={showModal}
                    onClose={() => setShowModal(false)}
                    onConfirm={deleteHandler}
                    confirmTitle='Delete'
                    title='Delete confirmation'
                    description='Items once deleted cannot be recovered! Are you sure you want to delete?'
                    type={1} />
            </div></>
    );
}

export default memo(EventsScreen);
