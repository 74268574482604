import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BiCheck } from 'react-icons/bi';
import { IoReorderTwo } from 'react-icons/io5';
import { MdDelete, MdEdit } from 'react-icons/md';
import { decimalToFraction, parseRawIngredients } from 'utils/recipeHelpers';

function Ingredient(props) {
    const [showEdit, setShowEdit] = useState(false);
    const inputRef = useRef();
    const [rawString, setRawString] = useState('');

    const rawIngredient = useMemo(() => {
        const placeholdr = `${props.ingredient.quantity || ''} ${props.ingredient.serving || ''} ${props.ingredient.name}${props.ingredient.comment ? ' (' + props.ingredient.comment + ')' : ''}`;

        if (placeholdr.trim() !== '') {
            return placeholdr;
        }

        return '';
    }, [props.ingredient.comment, props.ingredient.name, props.ingredient.quantity, props.ingredient.serving]);

    useEffect(() => {
        setRawString(rawIngredient);
    }, [rawIngredient, showEdit]);

    useEffect(() => {
        if (showEdit) {
            inputRef.current.focus();
        }
    }, [showEdit]);

    useEffect(() => {
        props.ingredient?.isNew && setShowEdit(true);
    }, [props.ingredient?.isNew]);

    const onParseText = useCallback(async () => {
        if (rawString.length > 0) {
            const ingredient = await parseRawIngredients(rawString);

            props.updateIngredient(ingredient[0]);
            setShowEdit(false);
        }
    }, [rawString, props]);

    if (props.ingredient.section || props.ingredient.section === '') {
        return (
            <div className='ingredient-wrapper'>
                <IoReorderTwo size={20} color='#818181' />
                <input value={props.ingredient.section} onChange={(e) => props.groupChangeHandler(e.target.value, props.index)}
                    className='form-input-borderless' type='text' placeholder='Section Title' />
                <MdDelete onClick={props.removeIngredient} className='ingredient-icon' size={20}
                    color='#818181' />
            </div>
        );
    }

    if (showEdit) {
        return (
            <div className='ingredient-wrapper'>
                <IoReorderTwo size={20} color='#818181' />
                <input ref={inputRef} value={rawString} onChange={(e) => setRawString(e.target.value)}
                    className='form-input-borderless' type='text' placeholder='Add an Ingredient' />
                <BiCheck onClick={onParseText} className='ingredient-icon' size={30}
                    color='#818181' />
                <MdDelete onClick={props.removeIngredient} className='ingredient-icon' size={25}
                    color='#818181' />
            </div>
        );
    }

    return (
        <div className='ingredient-wrapper'>
            <IoReorderTwo size={20} color='#818181' />
            <div className='ingredient-name'>{props.ingredient?.name} <span className='ingredient-comment'>{props.ingredient?.comment}</span></div>
            <div className='ingredient-qualtity'>{decimalToFraction(props.ingredient?.quantity)} <span className={props.ingredient?.unitError ? 'ingredient-error' : ''}>{props.ingredient?.serving}</span></div>
            <MdEdit onClick={() => setShowEdit(true)} className='ingredient-icon' size={20}
                color='#818181' />
            <MdDelete onClick={props.removeIngredient} className='ingredient-icon' size={20}
                color='#818181' />
        </div>
    );
}

Ingredient.propTypes = {
    ingredient: PropTypes.object.isRequired,
    updateIngredient: PropTypes.func.isRequired,
    removeIngredient: PropTypes.func.isRequired,
    groupChangeHandler: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
};

export default memo(Ingredient);
