import './index.scss';

import { ReactComponent as UserRed } from 'images/icons/User-red.svg';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AiFillDelete, AiFillTrophy } from 'react-icons/ai';

function ConfirmPopup({ isVisible, isLoading = false, onClose, onConfirm, title, description, cancelText = 'Cancel', confirmTitle = 'Confirm', type }) {
    return (
        <Modal className='confirm-modal' centered={true} show={isVisible}
            onHide={onClose}>
            <div className='confirm-popup-header'>
                {type === 1 && <div className='confirm-icon-delete'><AiFillDelete color='#E74C3C' size={30} /></div>}
                {type === 2 && <div className='confirm-icon-win'><AiFillTrophy color='#42C1A2' size={30} /></div>}
                {type === 3 && <div className='confirm-icon-delete'><UserRed width={30} height={30} /></div>}
                <div className='confirm-info'>
                    <span className='confirm-info-title'>{title}</span>
                    <span className='confirm-info-description'>{description}</span>
                </div>
            </div>
            <Modal.Footer className='no-border'>
                <Button variant='brand-gray' onClick={onClose}>{cancelText}</Button>
                <Button variant='brand-red' disabled={isLoading} onClick={onConfirm}>{confirmTitle}</Button>
            </Modal.Footer>
        </Modal>
    );
}

ConfirmPopup.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    cancelText: PropTypes.string,
    confirmTitle: PropTypes.string,
    type: PropTypes.number
};

export default memo(ConfirmPopup);
