import { searchChallenge } from 'api/challenge';
import { searchRecipe } from 'api/recipe';
import { uploadImage } from 'api/upload';
import ConfirmPopup from 'components/ConfirmPopup';
import Media from 'components/Media';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { AiFillEye } from 'react-icons/ai';
import { BsFillImageFill } from 'react-icons/bs';
import Resizer from 'react-image-file-resizer';
import { useHistory } from 'react-router-dom';

function StoriesForm(props) {
    const [challengeData, setChallengeData] = useState({ page: 1, isLoading: false, data: [] });
    const [recipeData, setRecipeData] = useState({ page: 1, isLoading: false, data: [] });
    const [challengeQuery, setChallengeQuery] = useState('');
    const [recipeQuery, setRecipeQuery] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showList, setShowList] = useState(false);
    const [showRecipes, setShowRecipes] = useState(false);
    const imageUploadRef = useRef();
    const [isUploading, setUploading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (props.fields?.challenges[0]?.hashtag) {
            setChallengeQuery(props.fields.challenges[0].hashtag);
        }
    }, [props.fields.challenges]);

    useEffect(() => {
        if (props.fields?.recipe?.recipename) {
            setRecipeQuery(props.fields.recipe?.recipename);
        }
    }, [props.fields.recipe]);

    const uploadStoryImage = useCallback(async (image) => {
        try {
            setUploading(true);

            const uploadResponse = await uploadImage(image);

            props.setValue('photos', [...props.fields?.photos, uploadResponse]);
        } finally {
            setUploading(false);
        }
    }, [props]);

    const uploadImageHandler = useCallback(async (event) => {
        if (event.target.files[0]) {
            try {
                Resizer.imageFileResizer(event.target.files[0], 2048, 2048, 'PNG', 85, 360, (uri) => uploadStoryImage(uri));
            } catch (err) {
            }
        }
    }, [uploadStoryImage]);

    const fetchChallenges = useCallback(async (query) => {
        try {
            setChallengeData({ ...challengeData, isLoading: true });

            const { data, totalPages } = await searchChallenge(query, 1, 15);

            setChallengeData({ currentPage: 1, lastPage: totalPages, isLoading: false, data: [...data] });
        } catch {
            setChallengeData({ ...challengeData, isLoading: false });
        }
    }, [challengeData]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchChallenges(challengeQuery);
        }, 500);

        return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [challengeQuery]);

    const fetchRecipe = useCallback(async (query) => {
        try {
            setRecipeData({ ...recipeData, isLoading: true });

            const { data } = await searchRecipe(query, 1, 15);

            setRecipeData({ currentPage: 1, lastPage: data.totalPages, isLoading: false, data: [...data] });
        } catch {
            setRecipeData({ ...recipeData, isLoading: false });
        }
    }, [recipeData]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchRecipe(recipeQuery);
        }, 500);

        return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipeQuery]);

    const handleChallengeSelect = useCallback((item) => {
        props.setValue('challenges', [item]);
        setShowList(false);
    }, [props]);

    const handleRecipeSelect = useCallback((item) => {
        props.setValue('recipe_id', item.id);
        props.setValue('recipe', item);
        setShowRecipes(false);
    }, [props]);

    const renderChallenge = useCallback(() => {
        if (challengeData.data.length === 0) {
            return <span className='challenge-type'>Choose Challenge Category</span>;
        }

        return challengeData.data.map((challenge) => {
            return (
                <Dropdown.Item key={challenge.id} onClick={() => handleChallengeSelect(challenge)}>{challenge.hashtag}</Dropdown.Item>
            );
        });
    }, [challengeData.data, handleChallengeSelect]);

    const renderRecipe = useCallback(() => {
        if (recipeData.data.length === 0) {
            return <span className='challenge-type'>Choose Recipe</span>;
        }

        return recipeData.data.map((recipe) => {
            return (
                <Dropdown.Item key={recipe.id} onClick={() => handleRecipeSelect(recipe)}>{recipe.recipename}</Dropdown.Item>
            );
        });
    }, [recipeData.data, handleRecipeSelect]);

    const challengeQueryChangeHandler = useCallback((e) => {
        setChallengeQuery(e.target.value);

        if (props.fields?.challenges[0]?.id) {
            props.setValue('challenges', []);
        }
    }, [props]);

    const recipeQueryChangeHandler = useCallback((e) => {
        setRecipeQuery(e.target.value);

        if (props.fields?.recipe?.id) {
            props.setValue('recipe', {});
            props.setValue('recipe_id', null);
        }
    }, [props]);

    const removeIndexHandler = (index) => {
        const arr = props.fields?.photos;

        arr.splice(index, 1);

        props.setValue('photos', arr);
    };

    return (
        <div className='form-wrapper'>
            <Form>
                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Upload Assets</Form.Label>
                    {props.fields?.photos?.length > 0 && props.fields?.photos?.map((item, index) => {
                        return <Media onClose={() => removeIndexHandler(index)} src={item} key={index}
                            alt='organizer' className='form-input-image' />;
                    })}
                    <div onClick={() => imageUploadRef.current.click()} className={`image-placeholder-wrapper hoverable ${isUploading ? 'disabled' : ''}`}>
                        <BsFillImageFill color='#bdbdbd' size={40} />
                        <span>Upload imgaes.</span>
                    </div>
                    <input type='file' multiple={false} ref={imageUploadRef}
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={uploadImageHandler} />
                    {props.errors?.images?.message && <Form.Text className='form-error-message'>{props.errors?.images?.message}</Form.Text>}
                </Form.Group>
                <Form.Group className='mb-3' controlId='challengeName'>
                    <Form.Label className='form-label'>Title</Form.Label>
                    <input value={props.fields?.title} onChange={(e) => props.setValue('title', e.target.value)}
                        className='form-input' type='text' placeholder='Enter cooking story title' />
                    {props.errors?.title?.message && <Form.Text className='form-error-message'>{props.errors?.title?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='recipeDescription'>
                    <Form.Label className='form-label'>Caption</Form.Label>
                    <textarea value={props.fields?.comment} onChange={(e) => props.setValue('comment', e.target.value)}
                        rows={2} type='text'
                        className='form-textarea'
                        placeholder='Write the caption' />
                    {props.errors?.comment?.message && <Form.Text className='form-error-message'>{props.errors?.comment?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Challenge Tag <span className='form-label-count'>(Optional)</span></Form.Label>
                    <Dropdown onToggle={setShowList}>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>
                            <input value={challengeQuery} onChange={challengeQueryChangeHandler}
                                className='organizer-input'
                                type='text'
                                placeholder='Choose Challenge Tag' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu show={showList}>
                            {renderChallenge()}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Recipe Tag <span className='form-label-count'>(Optional)</span></Form.Label>
                    <Dropdown onToggle={setShowRecipes}>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>
                            <input value={recipeQuery} onChange={recipeQueryChangeHandler}
                                className='organizer-input'
                                type='text'
                                placeholder='Choose Recipe' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu show={showRecipes}>
                            {renderRecipe()}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>
            </Form>
            <div className='form-footer'>
                <Button variant='brand-red-hollow' onClick={props.showPreview} ><AiFillEye color='#FF7268' />  Preview</Button>
                <div>
                    <Button variant='brand-gray mx-2' onClick={() => setShowModal(true)}>Cancel</Button>
                    <Button variant='brand-red mx-2' onClick={props.saveHandler}>Save Story</Button>
                </div>
            </div>
            <ConfirmPopup
                isVisible={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={history.goBack}
                confirmTitle='Leave'
                title='Unsaved Changes'
                description='Are you sure want to discard this changes? Changes you made may not be saved.'
                type={1} />
        </div>
    );
}

StoriesForm.propTypes = {
    fields: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    saveHandler: PropTypes.func.isRequired,
    showPreview: PropTypes.func.isRequired
};

export default memo(StoriesForm);
