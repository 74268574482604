import { AdminApi } from './api';

/**
 * Get searched Reviews
 * @param {String} query
 * @param {Number} page
 * @param {Number} perPage
 * @returns {Promise}
 */
export function searchReview(query, page, perPage = 15) {
    return AdminApi.get('/v1/reviews/search', { params: { query, page, perPage } });
}

/**
 * Delete by ids
 * @param {Array} ids
 * @returns {Promise}
 */
export function reviewsDelete(ids) {
    return AdminApi.post('/v1/reviews/delete', { ids });
}

/**
 * Get One By Id
 * @param {Number} id
 * @returns {Promise}
 */
export function getById(id) {
    return AdminApi.get(`/v1/reviews/${id}`);
}

/**
 * Create Review
 * @param {Object} data
 * @returns {Promise}
 */
export function createReview(data) {
    return AdminApi.post('/v1/reviews/', data);
}

/**
 * Create Review
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateReview(id, data) {
    return AdminApi.post(`/v1/reviews/update/${id}`, data);
}
