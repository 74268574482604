import { searchUser } from 'api/users';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';

function EventsScreen() {
    const [query, setQuery] = useState('');
    const [searchData, setSerachData] = useState({ page: 1, isLoading: false, data: [] });
    const [currentTab, setCurrentTab] = useState(null);
    const history = useHistory();

    const visiblePages = useMemo(() => {
        const startPages = searchData?.currentPage > 2 ? [1, searchData?.currentPage - 1] : [1];
        const endPages = (searchData?.lastPage - searchData?.currentPage) > 1 ? [searchData?.currentPage + 1, searchData?.lastPage] : [searchData?.lastPage];

        if (searchData?.lastPage < 4) {
            return [...Array.from({ length: searchData?.lastPage }, (_, i) => i + 1)];
        } else {
            return [...startPages, searchData?.currentPage, ...endPages];
        }
    }, [searchData?.currentPage, searchData?.lastPage]);

    useEffect(() => {
        if (query) {
            const timeoutId = setTimeout(() => {
                fetchUsersSearch(1);
            }, 500);

            return () => clearTimeout(timeoutId);
        } else {
            fetchUsersSearch(1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const fetchUsersSearch = useCallback(async (page) => {
        try {
            setSerachData((prev) => ({ ...prev, isLoading: true }));

            const { data, totalPages } = await searchUser(query, page, currentTab ?? undefined, 10);

            setSerachData({ currentPage: page, lastPage: totalPages, isLoading: false, data: [...data] });
        } catch {
            setSerachData((prev) => ({ ...prev, isLoading: false }));
        }
    }, [currentTab, query]);

    useEffect(() => {
        fetchUsersSearch(1);
    }, [currentTab, fetchUsersSearch]);

    const handleQueryChange = (e) => {
        setQuery(e.target.value);
    };

    const nextPageHandler = () => {
        if (searchData.currentPage < searchData.lastPage) {
            fetchUsersSearch(searchData.currentPage + 1);
        }
    };

    const prevPageHandler = () => {
        if (searchData.currentPage > 1) {
            fetchUsersSearch(searchData.currentPage - 1);
        }
    };

    const pageSelectHandler = useCallback((page) => {
        fetchUsersSearch(page);
    }, [fetchUsersSearch]);

    const renderUSer = (item, index) => {
        const openItem = () => {
            history.push(`/user/${item.id}`);
        };

        return (
            <tr onClick={openItem} key={item.id}>
                <td>{item.id}</td>
                <td><Link to={`/user/${item.id}`}>{item?.name}</Link></td>
                <td>{item.user_name}</td>
                <td>{item.email}</td>
                <td>{item.mobile}</td>
                <td>{item.blocked_count}</td>
                <td>{item.report_count}</td>
                <td>{!item.ban_status && 'Active'}{item.ban_status === 1 && <span style={{ color: '#FFAA06' }}>Suspicious</span>}{item.ban_status === 2 && <span style={{ color: '#BF050F' }}>Banned</span>}</td>
                <td>{item.language === 'id' ? 'Indonesia' : 'Singapore'}</td>
            </tr>
        );
    };

    const renderPages = useCallback(() => {
        if (searchData?.lastPage) {
            const pages = Array.from({ length: searchData?.lastPage }, (_, i) => i + 1);

            return (
                <>{pages.map(item => {
                    if (visiblePages.includes(item)) {
                        return <Button key={item} variant={searchData.currentPage === item ? 'red-hollow' : 'gray_hollow'} onClick={() => pageSelectHandler(item)}>{item}</Button>;
                    } else if (searchData?.currentPage - 2 === item || searchData.currentPage + 2 === item) {
                        return <span key={item} className='gray_hollow'>...</span>;
                    }

                    return null;
                })}</>
            );
        }
    }, [pageSelectHandler, searchData.currentPage, searchData?.lastPage, visiblePages]);

    return (
        <>
            <Helmet>
                <title>Admin Users - YoRipe</title>
                <meta name='title' content='Admin Users - YoRipe' />
                <meta name='description' content={'Users page to manage all existing Users!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenges-header'>
                    <div className='search-input-wrapper'>
                        <input value={query} onChange={handleQueryChange} placeholder='Search' />
                        <BiSearch color='#818181' size={20} />
                    </div>
                </div>
                <div className='data-tabs-wrapper'>
                    <div onClick={() => setCurrentTab(null)} className={currentTab === null ? 'data-tab-active' : 'data-tab-inactive'}>All Users</div>
                    <div onClick={() => setCurrentTab(2)} className={currentTab === 2 ? 'data-tab-active' : 'data-tab-inactive'}>Banned Users</div>
                    <div onClick={() => setCurrentTab(1)} className={currentTab === 1 ? 'data-tab-active' : 'data-tab-inactive'}>Suspicious Users</div>
                </div>
                {searchData.isLoading && <div className='data-actions-wrapper'><Spinner animation='border' variant='red-1' /></div>}
                <div className='challenge-data-wrapper'>
                    <Table bordered={false} hover={true}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Display Name</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Numb Blocked</th>
                                <th>Numb Reported</th>
                                <th>Status</th>
                                <th>Country Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchData.data.map(renderUSer)}
                        </tbody>
                    </Table>
                </div>
                <div className='data-table-footer'>
                    <div className='page-info'>Showing ({10 * (searchData.currentPage - 1) + 1} to {10 * (searchData.currentPage - 1) + searchData.data.length}) - Page {searchData.currentPage} out of {searchData.lastPage}</div>
                    <div className='pagination-wrapper'>
                        <Button variant='red-hollow' disabled={searchData.currentPage === 1} onClick={prevPageHandler}>Prev</Button>{' '}
                        {renderPages()}
                        <Button variant='red-hollow' disabled={searchData.currentPage === searchData.lastPage} onClick={nextPageHandler}>Next</Button>
                    </div>
                </div>
            </div></>
    );
}

export default memo(EventsScreen);
