import { AdminApi } from 'api/api';
import { LOGIN_TOKEN_STORAGE_KEY } from 'config/constants';
import { format, formatDistanceToNowStrict, parseISO } from 'date-fns';

/**
 * Save login token
 * @param {String} loginToken
 */
export async function saveLoginToken(loginToken) {
    await localStorage.setItem(LOGIN_TOKEN_STORAGE_KEY, loginToken);
}

/**
 * Retrieve login token
 * @returns {String}
 */
export async function getLoginToken() {
    const token = await localStorage?.getItem(LOGIN_TOKEN_STORAGE_KEY);

    return token;
}

/**
 * Delete login token
 */
export async function deleteLoginToken() {
    localStorage.removeItem(LOGIN_TOKEN_STORAGE_KEY);
    AdminApi.logout();
}

/**
 * Sets the the authorization token for api requests
 * @param {String} token
 */
export function setApiRequestToken(token) {
    AdminApi.token = token;
}

/**
 * Validate email address
 * @param {String} email
 * @returns {Boolean}
 */
export function validateEmail(email) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
}

/**
 * Formats date into relative (e.g 2H)
 * @param {String} date
 * @returns {String}
 */
export function formatDate(date) {
    const parsedDate = parseISO(date);
    const formattedDate = formatDistanceToNowStrict(parsedDate, { roundingMethod: 'floor' });
    const [amount, distanceInWords] = formattedDate.split(' ');

    if (['seconds', 'minutes', 'hours'].includes(distanceInWords)) {
        return `${amount}${distanceInWords[0]}`;
    }

    return format(parsedDate, 'MM/dd/yy');
}

export const getParsedDate = (date_) => {
    if (date_) {
        const date = new Date(date_);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        const yyyy = date.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        return `${yyyy}-${mm}-${dd}`;
    } else {
        return null;
    }
};

export const getHourMinute = (timeInMinute) => {
    if (!timeInMinute) {
        return 'null';
    }

    const mins = parseInt(timeInMinute, 10) % 60;
    const hrs = Math.floor(parseInt(timeInMinute, 10) / 60);

    const hrsStrng = hrs > 0 ? `${hrs} ${hrs > 1 ? 'hours' : 'hour'}` : '';
    const minStr = mins > 0 ? `${mins} ${mins > 1 ? 'mins' : 'min'}` : '';

    return `${hrsStrng} ${minStr}`;
};

export const dateOptions = { weekday: 'short', month: 'short', day: 'numeric' };

export const returnParsedDate = (rawDate) => {
    try {
        if (rawDate) {
            const date = parseISO(rawDate);
            const day = date?.toLocaleDateString('en-US', dateOptions);
            const mins = (date?.getMinutes() < 10 ? '0' : '') + date?.getMinutes();
            const time = date?.getHours() + ':' + mins;

            return day + ' at ' + time;
        } else {
            return 'null';
        }
    } catch {
        return 'null';
    }
};

export function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
}
