import { getProfile } from 'api/auth';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getLoginToken, setApiRequestToken } from 'utils/helpers';
import { isAuthed } from 'utils/redux/modules/authentication';

function useAppPreparation() {
    const [isAppReady, setAppReady] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        loadAuthedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadAuthedData = useCallback(async () => {
        try {
            const loginToken = await getLoginToken();

            if (loginToken) {
                setApiRequestToken(loginToken);

                const profileData = await getProfile();

                dispatch(isAuthed({ data: profileData }));
            }
        } finally {
            setAppReady(true);
        }
    }, [dispatch]);

    return isAppReady;
}

export default useAppPreparation;
