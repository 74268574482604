import Profile from 'images/Profile.svg';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { getRecipeImages, getRecipePublisherImage } from 'utils/recipeHelpers';

function Recipe(props) {
    const [groupedIngredients, setGroupedIngredients] = useState({});
    const { data } = props;
    const { publisherImage } = useMemo(() => {
        return {
            publisherImage: getRecipePublisherImage(data),
            recipeImages: getRecipeImages(data?.is_uploaded, data?.image_name, data?.images)
        };
    }, [data]);

    useEffect(() => {
        const groupedIngredients = {};

        data?.ingredients?.forEach(ingredient => {
            const section = ingredient?.section || '';

            if (groupedIngredients[section]) {
                groupedIngredients[section] = [...groupedIngredients[section], ingredient];
            } else {
                groupedIngredients[section] = [ingredient];
            }
        });

        setGroupedIngredients(groupedIngredients);
    }, [data?.ingredients]);

    const dietaries = useMemo(() => {
        return data?.recipe?.dietaries?.map((dietary) => dietary.name)?.join(', ');
    }, [data]);

    const cuisine = useMemo(() => {
        return data?.recipe?.cuisines?.map((data) => data.name)?.join(', ');
    }, [data]);

    const commonIngredients = useMemo(() => {
        return data?.recipe?.common_ingredients?.map((data) => data.name)?.join(', ');
    }, [data]);

    const equipment = useMemo(() => {
        return data?.recipe?.equipment?.map((data) => data.name)?.join(', ');
    }, [data]);

    const meals = useMemo(() => {
        return data?.recipe?.meals?.map((data) => data.name)?.join(', ');
    }, [data]);

    const occasion = useMemo(() => {
        return data?.recipe?.occasions?.map((data) => data.name)?.join(', ');
    }, [data]);

    const feeling = useMemo(() => {
        return data?.recipe?.feelings?.map((data) => data.name)?.join(', ');
    }, [data]);

    const renderDifficylty = (difficulty) => {
        switch (difficulty) {
            case 1:
                return 'Easy';
            case 2:
                return 'Medium';
            case 3:
                return 'Hard';
            default:
                return 'Unknown';
        }
    };

    return (
        <>
            <Helmet>
                <title>{`${data?.recipe?.recipename} - Admin YoRipe`}</title>
                <meta name='title' content={`${data?.recipe?.recipename} - Admin YoRipe`} />
                <meta name='description' content={'Recipe Form page to manage all existing challenges and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>Recipe Preview</div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Recipe Name</div>
                        <div className='challenge-section-content'>{data?.recipe?.recipename}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Images</div>
                        <div className='image-grid-container'>{data?.recipe?.images?.map((img, index) => <img key={index} src={img} alt='recipe media' />)}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Publisher</div>
                        <div className='challenge-section-content'>
                            <img className='recipe-publisher-image' src={publisherImage ?? Profile} alt={data?.recipe?.publisher?.name ?? 'YoRipe'} />
                            {data?.recipe?.publisher?.name ?? 'YoRipe'}
                        </div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Description</div>
                        <p className='challenge-section-content'>{data?.recipe?.description}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Servings</div>
                        <p className='challenge-section-content'>{data?.recipe?.servings}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Price Range</div>
                        <p className='challenge-section-content'>{data?.recipe?.price === 1 && '$1 - $50'}{data?.recipe?.price === 2 && '$50 - $100'}{data?.recipe?.price === 3 && '$100 - $150'}{!data?.recipe?.price && 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Language</div>
                        <p className='challenge-section-content'>{!data?.recipe?.language && 'All'}{data?.recipe?.language === 'en' && 'English'}{data?.recipe?.language === 'id' && 'Indo'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Difficulty</div>
                        <p className='challenge-section-content'>{renderDifficylty(data?.recipe?.level_of_difficulty)}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Dietary Tags</div>
                        <p className='challenge-section-content'>{data?.recipe?.dietaries?.length > 0 ? dietaries : 'None'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Prepration Time</div>
                        <p className='challenge-section-content'>{data?.recipe?.preptime} mins</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Cooking Time</div>
                        <p className='challenge-section-content'>{data?.recipe?.cookingtime} mins</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Challenge Tag</div>
                        {data?.recipe?.challenge
                            ? <Link target='_blank' to={`/challenge/${data?.recipe?.challenge?.hashtag.substring(1)}`} className='challenge-section-content'>{data?.recipe?.challenge?.hashtag}</Link>
                            : <p className='challenge-section-content'>None</p>}
                    </div>
                    <div className='data-section-heading'>Ingredients</div>
                    {Object.keys(groupedIngredients).map((key, index) => (
                        <React.Fragment key={index}>
                            {key !== '' && (
                                <div className='ingredient-section-title'>{key}</div>
                            )}
                            {groupedIngredients[key].map((ingredient, index) => {
                                return (
                                    <div className='challenge-section' key={index}>
                                        <div className='challenge-section-title'>{ingredient.name}</div>
                                        <p className='challenge-section-content'>{ingredient?.quantity} {ingredient?.serving} {ingredient?.comment && <span className='challenge-section-comment'>({ingredient?.comment})</span>}</p>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    ))}
                    <div className='data-section-heading'>Steps</div>
                    {data?.recipe?.instructions.map((instruction, index) => {
                        return (
                            <div className='challenge-section' key={index}>
                                <div className='challenge-section-title'>Step {index + 1}</div>
                                <p className='challenge-section-content'>{instruction}</p>
                            </div>
                        );
                    })}
                    <div className='data-section-heading'>Category</div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Common Ingredients</div>
                        <p className='challenge-section-content'>{data?.recipe?.common_ingredients?.length > 0 ? commonIngredients : 'None'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Cuisine</div>
                        <p className='challenge-section-content'>{data?.recipe?.cuisines?.length > 0 ? cuisine : 'None'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Equipment</div>
                        <p className='challenge-section-content'>{data?.recipe?.equipment?.length > 0 ? equipment : 'None'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Meals</div>
                        <p className='challenge-section-content'>{data?.recipe?.meals?.length > 0 ? meals : 'None'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Occasion</div>
                        <p className='challenge-section-content'>{data?.recipe?.occasions?.length > 0 ? occasion : 'None'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Feeling</div>
                        <p className='challenge-section-content'>{data?.recipe?.feelings?.length > 0 ? feeling : 'None'}</p>
                    </div>
                    <div className='challenge-footer'>
                        <Button variant='brand-red mx-2' onClick={props.onSubmit} disabled={!props.isValidated}>Save Recipe</Button>
                        <Button variant='brand-gray mx-2' onClick={props.onBack}>Back to Form</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

Recipe.propTypes = {
    data: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isValidated: PropTypes.bool.isRequired
};

export default memo(Recipe);
