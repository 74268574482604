import { getProfile, login } from 'api/auth';
import { toast } from 'react-toastify';
import { deleteLoginToken, saveLoginToken, setApiRequestToken } from 'utils/helpers';

const APP_READY = 'APP_READY';
const AUTHENTICATING = 'AUTHENTICATING';
const AUTH_FAILED = 'AUTH_FAILED';
const IS_AUTHED = 'IS_AUTHED';

export const LOGGING_OUT = 'LOGGING_OUT';

export function appReady() {
    return {
        type: APP_READY
    };
}

function authenticating() {
    return {
        type: AUTHENTICATING
    };
}

function authFailed() {
    return {
        type: AUTH_FAILED
    };
}

export function isAuthed(data) {
    // use the "getAttributes" method of api sdk to get plain object from api response instead of class
    return {
        type: IS_AUTHED,
        data
    };
}

export function loggingOut() {
    return {
        type: LOGGING_OUT
    };
}

export function handleLoginViaEmail(email, password, callback) {
    return async function(dispatch, getState) {
        dispatch(authenticating());

        try {
            const { token } = await login(email, password);

            if (token) {
                await saveLoginToken(token);
                setApiRequestToken(token);

                const data = await getProfile();

                dispatch(isAuthed({ data: data }));
                callback();
            } else {
                toast.error('Sorry, you entered an incorrect email address or password', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        } catch (err) {
            toast.error(err.message ?? 'There was an error while logging in', {
                position: toast.POSITION.BOTTOM_LEFT
            });
            dispatch(authFailed());
        }
    };
}

export function handleLogout() {
    return function(dispatch) {
        deleteLoginToken();
        dispatch(loggingOut());
    };
}

const initialState = {
    isAppReady: false,
    isAuthenticating: false,
    isAuthed: false,
    profileData: {}
};

export default function authentication(state = initialState, action) {
    switch (action.type) {
        case APP_READY:
            return {
                ...state,
                isAppReady: true
            };

        case AUTHENTICATING:
            return {
                ...state,
                isAuthenticating: true
            };

        case AUTH_FAILED:
            return {
                ...state,
                isAuthenticating: false
            };

        case IS_AUTHED:
            return {
                ...state,
                isAuthenticating: false,
                isAuthed: true,
                profileData: action.data.data
            };

        case LOGGING_OUT:
            return {
                isAppReady: true,
                isAuthenticating: false,
                isAuthed: false,
                profileData: {}
            };

        default:
            return state;
    }
}
