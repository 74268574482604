import { bannersDelete } from 'api/banner';
import { challengesDelete } from 'api/challenge';
import { eventsDelete } from 'api/event';
import { occasionsDelete } from 'api/occasion';
import { productsDelete } from 'api/product';
import { recipesDelete } from 'api/recipe';
import { reviewsDelete } from 'api/review';
import { sellersDelete } from 'api/seller';

export const deleteChallenge = async (ids, callback) => {
    try {
        await challengesDelete(ids);
        callback();
    } catch {
    }
};

export const deleteRecipe = async (ids, callback) => {
    try {
        await recipesDelete(ids);
        callback();
    } catch {
    }
};

export const deletebanner = async (ids, callback) => {
    try {
        await bannersDelete(ids);
        callback();
    } catch {
    }
};

export const deleteEvents = async (ids, callback) => {
    try {
        await eventsDelete(ids);
        callback();
    } catch {
    }
};

export const deleteSeller = async (ids, callback) => {
    try {
        await sellersDelete(ids);
        callback();
    } catch {
    }
};

export const deleteProduct = async (ids, callback) => {
    try {
        await productsDelete(ids);
        callback();
    } catch {
    }
};

export const deleteStory = async (ids, callback) => {
    try {
        await reviewsDelete(ids);
        callback();
    } catch {
    }
};

export const deleteOccasion = async (ids, callback) => {
    try {
        await occasionsDelete(ids);
        callback();
    } catch {
    }
};
