import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Button } from 'react-bootstrap';
import Helmet from 'react-helmet';

function Story({ data, isValidated, onBack, onSubmit }) {
    return (
        <>
            <Helmet>
                <title>{`${data?.title} - Admin YoRipe`}</title>
                <meta name='title' content={`${data?.title} - Admin YoRipe`} />
                <meta name='description' content={'Recipe Form page to manage all existing Story and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>Preview Cooking Story</div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Upload Assets</div>
                        <div className='image-grid-container'>{data?.photos?.map((img, index) => <img key={index} src={img} alt='recipe media' />)}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Title</div>
                        <p className='challenge-section-content'>{data?.title}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Caption</div>
                        <p className='challenge-section-content'>{data?.comment}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Challenge Tag</div>
                        <p className='challenge-section-content'>{data?.challenges[0]?.hashtag ?? 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Recipe Tag</div>
                        <p className='challenge-section-content'>{data?.recipe?.recipename ?? 'null'}</p>
                    </div>
                    <div className='challenge-footer'>
                        <Button variant='brand-red mx-2' onClick={onSubmit} disabled={!isValidated}>Submit Story</Button>
                        <Button variant='brand-gray mx-2' onClick={onBack}>Edit Form</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

Story.propTypes = {
    data: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isValidated: PropTypes.bool.isRequired
};

export default memo(Story);
