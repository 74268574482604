import './index.scss';

import { searchChallenge } from 'api/challenge';
import ConfirmPopup from 'components/ConfirmPopup';
import ROUTE_PATHS from 'config/routePaths';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import { deleteChallenge } from 'utils/adminDelete';
import { getParsedDate } from 'utils/helpers';

function ChallengeScreen() {
    const [query, setQuery] = useState('');
    const [challengeData, setChallengeData] = useState({ page: 1, isLoading: false, data: [] });
    const [selectedItems, setSelectedItems] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();

    const visiblePages = useMemo(() => {
        const startPages = challengeData?.currentPage > 2 ? [1, challengeData?.currentPage - 1] : [1];
        const endPages = (challengeData?.lastPage - challengeData?.currentPage) > 1 ? [challengeData?.currentPage + 1, challengeData?.lastPage] : [challengeData?.lastPage];

        if (challengeData?.lastPage < 4) {
            return [...Array.from({ length: challengeData?.lastPage }, (_, i) => i + 1)];
        } else {
            return [...startPages, challengeData?.currentPage, ...endPages];
        }
    }, [challengeData?.currentPage, challengeData?.lastPage]);

    const deleteCallback = useCallback((ids) => {
        const newArr = challengeData.data.filter(item => !ids.includes(item.id));

        setChallengeData((prev) => ({ ...prev, data: newArr }));
        setSelectedItems([]);
        setShowModal(false);
    }, [challengeData]);

    const deleteHandler = useCallback(() => {
        const data = challengeData.data.filter((item, index) => {
            return selectedItems.includes(index);
        });

        const ids = data.map(item => item.id);

        deleteChallenge(ids, () => deleteCallback(ids));
    }, [deleteCallback, challengeData, selectedItems]);

    const selectHandler = (index) => {
        if (selectedItems.includes(index)) {
            const filtered = selectedItems.filter((item) => item !== index);

            setSelectedItems([...filtered]);
        } else {
            setSelectedItems((prev) => [...prev, index]);
        }
    };

    useEffect(() => {
        if (query) {
            const timeoutId = setTimeout(() => {
                fetchChallengesSearch(1);
            }, 500);

            return () => clearTimeout(timeoutId);
        } else {
            fetchChallengesSearch(1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const fetchChallengesSearch = useCallback(async (page) => {
        try {
            setChallengeData({ ...challengeData, isLoading: true });

            const { data, totalPages } = await searchChallenge(query, page, 10);

            setChallengeData({ currentPage: page, lastPage: totalPages, isLoading: false, data: [...data] });
            setSelectedItems([]);
        } catch {
            setChallengeData({ ...challengeData, isLoading: false });
        }
    }, [challengeData, query]);

    const handleQueryChange = (e) => {
        setQuery(e.target.value);
    };

    const nextPageHandler = () => {
        if (challengeData.currentPage < challengeData.lastPage) {
            fetchChallengesSearch(challengeData.currentPage + 1);
        }
    };

    const prevPageHandler = () => {
        if (challengeData.currentPage > 1) {
            fetchChallengesSearch(challengeData.currentPage - 1);
        }
    };

    const pageSelectHandler = useCallback((page) => {
        fetchChallengesSearch(page);
    }, [fetchChallengesSearch]);

    const renderChallenge = (item, index) => {
        const openItem = () => {
            history.push(`/challenge/${item.id}`);
        };

        return (
            <tr onClick={openItem} key={item.id}>
                <td><input onClick={(e) => e.stopPropagation()} type='checkbox' checked={selectedItems.includes(index)}
                    onChange={() => selectHandler(index)} /></td>
                <td><span className='challenge-name'>{item.name}</span></td>
                <td><Link to={`/challenge/${item.id}`}>{item.hashtag}</Link></td>
                <td>{item.organizer?.name ?? 'YoRipe'}</td>
                <td>{item.category ?? 'null'}</td>
                <td>{getParsedDate(item.start_date) ?? 'null'}</td>
                <td>{getParsedDate(item.end_date) ?? 'null'}</td>
            </tr>
        );
    };

    const renderPages = useCallback(() => {
        if (challengeData?.lastPage) {
            const pages = Array.from({ length: challengeData?.lastPage }, (_, i) => i + 1);

            return (
                <>{pages.map(item => {
                    if (visiblePages.includes(item)) {
                        return <Button key={item} variant={challengeData.currentPage === item ? 'red-hollow' : 'gray_hollow'} onClick={() => pageSelectHandler(item)}>{item}</Button>;
                    } else if (challengeData?.currentPage - 2 === item || challengeData.currentPage + 2 === item) {
                        return <span key={item} className='gray_hollow'>...</span>;
                    }

                    return null;
                })}</>
            );
        }
    }, [pageSelectHandler, challengeData.currentPage, challengeData?.lastPage, visiblePages]);

    return (
        <>
            <Helmet>
                <title>Admin Challenges - YoRipe</title>
                <meta name='title' content='Admin Challenges - YoRipe' />
                <meta name='description' content={'Admin page to manage all existing challenges and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenges-header'>
                    <div className='search-input-wrapper'>
                        <input value={query} onChange={handleQueryChange} placeholder='Search' />
                        <BiSearch color='#818181' size={20} />
                    </div>
                    <Link to={ROUTE_PATHS.CHALLENGE_CREATE} className='no-link challenges-header-create hoverable'>+ Add Challenge</Link>
                </div>
                <div className='data-actions-wrapper'>
                    <Button variant='danger' onClick={() => setShowModal(true)} disabled={selectedItems.length < 1}>Delete</Button>
                </div>
                {challengeData.isLoading && <div className='data-actions-wrapper'><Spinner animation='border' variant='red-1' /></div>}
                <div className='challenge-data-wrapper'>
                    <Table bordered={false} hover={true}>
                        <thead>
                            <tr>
                                <th/>
                                <th>Sharing Description</th>
                                <th>Hashtag</th>
                                <th>Organizer</th>
                                <th>Type</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {challengeData.data.map(renderChallenge)}
                        </tbody>
                    </Table>
                </div>
                <div className='data-table-footer'>
                    <div className='page-info'>Showing ({15 * (challengeData.currentPage - 1) + 1} to {15 * (challengeData.currentPage)}) - Page {challengeData.currentPage} out of {challengeData.lastPage}</div>
                    <div className='pagination-wrapper'>
                        <Button variant='red-hollow' disabled={challengeData.currentPage === 1} onClick={prevPageHandler}>Prev</Button>{' '}
                        {renderPages()}
                        <Button variant='red-hollow' disabled={challengeData.currentPage === challengeData.lastPage} onClick={nextPageHandler}>Next</Button>
                    </div>
                </div>
                <ConfirmPopup
                    isVisible={showModal}
                    onClose={() => setShowModal(false)}
                    onConfirm={deleteHandler}
                    confirmTitle='Delete'
                    title='Delete confirmation'
                    description='Items once deleted cannot be recovered! Are you sure you want to delete?'
                    type={1} />
            </div></>
    );
}

export default memo(ChallengeScreen);
