import { AdminApi } from './api';

/**
 * Get searched users
 * @param {String} query
 * @param {Number} page
 * @param {Number} banStatus
 * @param {Number} perPage
 * @returns {Promise}
 */
export function searchUser(query, page, banStatus, perPage = 15) {
    return AdminApi.get('/v1/users/search', { params: { query, banStatus, page, perPage } });
}

/**
 * Get One By Id
 * @param {Number} id
 * @returns {Promise}
 */
export function getById(id) {
    return AdminApi.get(`/v1/users/${id}`);
}

/**
 * Create users
 * @param {Number} id
 * @returns {Promise}
 */
export function banUser(id) {
    return AdminApi.post('/v1/users/ban/', { id });
}

/**
 * Create users
 * @param {Number} id
 * @returns {Promise}
 */
export function unbanUser(id) {
    return AdminApi.post('/v1/users/unban/', { id });
}
