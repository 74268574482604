const ROUTE_PATHS = {
    DEFAULT: '/',
    RECIPES: '/recipes',
    RECIPES_CREATE: '/recipes/create',
    INDIVIDUAL_RECIPE: '/recipes/:id',
    RECIPE_EDIT: '/recipe-edit/:id',
    COOKING_STORY: '/cooking-story',
    COOKING_STORY_CREATE: '/cooking-story/create',
    INDIVIDUAL_COOKING_STORY: '/cooking-story/:id',
    COOKING_STORY_EDIT: '/cooking-story-edit/:id',
    EVENTS: '/event',
    EVENTS_CREATE: '/event/create',
    INDIVIDUAL_EVENT: '/event/:id',
    EVENTS_EDIT: '/event-edit/:id',
    PRODUCTS: '/products',
    PRODUCTS_CREATE: '/products/create',
    INDIVIDUAL_PRODUCT: '/products/:id',
    PRODUCT_EDIT: '/product-edit/:id',
    CHALLENGE: '/challenge',
    CHALLENGE_CREATE: '/challenge/create',
    INDIVIDUAL_CHALLENGE: '/challenge/:id',
    CHALLENGE_EDIT: '/challenge-edit/:id',
    BRAND_PORTAL: '/brand-portal',
    BRAND_PORTAL_CREATE: '/brand-portal/create',
    INDIVIDUAL_BRAND_PORTAL: '/brand-portal/:id',
    BRAND_PORTAL_EDIT: '/brand-portal-edit/:id',
    BANNER: '/banner',
    BANNER_CREATE: '/banner/create',
    INDIVIDUAL_BANNER: '/banner/:id',
    BANNER_EDIT: '/banner-edit/:id',
    TOP_COLLECTION: '/top-collection',
    TOP_COLLECTION_CREATE: '/top-collection/create',
    INDIVIDUAL_TOP_COLLECTION: '/top-collection/:id',
    TOP_COLLECTION_EDIT: '/top-collection-edit/:id',
    USERS: '/users',
    INDIVIDUAL_USER: '/user/:id',
    RECIPE_REVIEW: '/recipes-review'
};

export default ROUTE_PATHS;
