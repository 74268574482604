import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import { parseRawIngredients } from 'utils/recipeHelpers';

import Ingredient from './Ingredient';

function AddIngredients({ ingredients, setValue, error, ingredientsError }) {
    const [rawIngredients, setRawIngredients] = useState('');
    const [errorMessage, setErrorMessage] = useState(error ?? '');

    useEffect(() => {
        setErrorMessage(error ?? '');
    }, [error]);

    const onDragEnd = useCallback((fromIndex, toIndex) => {
        const data = [...ingredients];
        const item = data.splice(fromIndex, 1)[0];

        data.splice(toIndex, 0, item);
        setValue('ingredients', [...data]);
    }, [ingredients, setValue]);

    const onParseText = useCallback(async () => {
        setErrorMessage('');

        if (rawIngredients.length > 0) {
            const ingredient = await parseRawIngredients(rawIngredients);

            setValue('ingredients', [...ingredient]);
        } else {
            setErrorMessage('Please enter ingredients');
        }
    }, [rawIngredients, setValue]);

    const updateIngredient = useCallback((index, ingredient) => {
        const newIngredients = [...ingredients];

        newIngredients[index] = ingredient;

        setValue('ingredients', newIngredients);
    }, [ingredients, setValue]);

    const removeIngredient = useCallback((index) => {
        const newIngredients = [...ingredients];

        newIngredients.splice(index, 1);

        setValue('ingredients', newIngredients);
    }, [ingredients, setValue]);

    const addIngredientHandler = useCallback(() => {
        setValue('ingredients', [...ingredients, { quantity: '', serving: '', name: '', comment: '', index: ingredients.length, isNew: true, unitError: false }]);
    }, [ingredients, setValue]);

    const groupChangeHandler = (val, index) => {
        const newIngredients = ingredients.map((x, idx) => idx === index ? { section: val } : x);

        setValue('ingredients', newIngredients);
    };

    const addSectionHandler = () => {
        setValue('ingredients', [{ section: '' }, ...ingredients]);
    };

    const renderIngredient = (ingredient, index) => {
        return <li><Ingredient
            key={index}
            groupChangeHandler={groupChangeHandler}
            updateIngredient={(ingredient) => updateIngredient(index, ingredient)}
            index={index}
            removeIngredient={() => removeIngredient(index)}
            ingredient={ingredient} /></li>;
    };

    if (ingredients.length > 0) {
        return (
            <Form.Group className='mb-3' controlId='recipeDescription'>
                <div className='form-label-wrapper'>
                    <Form.Label className='form-label'>Ingredient</Form.Label>
                    <div onClick={addSectionHandler} className='form-label-red hoverable'>+ Add Section</div>
                </div>
                {ingredientsError && <Form.Text className='form-error-message'><strong>Issue Detected:</strong> Please review this section manually.</Form.Text>}
                <ul className='ingredients-dragable'><ReactDragListView
                    nodeSelector='li'
                    onDragEnd={onDragEnd}>{ingredients.map(renderIngredient)}</ReactDragListView></ul>
                {errorMessage && <Form.Text className='form-error-message'>{errorMessage}</Form.Text>}
                <div className='form-label-wrapper'>
                    <div onClick={addIngredientHandler} className='form-label-red hoverable'>+ Add Ingredient</div>
                </div>
            </Form.Group>
        );
    }

    return (
        <>
            <Form.Group className='mb-3' controlId='recipeDescription'>
                <Form.Label className='form-label'>Ingredients <span className='form-label-count'>(Paste ingredient in the provided format)</span></Form.Label>
                {ingredientsError && <Form.Text className='form-error-message'><strong>Issue Detected:</strong> Please review this section manually.</Form.Text>}
                <textarea value={rawIngredients} onChange={(e) => setRawIngredients(e.target.value)}
                    rows={8} type='text'
                    className='form-textarea'
                    placeholder='Please paste your ingredients here using the format below. One ingredient per line.&#10;&#10;Example :&#10;200g rice&#10;4 eggs (medium size)&#10;2pcs carrots (sliced)&#10;1pc turkey ham&#10;2.5tablespoon soy sauce&#10;Salt to taste' />
                {errorMessage && <Form.Text className='form-error-message'>{errorMessage}</Form.Text>}
            </Form.Group>
            <Button variant='brand-red mx-2' onClick={onParseText}>Parse</Button>
        </>
    );
}

AddIngredients.propTypes = {
    ingredients: PropTypes.array.isRequired,
    error: PropTypes.object,
    setValue: PropTypes.func.isRequired,
    ingredientsError: PropTypes.bool
};

export default memo(AddIngredients);
