import ConfirmPopup from 'components/ConfirmPopup';
import { imageEndpoints } from 'config/constants';
import PropTypes from 'prop-types';
import React, { memo, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';

function ChallengePreview({ challenge, winners, onBack, onSubmit, isValidated }) {
    const [showModal, setShowModal] = useState(false);
    const { equalWinners, rankedWinners, topLiked } = useMemo(() => {
        let equalWinners = [];
        let rankedWinners = [];
        let topLiked = [];

        winners.forEach(item => {
            switch (item.winner_category) {
                case 'Equal Winners':
                    equalWinners = item.winners;
                    break;

                case 'Ranking':
                    rankedWinners = item.winners;
                    break;

                case 'Top Votes':
                    topLiked = item.winners;
                    break;

                default:
                    break;
            }
        });

        return { equalWinners, rankedWinners, topLiked };
    }, [winners]);

    const renderTopWinner = (item, index) => {
        if (item.id) {
            return <><span key={index}><strong>&bull;</strong> {item.placeholder}</span><br/></>;
        }
    };

    const renderWinner = (item, index) => {
        if (item.id) {
            return <><span key={index}>{index + 1}. {item.placeholder}</span><br/></>;
        }
    };

    const submitHandler = () => {
        if (equalWinners?.length + rankedWinners?.length + topLiked?.length > 0) {
            setShowModal(true);
        } else {
            onSubmit();
        }
    };

    const confirmSubmit = () => {
        onSubmit();
        setShowModal(false);
    };

    return (
        <div className='page-wrapper'>
            <div className='challenge-wrapper'>
                <div className='challenge-header'>Add Challenge</div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Sharing Description</div>
                    <div className='challenge-section-content'>{challenge?.name}</div>
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Hashtag</div>
                    <div className='challenge-section-content'>{challenge?.hashtag}</div>
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Organizer Name</div>
                    <div className='challenge-section-content'>{challenge?.organizer?.name}</div>
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Organizer Image</div>
                    {challenge?.organizer?.image && <img className='challenge-section-media' src={imageEndpoints.organizerUrl + challenge?.organizer?.image} alt={challenge?.organizer?.name} />}
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Image</div>
                    {challenge?.image && <img className='challenge-section-media' src={imageEndpoints.challengeUrl + challenge?.image} alt={challenge?.hashtag} />}
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Language</div>
                    <p className='challenge-section-content'>{!challenge?.language && 'All'}{challenge?.language === 'en' && 'English'}{challenge?.language === 'id' && 'Indo'}</p>
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Type</div>
                    <div className='challenge-section-content'>{challenge?.type === 1 && 'Cooking Story'}{challenge?.type === 2 && 'Recipe'}{challenge?.type === 3 && 'Recipe & Cooking Story'}</div>
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Category</div>
                    <div className='challenge-section-content'>{challenge?.category}</div>
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Short Description</div>
                    <p className='challenge-section-content'>{challenge?.short_description}</p>
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Rewards</div>
                    {challenge?.rewards ? <div className='challenge-section-content'>{challenge?.rewards.map((reward, index) => <><span key={index}>&bull; {reward}</span><br/></>)}</div> : <div className='challenge-section-content'>No rewards</div>}
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Description</div>
                    <p className='challenge-section-content'>{challenge?.description}</p>
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Promo Code</div>
                    <div className='challenge-section-content'>{challenge?.promo_code ?? 'null'}</div>
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Promo Information</div>
                    <div className='challenge-section-content'>{challenge?.promo_info ?? 'null'}</div>
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Promo Site</div>
                    {challenge?.promo_website ? <a href={challenge?.promo_website} className='challenge-section-content'>{challenge?.promo_website}</a> : <div className='challenge-section-content'>null</div>}
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Start Date</div>
                    <div className='challenge-section-content'>{challenge?.start_date}</div>
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>End Date</div>
                    <div className='challenge-section-content'>{challenge?.end_date ?? 'null'}</div>
                </div>

                {(equalWinners?.length + rankedWinners?.length + topLiked?.length > 0) && <div className='winners-form-title'>Winners List</div>}

                {equalWinners?.length > 0 && <><div className='challenge-section'>
                    <div className='challenge-section-title'>Category</div>
                    <div className='challenge-section-content'>Equal winners</div>
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Winners</div>
                    <div className='challenge-section-content'>{equalWinners?.map(renderTopWinner)}</div>
                </div></>}

                {rankedWinners?.length > 0 && <><div className='challenge-section'>
                    <div className='challenge-section-title'>Category</div>
                    <div className='challenge-section-content'>Ranking</div>
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Winners</div>
                    <div className='challenge-section-content'>{rankedWinners?.map(renderWinner)}</div>
                </div></>}

                {topLiked?.length > 0 && <><div className='challenge-section'>
                    <div className='challenge-section-title'>Category</div>
                    <div className='challenge-section-content'>Top Votes</div>
                </div>
                <div className='challenge-section'>
                    <div className='challenge-section-title'>Winners</div>
                    <div className='challenge-section-content'>{topLiked?.map(renderWinner)}</div>
                </div></>}

                <div className='challenge-footer'>
                    <Button variant='brand-red mx-2' disabled={!isValidated} onClick={submitHandler}>Save Challenge</Button>
                    <Button variant='brand-gray mx-2' onClick={onBack}>Back to Form</Button>
                </div>
            </div>
            <ConfirmPopup
                isVisible={showModal}
                onClose={() => setShowModal(false)}
                confirmTitle='Yes, save winners'
                onConfirm={confirmSubmit}
                title='Are you sure want to save?'
                description="Winners won't be notified yet, but the information will appear on the app."
                type={2} />
        </div>
    );
}

ChallengePreview.propTypes = {
    challenge: PropTypes.object.isRequired,
    winners: PropTypes.array.isRequired,
    onBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isValidated: PropTypes.bool.isRequired
};

export default memo(ChallengePreview);
