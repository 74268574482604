import { searchChallenge } from 'api/challenge';
import { uploadImageDir } from 'api/upload';
import ConfirmPopup from 'components/ConfirmPopup';
import Media from 'components/Media';
import { imageEndpoints } from 'config/constants';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { AiFillEye } from 'react-icons/ai';
import { BiCalendarEvent } from 'react-icons/bi';
import { BsDashLg, BsFillImageFill } from 'react-icons/bs';
import Resizer from 'react-image-file-resizer';
import { useHistory } from 'react-router-dom';

function BannerForm(props) {
    const bannerImageRef = useRef();
    const [isUploading, setUploading] = useState(false);
    const history = useHistory();
    const [challengeData, setChallengeData] = useState({ page: 1, isLoading: false, data: [] });
    const [showList, setShowList] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [challengeQuery, setChallengeQuery] = useState('');

    const fetchChallenges = useCallback(async (query) => {
        try {
            setChallengeData({ ...challengeData, isLoading: true });

            const { data, totalPages } = await searchChallenge(query, 1, 10);

            setChallengeData({ currentPage: data.current_page, lastPage: totalPages, isLoading: false, data: [...data] });
        } catch {
            setChallengeData({ ...challengeData, isLoading: false });
        }
    }, [challengeData]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchChallenges(challengeQuery);
        }, 500);

        return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [challengeQuery]);

    useEffect(() => {
        setChallengeQuery(props.fields?.navigation_params?.hashtag || '');
    }, [props.fields?.navigation_params]);

    const challengeSelectHandler = useCallback((challenge) => {
        setChallengeQuery(challenge.hashtag);
        setShowList(false);
        props.setValue('navigation_params', { challengeId: challenge.id, hashtag: challenge.hashtag });
    }, [props]);

    const renderChallenge = useCallback(() => {
        if (challengeData.data.length === 0) {
            return <span className='challenge-type'>Choose Challenge Category</span>;
        }

        return challengeData.data.map((challenge) => {
            return (
                <Dropdown.Item key={challenge.id} onClick={() => challengeSelectHandler(challenge)}>{challenge.hashtag}</Dropdown.Item>
            );
        });
    }, [challengeData.data, challengeSelectHandler]);

    const uploadChallengeImage = useCallback(async (image) => {
        try {
            setUploading(true);

            const uploadResponse = await uploadImageDir('banners', image);

            props.setValue('image', uploadResponse);
        } finally {
            setUploading(false);
        }
    }, [props]);

    const bannerImageHandler = useCallback(async (event) => {
        if (event.target.files[0]) {
            try {
                Resizer.imageFileResizer(event.target.files[0], 2048, 2048, 'PNG', 85, 360, (uri) => uploadChallengeImage(uri));
            } catch (err) {
            }
        }
    }, [uploadChallengeImage]);

    const renderLocation = useCallback(() => {
        if (props.fields?.location === 1) {
            return <div className='challenge-type selected-type-text'>Shop Page</div>;
        } else if (props.fields?.location === 2) {
            return <div className='challenge-type selected-type-text'>Challenge Page</div>;
        } else if (props.fields?.location === 3) {
            return <div className='challenge-type selected-type-text'>Search Page</div>;
        }
    }, [props.fields?.location]);

    const renderType = useCallback(() => {
        if (props.fields?.type === 1) {
            return <div className='challenge-type selected-type-text'>URL</div>;
        } else if (props.fields?.type === 2) {
            return <div className='challenge-type selected-type-text'>Challenge</div>;
        }
    }, [props.fields?.type]);

    const renderLanguage = useCallback(() => {
        if (props.fields?.language === 'en') {
            return <div className='challenge-type selected-type-text'>English</div>;
        } else if (props.fields?.language === 'id') {
            return <div className='challenge-type selected-type-text'>Indo</div>;
        } else {
            return <div className='challenge-type selected-type-text'>All</div>;
        }
    }, [props.fields?.language]);

    return (
        <div className='form-wrapper'>
            <Form>
                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Image <span className='form-label-info'>(Less than 1mb)</span></Form.Label>
                    {props.fields?.image
                        ? <Media onClose={() => props.setValue('image', '')} src={imageEndpoints.bannersUrl + props.fields?.image} alt='Challenge'
                            className='form-banner-image' />
                        : <div onClick={() => bannerImageRef.current.click()} className={`image-placeholder-wrapper hoverable ${isUploading ? 'disabled' : ''}`}>
                            <BsFillImageFill color='#bdbdbd' size={40} />
                            <span>Upload imgae.</span>
                        </div>}
                    <input type='file' multiple={false} ref={bannerImageRef}
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={bannerImageHandler} />
                    {props.errors?.image?.message && <Form.Text className='form-error-message'>{props.errors?.image?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeType'>
                    <Form.Label className='form-label'>Language</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>{renderLanguage()}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => props.setValue('language', null)}><span className={!props.fields?.language ? 'color-brand-imp' : ''}>All</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setValue('language', 'en')}><span className={props.fields?.language === 1 ? 'color-brand-imp' : ''}>English</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setValue('language', 'id')}><span className={props.fields?.language === 2 ? 'color-brand-imp' : ''}>Indo</span></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeType'>
                    <Form.Label className='form-label'>Page</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>{renderLocation()}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => props.setValue('location', 1)}><span className={props.fields?.location === 1 ? 'color-brand-imp' : ''}>Shop Page</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setValue('location', 2)}><span className={props.fields?.location === 2 ? 'color-brand-imp' : ''}>Challenge Page</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setValue('location', 3)}><span className={props.fields?.location === 3 ? 'color-brand-imp' : ''}>Search Page</span></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {props.errors?.location?.message && <Form.Text className='form-error-message'>{props.errors?.location?.message}</Form.Text>}
                </Form.Group>

                <div className='dates-input-wrapper'>
                    <Form.Group className='mb-3' controlId='challengeHashtag'>
                        <Form.Label className='form-label'>Start Date</Form.Label>
                        <div className='date-wrapper'>
                            <DatePicker className='date-wrapper-input' selected={props.fields?.from_date}
                                onSelect={(date) => props.setValue('from_date', date)}
                                onChange={(date) => props.setValue('from_date', date)} />
                            <BiCalendarEvent size={18} color='#838383' />
                        </div>
                        {props.errors?.from_date?.message && <Form.Text className='form-error-message'>{props.errors?.from_date?.message}</Form.Text>}
                    </Form.Group>
                    <BsDashLg size={18} color='#838383' />
                    <Form.Group className='mb-3' controlId='challengeHashtag'>
                        <Form.Label className='form-label'>End Date</Form.Label>
                        <div className='date-wrapper'>
                            <DatePicker className='date-wrapper-input' selected={props.fields?.to_date}
                                onSelect={(date) => props.setValue('to_date', date)}
                                onChange={(date) => props.setValue('to_date', date)} />
                            <BiCalendarEvent size={18} color='#838383' />
                        </div>
                    </Form.Group>
                </div>

                <Form.Group className='mb-3' controlId='challengeType'>
                    <Form.Label className='form-label'>Choose Linkout</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>{renderType()}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => props.setValue('type', 1)}><span className={props.fields?.type === 1 ? 'color-brand-imp' : ''}>URL</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setValue('type', 2)}><span className={props.fields?.type === 2 ? 'color-brand-imp' : ''}>Challenge</span></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    {props.fields?.type === 1 && <input value={props.fields?.url} onChange={(e) => props.setValue('url', e.target.value)}
                        className='form-input' type='text' placeholder='Enter linkout url' />}
                    {props.fields?.type === 2 && <Dropdown onToggle={setShowList}>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>
                            <input value={challengeQuery} onChange={(e) => setChallengeQuery(e.target.value)}
                                className='organizer-input'
                                type='text'
                                placeholder='Choose Challenge Tag' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu show={showList}>
                            {renderChallenge()}
                        </Dropdown.Menu>
                    </Dropdown>}
                    {props.errors?.url?.message && <Form.Text className='form-error-message'>{props.errors?.url?.message}</Form.Text>}
                    {props.errors?.navigation_params?.message && <Form.Text className='form-error-message'>{props.errors?.navigation_params?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeName'>
                    <Form.Label className='form-label'>Order</Form.Label>
                    <input value={props.fields?.order} onChange={(e) => props.setValue('order', e.target.value)}
                        className='form-input' type='text' placeholder='Enter Number order' />
                    {props.errors?.order?.message && <Form.Text className='form-error-message'>{props.errors?.order?.message}</Form.Text>}
                </Form.Group>
            </Form>
            <div className='form-footer'>
                <Button onClick={props.showPreview} variant='brand-red-hollow' ><AiFillEye color='#FF7268' />  Preview</Button>
                <div>
                    <Button variant='brand-gray mx-2' onClick={() => setShowModal(true)}>Cancel</Button>
                    <Button variant='brand-red mx-2' onClick={props.saveHandler}>Save Banner</Button>
                </div>
            </div>
            <ConfirmPopup
                isVisible={showModal}
                onClose={() => setShowModal(false)}
                confirmTitle='Leave'
                onConfirm={history.goBack}
                title='Unsaved Changes'
                description='Are you sure want to discard this changes? Changes you made may not be saved.'
                type={1} />
        </div>
    );
}

BannerForm.propTypes = {
    fields: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    saveHandler: PropTypes.func.isRequired,
    showPreview: PropTypes.func.isRequired
};

export default memo(BannerForm);
