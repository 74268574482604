import PropTypes from 'prop-types';
import React, { memo, useMemo, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { RiDeleteBinFill } from 'react-icons/ri';

import WinnerSelect from './WinnerSelect';

function WinnersCategory({ item, index, winnersList, setWinnersList, selectedWinners, challengeId }) {
    const [showModal, setShowModal] = useState(false);

    const addWinnerCategory = () => {
        setWinnersList(prev => [...prev, { winner_category: '', winners: [] }]);
    };

    const existingCategory = useMemo(() => {
        return winnersList.map(item => item.winner_category);
    }, [winnersList]);

    const categoryDeleteHandler = () => {
        const newArr = winnersList?.filter((item, idx) => index !== idx);

        setWinnersList([...newArr]);
        setShowModal(false);
    };

    const categoryUpdateHandler = (data) => {
        const newArr = winnersList?.map((item, idx) => index === idx ? data : item);

        setWinnersList([...newArr]);
    };

    const categoryClickHandler = (category) => {
        switch (category) {
            case 'Equal Winners':
                categoryUpdateHandler({ winner_category: category, winners: [{ winner_category: category }] });
                break;

            case 'Ranking':
                categoryUpdateHandler({ winner_category: category, winners: [{ winner_category: category }] });
                break;

            case 'Top Votes':
                categoryUpdateHandler({ winner_category: category, winners: [{ winner_category: category }, { winner_category: category }, { winner_category: category }] });
                break;

            default:
                break;
        }
    };

    const winnerDelete = (index) => {
        const newArr = item?.winners?.filter((item, idx) => index !== idx);

        categoryUpdateHandler({ ...item, winners: [...newArr] });
    };

    const winnerUpdate = (data, index) => {
        const newArr = item?.winners?.map((item, idx) => index === idx ? data : item);

        categoryUpdateHandler({ ...item, winners: [...newArr] });
    };

    const addWinner = () => {
        categoryUpdateHandler({ ...item, winners: [...item?.winners, { winner_category: item.winner_category }] });
    };

    return (
        <><Form.Group className='mb-3' controlId='challengeType'>
            <div className='category-header'>
                <Form.Label className='form-label'>Category</Form.Label>
                {(item.winner_category !== '' && index === winnersList.length - 1 && winnersList.length < 3) && <div onClick={addWinnerCategory} className={item?.winners[0]?.id ? 'category-dropdown-add' : 'category-dropdown-disabled'}>+ Add Category</div>}
            </div>
            <Form.Text className='text-muted'>(<strong>Equal winners</strong>: No ranking system | <strong>Ranking</strong>: e.g. 1st Winner, 2nd Winner | <strong>Top votes</strong>: Automatic & based on likes)</Form.Text>

            <div className='category-dropdown'>
                <div className='category-dropdown-wrapper'>
                    <Dropdown>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'><div className='challenge-type'>{item.winner_category || 'Choose One'}</div></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item disabled={existingCategory.includes('Equal Winners')} onClick={() => categoryClickHandler('Equal Winners')}>
                                <span className={item.winner_category === 'Equal Winners' ? 'color-brand-imp' : ''}>Equal Winners</span>
                            </Dropdown.Item>
                            <Dropdown.Item disabled={existingCategory.includes('Ranking')} onClick={() => categoryClickHandler('Ranking')}>
                                <span className={item.winner_category === 'Ranking' ? 'color-brand-imp' : ''}>Ranking</span>
                            </Dropdown.Item>
                            <Dropdown.Item disabled={existingCategory.includes('Top Votes')} onClick={() => categoryClickHandler('Top Votes')}>
                                <span className={item.winner_category === 'Top Votes' ? 'color-brand-imp' : ''}>Top Votes</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {winnersList?.length > 1 && <RiDeleteBinFill className='hoverable' onClick={() => setShowModal(true)} color='#C2C2C2'
                    size={25} />}
            </div>
            <div className='winner-margin-left'>
                {item.winners.map((itm, index) =>
                    <WinnerSelect
                        key={index}
                        data={itm}
                        selectedWinners={selectedWinners}
                        challengeId={challengeId}
                        winnerUpdate={winnerUpdate}
                        winnerDelete={winnerDelete}
                        index={index} />
                )}
                {item?.winner_category !== '' && <Button variant='brand-red' onClick={addWinner}>Add Winner</Button>}
            </div>
        </Form.Group>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton={true}>
                <Modal.Title>Are you sure want to delete this category?</Modal.Title>
            </Modal.Header>
            <Modal.Body>The selected winners in this category will be deleted too.</Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={() => setShowModal(false)}>Cancel</Button>
                <Button variant='danger' onClick={categoryDeleteHandler}>Delete</Button>
            </Modal.Footer>
        </Modal></>
    );
}

WinnersCategory.propTypes = {
    winnersList: PropTypes.array.isRequired,
    setWinnersList: PropTypes.func.isRequired,
    selectedWinners: PropTypes.array.isRequired,
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    challengeId: PropTypes.number.isRequired
};

export default memo(WinnersCategory);
