import { imageEndpoints } from 'config/constants';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Button } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { getParsedDate } from 'utils/helpers';

function Banner({ data, isValidated, onSubmit, onBack }) {
    return (
        <>
            <Helmet>
                <title>Banner - Admin YoRipe</title>
                <meta name='title' content='Banner - Admin YoRipe' />
                <meta name='description' content={'Banners page to manage all existing banners and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>Banner Preview</div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Image</div>
                        <img className='banner-image-form' src={imageEndpoints.bannersUrl + data?.image} alt='Banner media' />
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Language</div>
                        <p className='challenge-section-content'>{!data?.language && 'All'}{data?.language === 'en' && 'English'}{data?.language === 'id' && 'Indo'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Page</div>
                        <p className='challenge-section-content'>{data?.location === 1 && 'Shop page'}{data?.location === 2 && 'Challenge page'}{data?.location === 3 && 'Search page'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>From When</div>
                        <p className='challenge-section-content'>{data?.from_date ? getParsedDate(data.from_date) : 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>To When</div>
                        <p className='challenge-section-content'>{data?.to_date ? getParsedDate(data?.to_date) : 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Linkout Url</div>
                        <p className='challenge-section-content'>{data?.url ?? 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Challenge Linkout</div>
                        <p className='challenge-section-content'>{data?.navigation_params?.hashtag ?? 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Order</div>
                        <p className='challenge-section-content'>{data?.order ?? 'None'}</p>
                    </div>
                    <div className='challenge-footer'>
                        <Button variant='brand-red mx-2' onClick={onSubmit} disabled={!isValidated}>Save Banner</Button>
                        <Button variant='brand-gray mx-2' onClick={onBack}>Back to Form</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

Banner.propTypes = {
    data: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isValidated: PropTypes.bool.isRequired
};

export default memo(Banner);
