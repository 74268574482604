import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { MdClose } from 'react-icons/md';

function Media({ src, onClose, alt, className }) {
    return (
        <div className='form-image-wrapper'>
            <img src={src} alt={alt} className={className} />
            <div onClick={onClose} className='form-input-image-close'><MdClose color='#fff' width={20} height={20} /></div>
        </div>
    );
}

Media.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
};

export default memo(Media);
