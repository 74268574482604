import { createEvent, getById, updateEvent } from 'api/event';
import EventPreview from 'components/EventPreview';
import { parseISO } from 'date-fns';
import React, { memo, useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isValidHttpUrl } from 'utils/helpers';

import DataForm from './components/DataForm';

function BannerForm() {
    const [showPreview, setShowPreview] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const [parsedData, setParsedData] = useState({});
    const [categories, setCategories] = useState([]);
    const [categoryError, setCategoryError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { id } = useParams();
    const history = useHistory();
    const { register, watch, setValue, clearErrors, trigger, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            name: '',
            banner_image: null,
            start_date: new Date(),
            end_date: new Date(),
            website_link: null,
            group_link: null,
            language: null,
            category: null,
            button_text: '',
            description: '',
            embed_link: ''
        }
    });

    const fields = watch();

    useEffect(() => {
        register('name', { required: 'This field is Required' });
        register('banner_image', { required: 'This field is Required' });
        register('start_date', { required: 'This field is Required' });
        register('end_date', { required: 'This field is Required' });
        register('website_link', { required: 'Please enter a URL' });
        register('embed_link');
        register('group_link');
        register('language');
        register('description');
        register('button_text');
    }, [register]);

    const fetchData = useCallback(async () => {
        try {
            const data = await getById(id);

            if (data) {
                setValue('id', id);
                setValue('name', data?.name);
                setValue('description', data?.description);
                setValue('button_text', data?.button_text);
                setValue('banner_image', data?.banner_image);
                setValue('start_date', data?.start_date ? parseISO(data?.start_date) : new Date());
                setValue('end_date', data?.end_date ? parseISO(data?.end_date) : new Date());
                setValue('language', data?.language);
                setValue('website_link', data?.website_link);
                setValue('embed_link', data?.embed_link);
                setValue('group_link', data?.group_link);
            }

            if (data?.category) {
                setCategories([data?.category]);
            }
        } catch {
            toast.error('Unable to fetch Event', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }, [id, setValue]);

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [fetchData, id]);

    const validateUrl = (url) => {
        const isValid = isValidHttpUrl(url);

        if (isValid) {
            return url;
        } else {
            return 'https://' + url;
        }
    };

    const parseChallengeData = useCallback(() => {
        const parsedData = {
            name: fields?.name,
            banner_image: fields?.banner_image,
            start_date: fields?.start_date ? new Date(fields.start_date) : '',
            end_date: fields?.end_date ? new Date(fields.end_date) : '',
            language: fields?.language,
            website_link: validateUrl(fields?.website_link),
            embed_link: fields?.embed_link ? validateUrl(fields?.embed_link) : '',
            group_link: fields?.group_link ? validateUrl(fields?.group_link) : '',
            button_text: fields?.button_text,
            description: fields?.description,
            category: categories[0] ?? ''
        };

        return parsedData;
    }, [fields, categories]);

    const previewHandler = useCallback(async () => {
        setParsedData(parseChallengeData());
        setShowPreview(true);
    }, [parseChallengeData]);

    const saveHandler = useCallback(async () => {
        clearErrors();

        const testArr = ['name', 'banner_image', 'website_link', 'start_date', 'end_date', 'group_link', 'embed_link'];

        let isValid = await trigger(testArr);

        if (categories.length === 0) {
            isValid = false;
            setCategoryError('Please select atleast 1 category.');
        } else {
            setCategoryError('');
        }

        if (isValid) {
            previewHandler();
            setIsValidated(true);
        }
    }, [categories.length, clearErrors, previewHandler, trigger]);

    const editSaveHandler = useCallback(async () => {
        try {
            setIsSubmitting(true);

            const data = await updateEvent(id, parsedData);

            if (data.id) {
                toast.success('Thank you. Your Changes has been saved.', {
                    position: toast.POSITION.BOTTOM_LEFT
                });

                history.push(`/event/${data.id}`);
            }
        } catch {
            toast.error('Unable to save the changes ', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsSubmitting(false);
        }
    }, [history, id, parsedData]);

    const submitBannerHandler = useCallback(async () => {
        if (id) {
            editSaveHandler();
        } else {
            try {
                setIsSubmitting(true);

                const { id } = await createEvent(parsedData);

                if (id) {
                    toast.success('Thank you. Your Event has been submitted.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    });

                    history.push(`/event/${id}`);
                }
            } catch (error) {
                toast.error('Unable to publish the event ', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            } finally {
                setIsSubmitting(false);
            }
        }
    }, [editSaveHandler, history, id, parsedData]);

    return (
        <>
            <Helmet>
                <title>{id ? 'Edit Event' : 'Create Event'} - YoRipe</title>
                <meta name='title' content={id ? 'Edit Event - YoRipe' : 'Create Event - YoRipe'}/>
                <meta name='description' content={'Event Form page to manage all existing events and create more!'} />
            </Helmet>
            <div className={`page-wrapper ${isSubmitting ? 'disabled' : ''}`}>
                {showPreview
                    ? <EventPreview
                        data={parsedData}
                        isValidated={isValidated}
                        onBack={() => setShowPreview(false)}
                        onSubmit={submitBannerHandler} />
                    : <DataForm
                        fields={fields}
                        errors={errors}
                        setValue={setValue}
                        categories={categories}
                        setCategories={setCategories}
                        categoryError={categoryError}
                        saveHandler={saveHandler}
                        showPreview={previewHandler}/>}
            </div>
        </>
    );
}

export default memo(BannerForm);
