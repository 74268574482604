import './index.scss';

import { searchBanner } from 'api/banner';
import ConfirmPopup from 'components/ConfirmPopup';
import { imageEndpoints } from 'config/constants';
import ROUTE_PATHS from 'config/routePaths';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import { deletebanner } from 'utils/adminDelete';
import { getParsedDate } from 'utils/helpers';

function BannersScreen() {
    const [query, setQuery] = useState('');
    const [searchData, setSerachData] = useState({ page: 1, isLoading: false, data: [] });
    const [selectedItems, setSelectedItems] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();

    const visiblePages = useMemo(() => {
        const startPages = searchData?.currentPage > 2 ? [1, searchData?.currentPage - 1] : [1];
        const endPages = (searchData?.lastPage - searchData?.currentPage) > 1 ? [searchData?.currentPage + 1, searchData?.lastPage] : [searchData?.lastPage];

        if (searchData?.lastPage < 4) {
            return [...Array.from({ length: searchData?.lastPage }, (_, i) => i + 1)];
        } else {
            return [...startPages, searchData?.currentPage, ...endPages];
        }
    }, [searchData?.currentPage, searchData?.lastPage]);

    const deleteCallback = useCallback((ids) => {
        const newArr = searchData.data.filter(item => !ids.includes(item.id));

        setSerachData((prev) => ({ ...prev, data: newArr }));
        setSelectedItems([]);
        setShowModal(false);
    }, [searchData]);

    const deleteHandler = useCallback(() => {
        const data = searchData.data.filter((item, index) => {
            return selectedItems.includes(index);
        });

        const ids = data.map(item => item.id);

        deletebanner(ids, () => deleteCallback(ids));
    }, [deleteCallback, searchData, selectedItems]);

    const selectHandler = (index) => {
        if (selectedItems.includes(index)) {
            const filtered = selectedItems.filter((item) => item !== index);

            setSelectedItems([...filtered]);
        } else {
            setSelectedItems((prev) => [...prev, index]);
        }
    };

    useEffect(() => {
        if (query) {
            const timeoutId = setTimeout(() => {
                fetchData(1);
            }, 500);

            return () => clearTimeout(timeoutId);
        } else {
            fetchData(1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const fetchData = useCallback(async (page) => {
        try {
            setSerachData((prev) => ({ ...prev, isLoading: true }));

            const { data, totalPages } = await searchBanner(query, page, 10);

            setSerachData({ currentPage: page, lastPage: totalPages, isLoading: false, data: [...data] });
            setSelectedItems([]);
        } catch {
            setSerachData((prev) => ({ ...prev, isLoading: false }));
        }
    }, [query]);

    const handleQueryChange = (e) => {
        setQuery(e.target.value);
    };

    const nextPageHandler = () => {
        if (searchData.currentPage < searchData.lastPage) {
            fetchData(searchData.currentPage + 1);
        }
    };

    const prevPageHandler = () => {
        if (searchData.currentPage > 1) {
            fetchData(searchData.currentPage - 1);
        }
    };

    const pageSelectHandler = useCallback((page) => {
        fetchData(page);
    }, [fetchData]);

    const renderChallenge = (item, index) => {
        const openItem = () => {
            history.push(`/banner/${item.id}`);
        };

        return (
            <tr onClick={openItem} key={item.id}>
                <td><input onClick={(e) => e.stopPropagation()} type='checkbox' checked={selectedItems.includes(index)}
                    onChange={() => selectHandler(index)} /></td>
                <td><Link to={`/banner/${item.id}`}><img src={imageEndpoints.bannersUrl + item.image} className='banner-image' alt='recipe' /></Link></td>
                <td>{item.location === 1 && 'Shop Page'}{item.location === 2 && 'Challenge Page'}{item.location === 3 && 'Search Page'}</td>
                <td>{getParsedDate(item.from_date) ?? 'null'}</td>
                <td>{getParsedDate(item.to_date) ?? 'null'}</td>
                <td className='banner-linkout'>{item?.navigation_params ? JSON.parse(item?.navigation_params).hashtag : item?.url}</td>
                <td>{item?.order !== null ? item.order : 'null'}</td>
            </tr>
        );
    };

    const renderPages = useCallback(() => {
        if (searchData?.lastPage) {
            const pages = Array.from({ length: searchData?.lastPage }, (_, i) => i + 1);

            return (
                <>{pages.map(item => {
                    if (visiblePages.includes(item)) {
                        return <Button key={item} variant={searchData.currentPage === item ? 'red-hollow' : 'gray_hollow'} onClick={() => pageSelectHandler(item)}>{item}</Button>;
                    } else if (searchData?.currentPage - 2 === item || searchData.currentPage + 2 === item) {
                        return <span key={item} className='gray_hollow'>...</span>;
                    }

                    return null;
                })}</>
            );
        }
    }, [pageSelectHandler, searchData.currentPage, searchData?.lastPage, visiblePages]);

    return (
        <>
            <Helmet>
                <title>Admin Banners - YoRipe</title>
                <meta name='title' content='Admin Banners - YoRipe' />
                <meta name='description' content={'Banners page to manage all existing Banners and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenges-header'>
                    <div className='search-input-wrapper'>
                        <input value={query} onChange={handleQueryChange} placeholder='Search' />
                        <BiSearch color='#818181' size={20} />
                    </div>
                    <Link to={ROUTE_PATHS.BANNER_CREATE} className='no-link challenges-header-create hoverable'>+ Add Banner</Link>
                </div>
                <div className='data-actions-wrapper'>
                    <Button variant='danger' onClick={() => setShowModal(true)} disabled={selectedItems.length < 1}>Delete</Button>
                </div>
                {searchData.isLoading && <div className='data-actions-wrapper'><Spinner animation='border' variant='red-1' /></div>}
                <div className='challenge-data-wrapper'>
                    <Table bordered={false} hover={true}>
                        <thead>
                            <tr>
                                <th/>
                                <th>Asset</th>
                                <th>Page</th>
                                <th>From When</th>
                                <th>To When</th>
                                <th>Linkout</th>
                                <th>Order</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchData.data.map(renderChallenge)}
                        </tbody>
                    </Table>
                </div>
                <div className='data-table-footer'>
                    <div className='page-info'>Showing ({10 * (searchData.currentPage - 1) + 1} to {10 * (searchData.currentPage - 1) + searchData.data.length}) - Page {searchData.currentPage} out of {searchData.lastPage}</div>
                    <div className='pagination-wrapper'>
                        <Button variant='red-hollow' disabled={searchData.currentPage === 1} onClick={prevPageHandler}>Prev</Button>
                        {renderPages()}
                        <Button variant='red-hollow' disabled={searchData.currentPage === searchData.lastPage} onClick={nextPageHandler}>Next</Button>
                    </div>
                </div>
                <ConfirmPopup
                    isVisible={showModal}
                    onClose={() => setShowModal(false)}
                    confirmTitle='Delete'
                    onConfirm={deleteHandler}
                    title='Delete confirmation'
                    description='Items once deleted cannot be recovered! Are you sure you want to delete?'
                    type={1} />
            </div></>
    );
}

export default memo(BannersScreen);
