import './index.scss';

import { getById } from 'api/banner';
import { imageEndpoints } from 'config/constants';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getParsedDate } from 'utils/helpers';

function Banner() {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            const response = await getById(id);

            if (response) {
                setData(response);
            }
        } catch {
            toast.error('Unable to fetch Banner', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title>Banner - Admin YoRipe</title>
                <meta name='title' content='Banner - Admin YoRipe' />
                <meta name='description' content={'Banner page to manage all existing banners and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>View Banner</div>
                    {isLoading && <div className='challenge-header'><Spinner animation='border' variant='red-1' /></div>}
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Image</div>
                        <img className='banner-image-form' src={imageEndpoints.bannersUrl + data?.image} alt='recipe media' />
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Language</div>
                        <p className='challenge-section-content'>{!data?.language && 'All'}{data?.language === 'en' && 'English'}{data?.language === 'id' && 'Indo'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Page</div>
                        <p className='challenge-section-content'>{data?.location === 1 && 'Shop page'}{data?.location === 2 && 'Challenge page'}{data?.location === 3 && 'Search page'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>From When</div>
                        <p className='challenge-section-content'>{getParsedDate(data?.from_date) ?? 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>To When</div>
                        <p className='challenge-section-content'>{getParsedDate(data?.to_date) ?? 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Linkout Url</div>
                        <p className='challenge-section-content'>{data?.url ?? 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Challenge Linkout</div>
                        <p className='challenge-section-content'>{data?.navigation_params ? JSON.parse(data?.navigation_params).hashtag : 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Order</div>
                        <p className='challenge-section-content'>{data?.order ?? 'None'}</p>
                    </div>
                    <div className='challenge-footer'>
                        <Link to={`/banner-edit/${data?.id}`}><Button variant='brand-red mx-2'>Edit Banner</Button></Link>
                        <Button variant='brand-gray mx-2' onClick={history.goBack}>Back</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(Banner);
