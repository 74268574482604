import { uploadImageDir } from 'api/upload';
import ConfirmPopup from 'components/ConfirmPopup';
import Media from 'components/Media';
import { eventsCategories, imageEndpoints } from 'config/constants';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useRef, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { AiFillEye } from 'react-icons/ai';
import { BiCalendarEvent } from 'react-icons/bi';
import { BsDashLg, BsFillImageFill } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import Resizer from 'react-image-file-resizer';
import { useHistory } from 'react-router-dom';

function BannerForm(props) {
    const bannerImageRef = useRef();
    const [isUploading, setUploading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();

    const uploadChallengeImage = useCallback(async (image) => {
        try {
            setUploading(true);

            const uploadResponse = await uploadImageDir('events', image);

            props.setValue('banner_image', uploadResponse);
        } finally {
            setUploading(false);
        }
    }, [props]);

    const bannerImageHandler = useCallback(async (event) => {
        if (event.target.files[0]) {
            try {
                Resizer.imageFileResizer(event.target.files[0], 2048, 2048, 'PNG', 85, 360, (uri) => uploadChallengeImage(uri));
            } catch (err) {
            }
        }
    }, [uploadChallengeImage]);

    const renderLanguage = useCallback(() => {
        if (props.fields?.language === 'en') {
            return <div className='challenge-type selected-type-text'>English</div>;
        } else if (props.fields?.language === 'id') {
            return <div className='challenge-type selected-type-text'>Indo</div>;
        } else {
            return <div className='challenge-type selected-type-text'>All</div>;
        }
    }, [props.fields?.language]);

    const endDateHandler = (date) => {
        props.setValue('end_date', date);
    };

    const startDateHandler = (date) => {
        props.setValue('start_date', date);

        if (date > props.fields?.end_date) {
            const buffer = date.setHours(date.getHours() + 1);

            endDateHandler(buffer);
        }
    };

    const addCategory = (category) => {
        props.setCategories([category]);
    };

    const removeCategory = useCallback((category) => {
        props.setCategories((prev) => prev.filter((item) => item !== category));
    }, [props]);

    const renderCategories = useCallback(() => {
        if (props.categories.length === 0) {
            return <span className='challenge-type'>Choose Event Category</span>;
        }

        return props.categories.map((category, index) => (
            <div className='selected-category' key={index}>
                <span>{category}</span>
                <MdClose color='#fff' size={18} onClick={() => removeCategory(category)} />
            </div>
        ));
    }, [props.categories, removeCategory]);

    return (
        <div className='form-wrapper'>
            <div className='challenge-header'>{props.fields?.id ? 'Edit Event' : 'Create Event'}</div>
            <Form>
                <Form.Group className='mb-3' controlId='challengeName'>
                    <Form.Label className='form-label'>Event Name</Form.Label>
                    <input value={props.fields?.name} onChange={(e) => props.setValue('name', e.target.value)}
                        className='form-input' type='text' placeholder='Enter Event Name' />
                    {props.errors?.name?.message && <Form.Text className='form-error-message'>{props.errors?.name?.message}</Form.Text>}
                </Form.Group>
                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Banner <span className='form-label-info'>(Less than 1 Mb, with ratio 16:9 or 1920x1080 pixels)</span></Form.Label>
                    {props.fields?.banner_image
                        ? <Media onClose={() => props.setValue('banner_image', '')} src={imageEndpoints.eventsUrl + props.fields?.banner_image} alt='Challenge'
                            className='form-banner-image' />
                        : <div onClick={() => bannerImageRef.current.click()} className={`image-placeholder-wrapper hoverable ${isUploading ? 'disabled' : ''}`}>
                            <BsFillImageFill color='#bdbdbd' size={40} />
                            <span>Upload imgae.</span>
                        </div>}
                    <input type='file' multiple={false} ref={bannerImageRef}
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={bannerImageHandler} />
                    {props.errors?.banner_image?.message && <Form.Text className='form-error-message'>{props.errors?.banner_image?.message}</Form.Text>}
                </Form.Group>
                <Form.Group className='mb-3' controlId='challengeShortDescription'>
                    <Form.Label className='form-label'>Event Description <span className='form-label-info'>(Optional)</span></Form.Label>
                    <textarea value={props.fields?.description} onChange={(e) => props.setValue('description', e.target.value)}
                        rows={5} type='text'
                        className='form-textarea'
                        placeholder='Write Description' />
                    {props.errors?.description?.message && <Form.Text className='form-error-message'>{props.errors?.description?.message}</Form.Text>}
                </Form.Group>
                <Form.Group className='mb-3' controlId='challengeType'>
                    <Form.Label className='form-label'>Language</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>{renderLanguage()}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => props.setValue('language', null)}><span className={!props.fields?.language ? 'color-brand-imp' : ''}>All</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setValue('language', 'en')}><span className={props.fields?.language === 1 ? 'color-brand-imp' : ''}>English</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setValue('language', 'id')}><span className={props.fields?.language === 2 ? 'color-brand-imp' : ''}>Indo</span></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeCategory'>
                    <Form.Label className='form-label'>Category</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>{renderCategories()}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {eventsCategories.map((category, index) => <Dropdown.Item key={index} onClick={() => addCategory(category.name)}><span className={props.categories.includes(category.name) ? 'color-brand-imp' : ''}>{category.name}</span></Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                    {props.categoryError && <Form.Text className='form-error-message'>{props.categoryError}</Form.Text>}
                </Form.Group>
                <Form.Group className='mb-3' controlId='challengeType'>
                    <Form.Label className='form-label'>Event Link <span className='form-label-info'>(Hyperlink for the event website)</span></Form.Label>
                    <div className='input-unit-wrapper'>
                        <div>https://</div>
                        <input value={props.fields?.website_link} onChange={(e) => props.setValue('website_link', e.target.value)}
                            className='form-input' type='text' placeholder='Enter event website link' />
                    </div>
                    {props.errors?.website_link?.message && <Form.Text className='form-error-message'>{props.errors?.website_link?.message}</Form.Text>}
                </Form.Group>
                <Form.Group className='mb-3' controlId='challengeType'>
                    <Form.Label className='form-label'>Event Embed Link <span className='form-label-info'>(Embed link for page or Lu.ma - Optional for WebApp only))</span></Form.Label>
                    <div className='input-unit-wrapper'>
                        <div>https://</div>
                        <input value={props.fields?.embed_link} onChange={(e) => props.setValue('embed_link', e.target.value)}
                            className='form-input' type='text' placeholder='Enter embed url for promo page' />
                    </div>
                    {props.errors?.embed_link?.message && <Form.Text className='form-error-message'>{props.errors?.embed_link?.message}</Form.Text>}
                </Form.Group>
                <Form.Group className='mb-3' controlId='challengeName'>
                    <Form.Label className='form-label'>Event Button Text <span className='form-label-info'>(Appears inside of mobile event's page - Optiona)</span></Form.Label>
                    <input value={props.fields?.button_text} onChange={(e) => props.setValue('button_text', e.target.value)}
                        className='form-input' type='text' placeholder='Enter the text for event button' />
                    {props.errors?.button_text?.message && <Form.Text className='form-error-message'>{props.errors?.button_text?.message}</Form.Text>}
                </Form.Group>
                <Form.Group className='mb-3' controlId='challengeType'>
                    <Form.Label className='form-label'>Event Button Link <span className='form-label-info'>(Appears when user click the event button in mobile - Optional)</span></Form.Label>
                    <div className='input-unit-wrapper'>
                        <div>https://</div>
                        <input value={props.fields?.group_link} onChange={(e) => props.setValue('group_link', e.target.value)}
                            className='form-input' type='text' placeholder="Enter event button's link" />
                    </div>
                    {props.errors?.group_link?.message && <Form.Text className='form-error-message'>{props.errors?.group_link?.message}</Form.Text>}
                </Form.Group>

                <div className='dates-input-wrapper'>
                    <Form.Group className='mb-3' controlId='challengeHashtag'>
                        <Form.Label className='form-label'>Start Date <span className='form-label-info'>(SGT Timezone)</span></Form.Label>
                        <div className='date-wrapper'>
                            <DatePicker className='date-wrapper-input' selected={props.fields?.start_date}
                                showTimeSelect={true}
                                dateFormat='MMM d, yyyy h:mm aa'
                                onSelect={startDateHandler}
                                onChange={startDateHandler} />
                            <BiCalendarEvent size={18} color='#838383' />
                        </div>
                        {props.errors?.start_date?.message && <Form.Text className='form-error-message'>{props.errors?.start_date?.message}</Form.Text>}
                    </Form.Group>
                    <BsDashLg size={18} color='#838383' />
                    <Form.Group className='mb-3' controlId='challengeHashtag'>
                        <Form.Label className='form-label'>End Date <span className='form-label-info'>(SGT Timezone)</span></Form.Label>
                        <div className='date-wrapper'>
                            <DatePicker className='date-wrapper-input' selected={props.fields?.end_date}
                                showTimeSelect={true}
                                dateFormat='MMM d, yyyy h:mm aa'
                                minDate={props.fields?.start_date}
                                onSelect={endDateHandler}
                                onChange={endDateHandler} />
                            <BiCalendarEvent size={18} color='#838383' />
                        </div>
                        {props.errors?.end_date?.message && <Form.Text className='form-error-message'>{props.errors?.end_date?.message}</Form.Text>}
                    </Form.Group>
                </div>
            </Form>
            <div className='form-footer'>
                <Button onClick={props.showPreview} variant='brand-red-hollow' ><AiFillEye color='#FF7268' />  Preview</Button>
                <div>
                    <Button variant='brand-gray mx-2' onClick={() => setShowModal(true)}>Cancel</Button>
                    <Button variant='brand-red mx-2' onClick={props.saveHandler}>Save Event</Button>
                </div>
            </div>
            <ConfirmPopup
                isVisible={showModal}
                onClose={() => setShowModal(false)}
                confirmTitle='Leave'
                onConfirm={history.goBack}
                title='Unsaved Changes'
                description='Are you sure want to discard this changes? Changes you made may not be saved.'
                type={1} />
        </div>
    );
}

BannerForm.propTypes = {
    fields: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    saveHandler: PropTypes.func.isRequired,
    showPreview: PropTypes.func.isRequired,
    categoryError: PropTypes.string,
    categories: PropTypes.array.isRequired,
    setCategories: PropTypes.func.isRequired
};

export default memo(BannerForm);
