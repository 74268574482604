import './index.scss';

import { createRecipe, getById, updateRecipe } from 'api/recipe';
import RecipePreview from 'components/RecipePreview';
import React, { memo, useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { configureRecipeIngredients, getRecipeImages, parseRecipeIngredients, validateIngredients, validateInstructions } from 'utils/recipeHelpers';

import DataForm1 from './components/DataForm1';
import DataForm2 from './components/DataForm2';
import Dataform3 from './components/Dataform3';

function ChallengeFormContainer() {
    const [currentStep, setStep] = useState(1);
    const [showPreview, setShowPreview] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const { id } = useParams();
    const [parsedData, setParsedData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [recipeErrors, setRecipeErrors] = useState(null);
    const history = useHistory();
    const { register, watch, setValue, clearErrors, setError, trigger, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            recipename: '',
            images: [],
            video: '',
            video_thumbnail: '',
            description: '',
            servings: '',
            price: null,
            level_of_difficulty: 1,
            preptime: '',
            cookingtime: '',
            challenge: { hashtag: '' },
            ingredients: [],
            instructions: [''],
            cuisines: [],
            common_ingredients: [],
            equipment: [],
            meals: [],
            occasions: [],
            feelings: [],
            language: null
        }
    });

    const fields = watch();

    useEffect(() => {
        register('recipename', { required: 'This field is Required' });
        register('images');
        register('video_thumbnail');
        register('description', { required: 'This field is Required' });
        register('servings', { required: 'This field is Required' });
        register('level_of_difficulty', { required: 'This field is Required' });
        register('preptime', { required: 'This field is Required' });
        register('cookingtime', { required: 'This field is Required' });
        register('challenge');
        register('instructions', validateInstructions);
        register('ingredients', validateIngredients);
        register('price');
        register('language');
        register('cuisines');
        register('meals');
        register('occasions');
        register('common_ingredients');
        register('equipment');
        register('feelings');
    }, [register]);

    const fetchData = useCallback(async () => {
        try {
            const data = await getById(id);

            setRecipeErrors(data?.review);

            if (data) {
                const images = getRecipeImages(data?.is_uploaded, data?.image_name, data?.images);
                const ingredients = data.ingredients.map(item => {
                    return {
                        id: item.id,
                        name: item.name,
                        section: item.pivot?.section,
                        quantity: item.pivot?.quantity,
                        serving: item.pivot?.serving,
                        comment: item.pivot?.comment ?? ''
                    };
                });

                setValue('id', id);
                setValue('recipename', data?.recipename);
                setValue('description', data?.description);
                setValue('challenge', data?.challenge);
                setValue('instructions', data?.instructions ?? []);
                setValue('ingredients', configureRecipeIngredients(ingredients));
                setValue('servings', data?.servings ? parseInt(data?.servings) : '');
                setValue('level_of_difficulty', data?.level_of_difficulty ? parseInt(data?.level_of_difficulty) : 1);
                setValue('preptime', data?.preptime);
                setValue('cookingtime', data?.cookingtime);
                setValue('price', data?.price ? parseInt(data?.price) : null);
                setValue('images', images);
                setValue('video', data?.video);
                setValue('video_thumbnail', data?.video_thumbnail);
                setValue('cuisines', data?.cuisines ?? []);
                setValue('meals', data?.meals ?? []);
                setValue('language', data?.language);
                setValue('occasions', data?.occasions ?? []);
                setValue('feelings', data?.feelings ?? []);
                setValue('common_ingredients', data?.common_ingredients ?? []);
                setValue('equipment', data?.equipment ?? []);
            }
        } catch {
            toast.error('Unable to fetch Recipe', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }, [id, setValue]);

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [fetchData, id]);

    const parsedRecipeData = useCallback(() => {
        const parsedData = {
            ingredients: parseRecipeIngredients(fields?.ingredients),
            recipe: {
                challenge_id: fields?.challenge?.id,
                challenge: fields?.challenge,
                recipename: fields?.recipename,
                description: fields?.description,
                images: fields?.images,
                video: fields?.video,
                video_thumbnail: fields?.video_thumbnail,
                servings: `${fields?.servings}`,
                price: fields?.price,
                level_of_difficulty: fields?.level_of_difficulty,
                preptime: fields?.preptime,
                cookingtime: fields?.cookingtime,
                instructions: fields?.instructions,
                cuisines: fields?.cuisines,
                meals: fields?.meals,
                language: fields?.language,
                occasions: fields?.occasions,
                feelings: fields?.feelings,
                common_ingredients: fields?.common_ingredients,
                equipment: fields?.equipment
            }
        };

        return parsedData;
    }, [fields]);

    const previewHandler = useCallback(async () => {
        setParsedData(parsedRecipeData());
        setShowPreview(true);
    }, [parsedRecipeData]);

    const validateTags = useCallback(() => {
        if ((fields.cuisines.length + fields.occasions.length + fields.meals.length + fields.feelings.length + fields.equipment.length + fields.common_ingredients.length) > 0) {
            return true;
        }

        setError('cuisines', { type: 'required', message: 'Please select atleast 1 category from below.' });
        toast.error('Please tag atleast 1 category to recipe.', {
            position: toast.POSITION.BOTTOM_LEFT
        });

        return false;
    }, [fields.common_ingredients.length, fields.cuisines.length, fields.equipment.length, fields.feelings.length, fields.meals.length, fields.occasions.length, setError]);

    const saveHandler = useCallback(async () => {
        clearErrors();

        if (currentStep === 1) {
            const isValid = await trigger(['recipename', 'description', 'servings', 'difficulty']);
            const validMedia = fields?.images?.length > 0 || fields?.video_thumbnail;

            if (isValid && validMedia) {
                setStep(2);
            }
        } else if (currentStep === 2) {
            const isValid = await trigger(['preptime', 'cookingtime', 'challenge', 'ingredients']);

            if (isValid) {
                setStep(3);
            }
        } else if (currentStep === 3) {
            const isValid = await trigger(['instructions']);
            const tagsValid = validateTags();

            if (isValid && tagsValid) {
                previewHandler();
                setIsValidated(true);
            }
        }
    }, [clearErrors, currentStep, fields?.images?.length, fields?.video_thumbnail, previewHandler, trigger, validateTags]);

    const editSaveHandler = useCallback(async () => {
        try {
            setIsSubmitting(true);

            const submitData = {
                ingredients: parseRecipeIngredients(fields?.ingredients),
                recipe: {
                    challenge_id: fields?.challenge?.id,
                    recipename: fields?.recipename,
                    description: fields?.description,
                    images: fields?.images,
                    servings: `${fields?.servings}`,
                    video: fields?.video,
                    video_thumbnail: fields?.video_thumbnail,
                    price: fields?.price,
                    level_of_difficulty: fields?.level_of_difficulty,
                    preptime: parseInt(fields?.preptime),
                    cookingtime: parseInt(fields?.cookingtime),
                    language: fields?.language,
                    instructions: fields?.instructions,
                    cuisines: fields?.cuisines.map(item => item.id),
                    meals: fields?.meals.map(item => item.id),
                    occasions: fields?.occasions.map(item => item.id),
                    feelings: fields?.feelings.map(item => item.id),
                    common_ingredients: fields?.common_ingredients.map(item => item.id),
                    equipment: fields?.equipment.map(item => item.id)
                }
            };

            const data = await updateRecipe(id, submitData);

            if (data.id) {
                toast.success('Thank you. Your Changes has been saved.', {
                    position: toast.POSITION.BOTTOM_LEFT
                });

                history.push(`/recipes/${data.id}`);
            }
        } catch {
            toast.error('Unable to save the changes ', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsSubmitting(false);
        }
    }, [fields?.challenge?.id, fields?.common_ingredients, fields?.cookingtime, fields?.cuisines, fields?.description, fields?.equipment, fields?.feelings, fields?.images, fields?.ingredients, fields?.instructions, fields?.language, fields?.level_of_difficulty, fields?.meals, fields?.occasions, fields?.preptime, fields?.price, fields?.recipename, fields?.servings, fields?.video, fields?.video_thumbnail, history, id]);

    const submitChallengeHandler = useCallback(async () => {
        if (id) {
            editSaveHandler();
        } else {
            try {
                setIsSubmitting(true);

                const submitData = {
                    ingredients: parseRecipeIngredients(fields?.ingredients),
                    recipe: {
                        challenge_id: fields?.challenge?.id,
                        recipename: fields?.recipename,
                        description: fields?.description,
                        images: fields?.images,
                        video: fields?.video,
                        video_thumbnail: fields?.video_thumbnail,
                        servings: `${fields?.servings}`,
                        price: fields?.price,
                        level_of_difficulty: fields?.level_of_difficulty,
                        preptime: parseInt(fields?.preptime),
                        cookingtime: parseInt(fields?.cookingtime),
                        language: fields?.language,
                        instructions: fields?.instructions,
                        cuisines: fields?.cuisines.map(item => item.id),
                        meals: fields?.meals.map(item => item.id),
                        occasions: fields?.occasions.map(item => item.id),
                        feelings: fields?.feelings.map(item => item.id),
                        common_ingredients: fields?.common_ingredients.map(item => item.id),
                        equipment: fields?.equipment.map(item => item.id)
                    }
                };

                const data = await createRecipe(submitData);

                if (data.id) {
                    toast.success('Thank you. Your Recipe has been submitted.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    });

                    history.push(`/recipes/${data.id}`);
                }
            } catch {
                toast.error('Unable to publish the Recipe ', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            } finally {
                setIsSubmitting(false);
            }
        }
    }, [editSaveHandler, fields?.challenge?.id, fields?.common_ingredients, fields?.cookingtime, fields?.cuisines, fields?.description, fields?.equipment, fields?.feelings, fields?.images, fields?.ingredients, fields?.instructions, fields?.language, fields?.level_of_difficulty, fields?.meals, fields?.occasions, fields?.preptime, fields?.price, fields?.recipename, fields?.servings, fields?.video, fields?.video_thumbnail, history, id]);

    const renderSteps = () => {
        switch (currentStep) {
            case 1:
                return <DataForm1
                    fields={fields}
                    setValue={setValue}
                    saveHandler={saveHandler}
                    recipeErrors={recipeErrors}
                    showPreview={previewHandler}
                    errors={errors} />;
            case 2:
                return <DataForm2
                    fields={fields}
                    setValue={setValue}
                    saveHandler={saveHandler}
                    showPreview={previewHandler}
                    recipeErrors={recipeErrors}
                    onBack={() => setStep(1)}
                    errors={errors} />;
            case 3:
                return <Dataform3
                    fields={fields}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    saveHandler={saveHandler}
                    instructionsError={recipeErrors?.instructionsError}
                    showPreview={previewHandler}
                    onBack={() => setStep(2)}
                    errors={errors} />;

            default:
                return null;
        }
    };

    return (
        <>
            <Helmet>
                <title>Create Recipe - YoRipe</title>
                <meta name='title' content='Create Recipe - YoRipe' />
                <meta name='description' content={'Fill all the required fields and create a new YoRipe recipe!'} />
            </Helmet>
            <div className={`page-wrapper ${isSubmitting ? 'disabled' : ''}`}>
                {showPreview
                    ? <RecipePreview
                        data={parsedData}
                        isValidated={isValidated}
                        onBack={() => setShowPreview(false)}
                        onSubmit={submitChallengeHandler} />
                    : renderSteps()}
            </div>
        </>
    );
}

export default memo(ChallengeFormContainer);
