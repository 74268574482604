import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Button } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { getParsedDate } from 'utils/helpers';

function Collection({ data, isValidated, onBack, onSubmit }) {
    return (
        <>
            <Helmet>
                <title>{`${data?.name} - Admin YoRipe`}</title>
                <meta name='title' content={`${data?.name} - Admin YoRipe`} />
                <meta name='description' content={'Occasions Form page to manage all existing Occasions and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>Preview Top Collection</div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>From When</div>
                        <div className='challenge-section-content'>{data?.from_date ? getParsedDate(data.from_date) : 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>To When</div>
                        <div className='challenge-section-content'>{data?.to_date ? getParsedDate(data.to_date) : 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Collection Name</div>
                        <div className='challenge-section-content'>{data?.name}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Orders</div>
                        <div className='challenge-section-content'>{data?.order}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Recipe IDs</div>
                        <div className='challenge-section-content'>{data?.recipes}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Image</div>
                        <div className='image-grid-container'><img src={data?.image} alt='recipe media' /></div>
                    </div>
                    <div className='challenge-footer'>
                        <Button variant='brand-red mx-2' onClick={onSubmit} disabled={!isValidated}>Save Collection</Button>
                        <Button variant='brand-gray mx-2' onClick={onBack}>Edit Form</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

Collection.propTypes = {
    data: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isValidated: PropTypes.bool.isRequired
};

export default memo(Collection);
