import { banUser, getById, unbanUser } from 'api/users';
import ConfirmPopup from 'components/ConfirmPopup';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function User() {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            const response = await getById(id);

            if (response) {
                setData(response);
            }
        } catch {
            toast.error('Unable to fetch User', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const banHandler = useCallback(async() => {
        try {
            await banUser(data?.id);

            toast.success('User successfully banned!', {
                position: toast.POSITION.BOTTOM_LEFT
            });

            setData(prev => {
                return { ...prev, ban_status: 2 };
            });
            setShowModal(false);
        } catch {
            toast.error('Unable to Ban User', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsLoading(false);
        }
    }, [data?.id]);

    const removeBanHandler = useCallback(async() => {
        try {
            await unbanUser(data?.id);

            toast.success('User successfully unbanned!', {
                position: toast.POSITION.BOTTOM_LEFT
            });

            setData(prev => {
                return { ...prev, ban_status: 0 };
            });
            setShowModal(false);
        } catch {
            toast.error('Unable to Unban User', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsLoading(false);
        }
    }, [data?.id]);

    return (
        <>
            <Helmet>
                <title>User - Admin YoRipe</title>
                <meta name='title' content='User - Admin YoRipe' />
                <meta name='description' content={'User page to manage the existing user!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>View User</div>
                    {isLoading && <div className='challenge-header'><Spinner animation='border' variant='red-1' /></div>}
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>ID</div>
                        <p className='challenge-section-content'>{data?.id}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Name</div>
                        <p className='challenge-section-content'><strong>{data?.name}</strong></p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Username</div>
                        <div className='challenge-section-content'>{data?.user_name}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Avatar</div>
                        <div className='image-grid-container'>{data?.avatar ? <img src={data.avatar} alt='User Profile Avatar' /> : 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Email</div>
                        <p className='challenge-section-content'>{data?.email ?? 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Phone Number</div>
                        <p className='challenge-section-content'>{data?.mobile ?? 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Numb Blocked</div>
                        <p className='challenge-section-content'>{data?.blocked_count}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Numb Reported</div>
                        <p className='challenge-section-content'>{data?.report_count}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Status</div>
                        <p className='challenge-section-content'>{!data?.ban_status && 'Active'}{data?.ban_status === 1 && <span style={{ color: '#FFAA06' }}>Suspicious</span>}{data?.ban_status === 2 && <span style={{ color: '#BF050F' }}>Banned</span>}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Country</div>
                        <p className='challenge-section-content'>{data?.language === 'id' ? 'Indonesia' : 'Singapore'}</p>
                    </div>
                    <div className='challenge-footer'>
                        <Button onClick={() => setShowModal(true)} variant='brand-red mx-2'>{data?.ban_status === 2 ? 'Unban User' : 'Ban User'}</Button>
                        <Button variant='brand-gray mx-2' onClick={history.goBack}>Back</Button>
                    </div>
                </div>
            </div>
            <ConfirmPopup
                isVisible={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={data?.ban_status === 2 ? removeBanHandler : banHandler}
                confirmTitle='Yes, I am'
                title={data?.ban_status === 2 ? 'Unban User' : 'Ban User'}
                description={`Are you sure you want to ${data?.ban_status === 2 ? 'unban' : 'ban'} ${data?.name}?`}
                type={3} />
        </>
    );
}

export default memo(User);
