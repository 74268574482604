import { getAllEquipments, searchIngredients } from 'api/misc';
import { searchSellers } from 'api/seller';
import { uploadImageDir } from 'api/upload';
import ConfirmPopup from 'components/ConfirmPopup';
import Media from 'components/Media';
import { imageEndpoints } from 'config/constants';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { AiFillEye } from 'react-icons/ai';
import { BsFillImageFill } from 'react-icons/bs';
import Resizer from 'react-image-file-resizer';
import { useHistory } from 'react-router-dom';

function DataForm(props) {
    const logoImageRef = useRef();
    const [sellerData, setSellerData] = useState({ page: 1, isLoading: false, data: [] });
    const [sellerQuery, setSellerQuery] = useState('');
    const [showSellerList, setShowSellerList] = useState(false);
    const [showEquipment, setShowEquipment] = useState(false);
    const [equipmentData, setEquipmentData] = useState([]);
    const [equipmentQuery, setEquipmentQuery] = useState('');
    const [ingredientData, setIngredientData] = useState({ isLoading: false, data: [] });
    const [ingredientQuery, setIngredientQuery] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showIngredients, setSHowIngredients] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (props.fields?.seller?.name) {
            setSellerQuery(props.fields.seller?.name);
        }
    }, [props.fields?.seller?.name]);

    useEffect(() => {
        if (props.fields?.equipment?.name) {
            setEquipmentQuery(props.fields.equipment?.name);
        }
    }, [props.fields?.equipment?.name]);

    useEffect(() => {
        if (props.fields?.ingredient?.name) {
            setIngredientQuery(props.fields.ingredient?.name);
        }
    }, [props.fields?.ingredient?.name]);

    const fetchSellers = useCallback(async (query) => {
        try {
            setSellerData({ ...sellerData, isLoading: true });

            const { data, totalPages } = await searchSellers(query, 1, 15);

            setSellerData({ currentPage: data.current_page, lastPage: totalPages, isLoading: false, data: [...data] });
        } catch {
            setSellerData({ ...sellerData, isLoading: false });
        }
    }, [sellerData]);

    const fetchEquipments = useCallback(async () => {
        try {
            const data = await getAllEquipments();

            setEquipmentData(data);
        } catch {
        }
    }, []);

    useEffect(() => {
        fetchEquipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchSellers(sellerQuery);
        }, 500);

        return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sellerQuery]);

    const fetchIngredients = useCallback(async (query) => {
        try {
            setIngredientData({ ...ingredientData, isLoading: true });

            const data = await searchIngredients(query, 1, 15);

            setIngredientData({ isLoading: false, data: [...data] });
        } catch {
            setIngredientData({ ...ingredientData, isLoading: false });
        }
    }, [ingredientData]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchIngredients(ingredientQuery);
        }, 500);

        return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ingredientQuery]);

    const uploadProductImage = useCallback(async (image) => {
        try {
            setUploading(true);

            const uploadResponse = await uploadImageDir('brand_portal/product', image);

            props.setValue('images', [...props.fields?.images, uploadResponse]);
        } finally {
            setUploading(false);
        }
    }, [props]);

    const productImageHandler = useCallback(async (event) => {
        if (event.target.files[0]) {
            try {
                Resizer.imageFileResizer(event.target.files[0], 2048, 2048, 'PNG', 85, 360, (uri) => uploadProductImage(uri));
            } catch (err) {
            }
        }
    }, [uploadProductImage]);

    const sellerSelectHandler = useCallback((item) => {
        setShowSellerList(false);
        props.setValue('seller', item);
    }, [props]);

    const equipmentSelectHandler = useCallback((item) => {
        setShowEquipment(false);
        props.setValue('equipment', item);
    }, [props]);

    const ingredientSelectHandler = useCallback((item) => {
        setSHowIngredients(false);
        props.setValue('ingredient', item);
    }, [props]);

    const renderSeller = useCallback(() => {
        if (sellerData.data.length === 0) {
            return <span className='challenge-type'>Choose Seller</span>;
        }

        return sellerData.data.map((item) => {
            return (
                <Dropdown.Item key={item.id} onClick={() => sellerSelectHandler(item)}>{item.name}</Dropdown.Item>
            );
        });
    }, [sellerData.data, sellerSelectHandler]);

    const renderEquipment = useCallback(() => {
        if (equipmentData.length === 0) {
            return <span className='challenge-type'>Choose Equipment</span>;
        }

        return equipmentData.map((item) => {
            return (
                <Dropdown.Item key={item.id} onClick={() => equipmentSelectHandler(item)}>{item.name}</Dropdown.Item>
            );
        });
    }, [equipmentSelectHandler, equipmentData]);

    const renderIngredient = useCallback(() => {
        if (ingredientData.data.length === 0) {
            return <span className='challenge-type'>Choose Ingredient</span>;
        }

        return ingredientData.data.map((item, index) => {
            return (
                <Dropdown.Item key={index} onClick={() => ingredientSelectHandler(item)}>{item.name}</Dropdown.Item>
            );
        });
    }, [ingredientData.data, ingredientSelectHandler]);

    const dynamicTitleChange = (text, index) => {
        const arr = props.fields?.dynamic_sections;

        arr[index].title = text;

        props.setValue('dynamic_sections', arr);
    };

    const dynamicDescriptionChange = (text, index) => {
        const arr = props.fields?.dynamic_sections;

        arr[index].body = text;

        props.setValue('dynamic_sections', arr);
    };

    const addDynamicHandler = () => {
        if (props.fields?.dynamic_sections.length < 5) {
            const arr = props.fields?.dynamic_sections;

            arr.push({
                title: '',
                body: ''
            });

            props.setValue('dynamic_sections', arr);
        }
    };

    const sellerQueryChangeHandler = useCallback((e) => {
        setSellerQuery(e.target.value);

        if (props.fields?.seller?.id) {
            props.setValue('seller', {});
        }
    }, [props]);

    const equipmentQueryChangeHandler = useCallback((e) => {
        setEquipmentQuery(e.target.value);

        if (props.fields?.equipment?.id) {
            props.setValue('equipment', {});
        }
    }, [props]);

    const ingredientQueryChangeHandler = useCallback((e) => {
        setIngredientQuery(e.target.value);

        if (props.fields?.ingredient?.id) {
            props.setValue('ingredient', {});
        }
    }, [props]);

    const removeIndexHandler = (index) => {
        const arr = props.fields?.images;

        arr.splice(index, 1);

        props.setValue('images', arr);
    };

    const renderCurrency = useCallback(() => {
        if (props.fields?.currency === '$') {
            return <div className='challenge-type selected-type-text'>SGD</div>;
        } else if (props.fields?.currency === 'Rp') {
            return <div className='challenge-type selected-type-text'>IDR</div>;
        }
    }, [props.fields?.currency]);

    return (
        <div className='form-wrapper'>
            <Form>
                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Seller Name</Form.Label>
                    <Dropdown onToggle={setShowSellerList}>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>
                            <input value={sellerQuery} onChange={sellerQueryChangeHandler}
                                className='organizer-input'
                                type='text'
                                placeholder='Choose Seller Name' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu show={showSellerList}>
                            {renderSeller()}
                        </Dropdown.Menu>
                    </Dropdown>
                    {props.errors?.seller_id?.message && <Form.Text className='form-error-message'>{props.errors?.seller_id?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Equipment Name <span className='form-label-count'>(Optional)</span></Form.Label>
                    <Dropdown onToggle={setShowEquipment}>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>
                            <input value={equipmentQuery} onChange={equipmentQueryChangeHandler}
                                className='organizer-input'
                                type='text'
                                placeholder='Choose Equipment Name' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu show={showEquipment}>
                            {renderEquipment()}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Ingredients Name <span className='form-label-count'>(Optional)</span></Form.Label>
                    <Dropdown onToggle={setSHowIngredients}>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>
                            <input value={ingredientQuery} onChange={ingredientQueryChangeHandler}
                                className='organizer-input'
                                type='text'
                                placeholder='Choose Ingredient Name' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu show={showIngredients}>
                            {renderIngredient()}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeName'>
                    <Form.Label className='form-label'>Product Name</Form.Label>
                    <input value={props.fields?.name} onChange={(e) => props.setValue('name', e.target.value)}
                        className='form-input' type='text' placeholder='Enter Product Name' />
                    {props.errors?.name?.message && <Form.Text className='form-error-message'>{props.errors?.name?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeType'>
                    <Form.Label className='form-label'>Currency</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>{renderCurrency()}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => props.setValue('currency', '$')}><span className={props.fields?.currency === '$' ? 'color-brand-imp' : ''}>SGD</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setValue('currency', 'Rp')}><span className={props.fields?.currency === 'Rp' ? 'color-brand-imp' : ''}>IDR</span></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>

                <Form.Group className='mb-3' controlId='recipeServings'>
                    <Form.Label className='form-label'>Price</Form.Label>
                    <div className='input-unit-wrapper'>
                        <div>{props.fields?.currency === '$' ? 'SGD' : 'IDR'}</div>
                        <input value={props.fields?.price} onChange={(e) => props.setValue('price', e.target.value)}
                            type='number' placeholder='Enter Price' />
                    </div>
                    {props.errors?.price?.message && <Form.Text className='form-error-message'>{props.errors?.price?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeShortDescription'>
                    <Form.Label className='form-label'>Description</Form.Label>
                    <textarea value={props.fields?.description} onChange={(e) => props.setValue('description', e.target.value)}
                        rows={5} type='text'
                        className='form-textarea'
                        placeholder='Write Description' />
                    {props.errors?.description?.message && <Form.Text className='form-error-message'>{props.errors?.description?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeName'>
                    <Form.Label className='form-label'>Product Link</Form.Label>
                    <input value={props.fields?.link} onChange={(e) => props.setValue('link', e.target.value)}
                        className='form-input' type='text' placeholder='Enter Product Link' />
                    {props.errors?.link?.message && <Form.Text className='form-error-message'>{props.errors?.link?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeName'>
                    <div className='form-label-wrapper'>
                        <Form.Label className='form-label-big'>Product Dynamic</Form.Label>
                        <div onClick={addDynamicHandler} className='form-label-red hoverable'>+ Add Section</div>
                    </div>
                </Form.Group>

                {props.fields?.dynamic_sections?.map((item, index) => {
                    return (
                        <>
                            <Form.Group key={index} className='mb-3' controlId='challengeName'>
                                <Form.Label className='form-label'>Title {index + 1}</Form.Label>
                                <input value={item.title} onChange={(e) => dynamicTitleChange(e.target.value, index)}
                                    className='form-input' type='text' placeholder='Enter Title' />
                            </Form.Group>

                            <Form.Group className='mb-3' controlId='challengeShortDescription'>
                                <Form.Label className='form-label'>Description {index + 1}</Form.Label>
                                <textarea value={item.body} onChange={(e) => dynamicDescriptionChange(e.target.value, index)}
                                    rows={5} type='text'
                                    className='form-textarea'
                                    placeholder='Write Description' />
                            </Form.Group>
                        </>
                    );
                })}

                {props.errors?.dynamic_sections?.message && <Form.Text className='form-error-message'>{props.errors?.dynamic_sections?.message}</Form.Text>}

                <Form.Group className='mb-3' controlId='challengeName'>
                    <Form.Label className='form-label'>Linked Recipe IDs <span className='form-label-count'>(Optional)</span></Form.Label>
                    <input value={props.fields?.recipes} onChange={(e) => props.setValue('recipes', e.target.value)}
                        className='form-input' type='text' placeholder='Enter Link of Recipe' />
                    {props.errors?.recipes?.message && <Form.Text className='form-error-message'>{props.errors?.recipes?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Product Image<span className='form-label-info'>(Less than 300kb, 1:1 or Square)</span></Form.Label>
                    {props.fields?.images?.length > 0 && props.fields?.images?.map((item, index) => <Media onClose={() => removeIndexHandler(index)} src={imageEndpoints.brandPortalProductUrl + item} key={index}
                        alt='organizer'
                        className='form-input-image' />)}
                    <div onClick={() => logoImageRef.current.click()} className={`image-placeholder-wrapper hoverable ${isUploading ? 'disabled' : ''}`}>
                        <BsFillImageFill color='#bdbdbd' size={40} />
                        <span>Upload imgaes.</span>
                    </div>
                    <input type='file' multiple={false} ref={logoImageRef}
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={productImageHandler} />
                    {props.errors?.images?.message && <Form.Text className='form-error-message'>{props.errors?.images?.message}</Form.Text>}
                </Form.Group>
            </Form>
            <div className='form-footer'>
                <Button variant='brand-red-hollow' onClick={props.showPreview} ><AiFillEye color='#FF7268' />  Preview</Button>
                <div>
                    <Button variant='brand-gray mx-2' onClick={() => setShowModal(true)}>Cancel</Button>
                    <Button variant='brand-red mx-2' onClick={props.saveHandler}>Save Product</Button>
                </div>
            </div>
            <ConfirmPopup
                isVisible={showModal}
                onClose={() => setShowModal(false)}
                confirmTitle='Leave'
                onConfirm={history.goBack}
                title='Unsaved Changes'
                description='Are you sure want to discard this changes? Changes you made may not be saved.'
                type={1} />
        </div>
    );
}

DataForm.propTypes = {
    fields: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    saveHandler: PropTypes.func.isRequired,
    showPreview: PropTypes.func.isRequired
};

export default memo(DataForm);
