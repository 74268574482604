import './index.scss';

import { saveAs } from 'file-saver';
import useVideoPlayer from 'hooks/useVideoPlayer';
import PropTypes from 'prop-types';
import { memo, useRef } from 'react';
import { BiFullscreen } from 'react-icons/bi';
import { FaRegPauseCircle, FaRegPlayCircle } from 'react-icons/fa';
import { IoDownloadOutline } from 'react-icons/io5';
import { VscCloudDownload, VscMute, VscUnmute } from 'react-icons/vsc';

async function downloadImage(imageSrc, name) {
    try {
        const image = await fetch(imageSrc);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);

        const link = document.createElement('a');

        link.href = imageURL;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (err) {
        saveAs(imageSrc, name);
    }
}

const VideoPlayer = ({ videoUrl, thumbnail, downloadName }) => {
    const videoElement = useRef(null);

    const {
        playerState,
        togglePlay,
        handleOnTimeUpdate,
        handleVideoProgress,
        handleVideoSpeed,
        toggleMute,
        toggleFullScreen
    } = useVideoPlayer(videoElement);

    const onDownloadVideo = () => {
        saveAs(videoUrl, `${downloadName}-video.mp4`);
    };

    const onDownloadImage = () => {
        downloadImage(thumbnail, `${downloadName}-thumbnail.jpg`);
    };

    return (
        <div className='container'>
            <div className='video-wrapper'>
                <video
                    src={videoUrl}
                    ref={videoElement}
                    poster={thumbnail}
                    onTimeUpdate={handleOnTimeUpdate}/>
                <div className='actions'>
                    <button onClick={togglePlay}>
                        {!playerState.isPlaying
                            ? (
                                <FaRegPlayCircle color='#FF7268' className='video-player-icon' />
                            )
                            : (
                                <FaRegPauseCircle color='#FF7268' className='video-player-icon pause-button' />
                            )}
                    </button>
                </div>
                {downloadName && <div onClick={onDownloadVideo} className='download-icon' ><IoDownloadOutline size={25} color='#FF7268' /></div>}
                {(downloadName && thumbnail) && <div onClick={onDownloadImage} className='download-icon-below' ><VscCloudDownload size={25} color='#FF7268' /></div>}
                <div className='controls'>
                    <button className='mute-btn' onClick={toggleMute}>
                        {!playerState.isMuted
                            ? (
                                <VscUnmute color='#fff' className='video-mute-icon' />
                            )
                            : (
                                <VscMute color='#fff' className='video-mute-icon' />
                            )}
                    </button>
                    <input
                        type='range'
                        min='1'
                        max='100'
                        value={playerState.progress}
                        onChange={(e) => handleVideoProgress(e)}/>
                    <select
                        className='velocity'
                        value={playerState.speed}
                        onChange={(e) => handleVideoSpeed(e)}>
                        <option value='0.50'>0.50x</option>
                        <option value='1'>1x</option>
                        <option value='1.25'>1.25x</option>
                        <option value='2'>2x</option>
                    </select>

                    <button className='mute-btn' onClick={toggleFullScreen}>
                        <BiFullscreen color='#fff' className='video-mute-icon' />
                    </button>
                </div>
            </div>
        </div>
    );
};

VideoPlayer.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    downloadName: PropTypes.string
};

export default memo(VideoPlayer);
