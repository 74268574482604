import ROUTE_PATHS from 'config/routePaths';
import Banner from 'containers/Banner';
import BannerCreate from 'containers/BannerCreate';
import Banners from 'containers/Banners';
import Brand from 'containers/Brand';
import BrandCreate from 'containers/BrandCreate';
import Brands from 'containers/Brands';
import Challenge from 'containers/Challenge';
import ChallengeCreate from 'containers/ChallengeCreate';
import ChallengeScreen from 'containers/Challenges';
import Collection from 'containers/Collection';
import CollectionCraete from 'containers/CollectionCreate';
import Collections from 'containers/Collections';
import Event from 'containers/Event';
import Events from 'containers/Events';
import EventsCreate from 'containers/EventsCreate';
import Product from 'containers/Product';
import ProductCreate from 'containers/ProductCreate';
import Products from 'containers/Products';
import Recipe from 'containers/Recipe';
import RecipeCreate from 'containers/RecipeCreate';
import RecipeReview from 'containers/RecipeReview';
import Recipes from 'containers/Recipes';
import Stories from 'containers/Stories';
import StoriesCreate from 'containers/StoriesCreate';
import Story from 'containers/Story';
import User from 'containers/User';
import Users from 'containers/Users';
import { Redirect, Route, Switch } from 'react-router-dom';

function Routes() {
    return (
        <Switch>
            <Route exact={true} path={ROUTE_PATHS.DEFAULT} component={() => <Redirect to={ROUTE_PATHS.RECIPES} />} />
            <Route exact={true} path={ROUTE_PATHS.RECIPES} component={Recipes} />
            <Route exact={true} path={ROUTE_PATHS.RECIPES_CREATE} component={RecipeCreate} />
            <Route exact={false} path={ROUTE_PATHS.INDIVIDUAL_RECIPE} component={Recipe} />
            <Route exact={true} path={ROUTE_PATHS.RECIPE_EDIT} component={RecipeCreate} />
            <Route exact={true} path={ROUTE_PATHS.CHALLENGE} component={ChallengeScreen} />
            <Route exact={true} path={ROUTE_PATHS.CHALLENGE_CREATE} component={ChallengeCreate} />
            <Route exact={false} path={ROUTE_PATHS.INDIVIDUAL_CHALLENGE} component={Challenge} />
            <Route exact={true} path={ROUTE_PATHS.CHALLENGE_EDIT} component={ChallengeCreate} />
            <Route exact={true} path={ROUTE_PATHS.BANNER} component={Banners} />
            <Route exact={true} path={ROUTE_PATHS.BANNER_CREATE} component={BannerCreate} />
            <Route exact={false} path={ROUTE_PATHS.INDIVIDUAL_BANNER} component={Banner} />
            <Route exact={true} path={ROUTE_PATHS.BANNER_EDIT} component={BannerCreate} />
            <Route exact={true} path={ROUTE_PATHS.EVENTS} component={Events} />
            <Route exact={true} path={ROUTE_PATHS.EVENTS_CREATE} component={EventsCreate} />
            <Route exact={false} path={ROUTE_PATHS.INDIVIDUAL_EVENT} component={Event} />
            <Route exact={true} path={ROUTE_PATHS.EVENTS_EDIT} component={EventsCreate} />
            <Route exact={true} path={ROUTE_PATHS.COOKING_STORY} component={Stories} />
            <Route exact={true} path={ROUTE_PATHS.COOKING_STORY_CREATE} component={StoriesCreate} />
            <Route exact={false} path={ROUTE_PATHS.INDIVIDUAL_COOKING_STORY} component={Story} />
            <Route exact={true} path={ROUTE_PATHS.COOKING_STORY_EDIT} component={StoriesCreate} />
            <Route exact={true} path={ROUTE_PATHS.BRAND_PORTAL} component={Brands} />
            <Route exact={true} path={ROUTE_PATHS.BRAND_PORTAL_CREATE} component={BrandCreate} />
            <Route exact={false} path={ROUTE_PATHS.INDIVIDUAL_BRAND_PORTAL} component={Brand} />
            <Route exact={true} path={ROUTE_PATHS.BRAND_PORTAL_EDIT} component={BrandCreate} />
            <Route exact={true} path={ROUTE_PATHS.TOP_COLLECTION} component={Collections} />
            <Route exact={true} path={ROUTE_PATHS.TOP_COLLECTION_CREATE} component={CollectionCraete} />
            <Route exact={false} path={ROUTE_PATHS.INDIVIDUAL_TOP_COLLECTION} component={Collection} />
            <Route exact={true} path={ROUTE_PATHS.TOP_COLLECTION_EDIT} component={CollectionCraete} />
            <Route exact={true} path={ROUTE_PATHS.PRODUCTS} component={Products} />
            <Route exact={true} path={ROUTE_PATHS.PRODUCTS_CREATE} component={ProductCreate} />
            <Route exact={false} path={ROUTE_PATHS.INDIVIDUAL_PRODUCT} component={Product} />
            <Route exact={true} path={ROUTE_PATHS.PRODUCT_EDIT} component={ProductCreate} />
            <Route exact={true} path={ROUTE_PATHS.USERS} component={Users} />
            <Route exact={false} path={ROUTE_PATHS.INDIVIDUAL_USER} component={User} />
            <Route exact={true} path={ROUTE_PATHS.RECIPE_REVIEW} component={RecipeReview} />
        </Switch>
    );
}

export default Routes;
