import { getById } from 'api/product';
import { imageEndpoints } from 'config/constants';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function Product() {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    const images = useMemo(() => {
        if (data?.images) {
            return JSON.parse(data.images);
        }

        return [];
    }, [data]);

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            const response = await getById(id);

            if (response) {
                setData(response);
            }
        } catch {
            toast.error('Unable to fetch Product', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title>{`${data?.name} - Admin YoRipe`}</title>
                <meta name='title' content={`${data?.name} - Admin YoRipe`} />
                <meta name='description' content={'Admin page to manage all existing Products and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>View Product</div>
                    {isLoading && <div className='challenge-header'><Spinner animation='border' variant='red-1' /></div>}
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Seller Name</div>
                        <div className='challenge-section-content'>{data?.seller?.name}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Equipment Name</div>
                        <div className='challenge-section-content'>{data?.equipment?.name}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Ingredient Name</div>
                        <div className='challenge-section-content'>{data?.ingredient?.name ?? 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Recipe Publisher</div>
                        <div className='challenge-section-content'>{data?.recipe_publisher?.name ?? 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Product Name</div>
                        <div className='challenge-section-content'>{data?.name ?? 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Price ({data?.currency === 'Rp' ? 'IDR' : 'SGD'})</div>
                        <div className='challenge-section-content'>{data?.price ?? 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Description</div>
                        <p className='challenge-section-content'>{data?.description}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Product Link</div>
                        <a href={data?.link} rel='noreferrer' target='_blank'
                            className='challenge-section-content'>{data?.link ?? 'null'}</a>
                    </div>
                    <div className='data-section-heading'>Product Dynamic</div>
                    {data?.dynamic_sections.map((section, index) => {
                        return (
                            <>
                                <div key={index} className='challenge-section'>
                                    <div className='challenge-section-title'>Title {index + 1}</div>
                                    <p className='challenge-section-content'>{section?.title}</p>
                                </div>
                                <div className='challenge-section'>
                                    <div className='challenge-section-title'>Description {index + 1}</div>
                                    <p className='challenge-section-content'>{section?.body ? JSON.parse(section?.body).join(', ') : ''}</p>
                                </div>
                            </>
                        );
                    })}

                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Linked Recipes</div>
                        <p className='challenge-section-content'>{data?.recipes}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Image</div>
                        <div className='image-grid-container'>{images?.map((img, index) => <img key={index} src={imageEndpoints.brandPortalProductUrl + img} alt='recipe media' />)}</div>
                    </div>
                    <div className='challenge-footer'>
                        <Link to={`/product-edit/${data?.id}`}><Button variant='brand-red mx-2'>Edit Product</Button></Link>
                        <Button variant='brand-gray mx-2' onClick={history.goBack}>Back</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(Product);
