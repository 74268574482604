import { createReview, getById, updateReview } from 'api/review';
import CookingStory from 'components/CookingStory';
import React, { memo, useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import DataForm from './components/DataForm';

function StoryForm() {
    const [showPreview, setShowPreview] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const [parsedData, setParsedData] = useState({});
    const { id } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();
    const { register, watch, setValue, clearErrors, trigger, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            title: '',
            recipe_id: null,
            recipe: null,
            photos: [],
            comment: '',
            challenges: []
        }
    });

    const fields = watch();

    useEffect(() => {
        register('photos', { required: 'This field is Required' });
        register('title', { required: 'This field is Required' });
        register('comment', { required: 'This field is Required' });
        register('recipe_id');
        register('recipe');
        register('challenges');
    }, [register]);

    const fetchData = useCallback(async () => {
        try {
            const data = await getById(id);

            if (data) {
                setValue('id', id);
                setValue('photos', data?.photos ?? []);
                setValue('title', data?.title);
                setValue('comment', data?.comment);
                setValue('recipe', data?.recipe);
                setValue('challenges', data?.challenges);
                setValue('url', data?.url);
            }
        } catch {
            toast.error('Unable to fetch Cooking story', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }, [id, setValue]);

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [fetchData, id]);

    const parseStoryData = useCallback(() => {
        const parsedData = {
            title: fields?.title,
            photos: fields?.photos,
            comment: fields?.comment,
            challenges: fields?.challenges,
            recipe: fields?.recipe
        };

        return parsedData;
    }, [fields]);

    const previewHandler = useCallback(async () => {
        setParsedData(parseStoryData());
        setShowPreview(true);
    }, [parseStoryData]);

    const saveHandler = useCallback(async () => {
        clearErrors();

        const isValid = await trigger(['title', 'photos', 'comment']);

        if (isValid) {
            previewHandler();
            setIsValidated(true);
        }
    }, [clearErrors, previewHandler, trigger]);

    const editSaveHandler = useCallback(async () => {
        try {
            setIsSubmitting(true);
            const submitData = {
                title: fields?.title,
                photos: fields?.photos,
                comment: fields?.comment,
                challenges: fields?.challenges?.map((challenge) => challenge.id),
                recipe_id: fields?.recipe?.id
            };

            const data = await updateReview(id, submitData);

            if (data.id) {
                toast.success('Thank you. Your Changes has been saved.', {
                    position: toast.POSITION.BOTTOM_LEFT
                });

                history.push(`/cooking-story/${data.id}`);
            }
        } catch {
            toast.error('Unable to save the changes ', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsSubmitting(false);
        }
    }, [fields?.challenges, fields?.comment, fields?.photos, fields?.recipe?.id, fields?.title, history, id]);

    const submitReviewHandler = useCallback(async () => {
        if (id) {
            editSaveHandler();
        } else {
            try {
                setIsSubmitting(true);

                const submitData = {
                    title: fields?.title,
                    photos: fields?.photos,
                    comment: fields?.comment,
                    challenges: fields?.challenges?.map((challenge) => challenge.id),
                    recipe_id: fields?.recipe?.id
                };

                const data = await createReview(submitData);

                if (data.id) {
                    toast.success('Thank you. Your Story has been submitted.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    });

                    history.push(`/cooking-story/${data.id}`);
                }
            } catch {
                toast.error('Unable to publish the Story ', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            } finally {
                setIsSubmitting(false);
            }
        }
    }, [editSaveHandler, fields?.challenges, fields?.comment, fields?.photos, fields?.recipe?.id, fields?.title, history, id]);

    return (
        <>
            <Helmet>
                <title>Create Cooking Story - YoRipe</title>
                <meta name='title' content='Create Cooking Story - YoRipe' />
                <meta name='description' content={'Cooking Story Form page to manage all existing stories and create more!'} />
            </Helmet>
            <div className={`page-wrapper ${isSubmitting ? 'disabled' : ''}`}>
                {showPreview
                    ? <CookingStory
                        data={parsedData}
                        isValidated={isValidated}
                        onBack={() => setShowPreview(false)}
                        onSubmit={submitReviewHandler} />
                    : <DataForm
                        fields={fields}
                        errors={errors}
                        setValue={setValue}
                        saveHandler={saveHandler}
                        showPreview={previewHandler}/>}
            </div>
        </>
    );
}

export default memo(StoryForm);
