import { imageEndpoints } from 'config/constants';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { getParsedDate } from 'utils/helpers';

function EventPreview({ data, isValidated, onSubmit, onBack }) {
    const startDate = useMemo(() => getParsedDate(data?.start_date), [data?.start_date]);
    const endDate = useMemo(() => getParsedDate(data?.end_date), [data?.end_date]);

    return (
        <>
            <Helmet>
                <title>Event - Admin YoRipe</title>
                <meta name='title' content='Event - Admin YoRipe' />
                <meta name='description' content={'Event page to manage all existing events and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>Preview Event</div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Event Name</div>
                        <p className='challenge-section-content'>{data?.name}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Banner</div>
                        {data?.banner_image
                            ? <img className='banner-image-form' src={imageEndpoints.eventsUrl + data?.banner_image} alt='recipe media' />
                            : <p className='challenge-section-content'>null</p>}
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Event Description</div>
                        <p className='challenge-section-content'>{data?.description}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Language</div>
                        <p className='challenge-section-content'>{!data?.language && 'All'}{data?.language === 'en' && 'English'}{data?.language === 'id' && 'Indo'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Category</div>
                        <p className='challenge-section-content'>{data?.category?.split(' ').map((item, index) => <span key={index} className='challenge-category'>{item}</span>)}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Event Link</div>
                        {data?.website_link
                            ? <a className='challenge-section-content' href={data?.website_link} target='_blank'
                                rel='noreferrer'>{data?.website_link}</a>
                            : <p className='challenge-section-content'>null</p>}
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Event Embed Link</div>
                        {data?.embed_link
                            ? <a className='challenge-section-content' href={data?.embed_link} target='_blank'
                                rel='noreferrer'>{data?.embed_link}</a>
                            : <p className='challenge-section-content'>null</p>}
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Event Button Text</div>
                        <p className='challenge-section-content'>{data?.button_text}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Event Button Link</div>
                        {data?.group_link
                            ? <a className='challenge-section-content' href={data?.group_link} target='_blank'
                                rel='noreferrer'>{data?.group_link}</a>
                            : <p className='challenge-section-content'>null</p>}
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Start Time</div>
                        <p className='challenge-section-content'>{startDate}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>End Time</div>
                        <p className='challenge-section-content'>{endDate}</p>
                    </div>
                    <div className='challenge-footer'>
                        <Button variant='brand-red mx-2' onClick={onSubmit} disabled={!isValidated}>Save Event</Button>
                        <Button variant='brand-gray mx-2' onClick={onBack}>Back to Form</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

EventPreview.propTypes = {
    data: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isValidated: PropTypes.bool.isRequired
};

export default memo(EventPreview);
