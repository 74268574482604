import { AdminApi } from './api';

/**
 * Get searched Challenges
 * @param {String} query
 * @param {Number} page
 * @param {Number} perPage
 * @returns {Promise}
 */
export function searchChallenge(query, page, perPage = 15) {
    return AdminApi.get('/v1/challenge/search', { params: { query, page, perPage } });
}

/**
 * Search challenge submissions
 * @param {Number} id
 * @param {String} query
 * @returns {Promise}
 */
export function searchSubmissions(id, query) {
    return AdminApi.get(`/v1/challenge/${id}/submissions`, { params: { query } });
}

/**
 * Get challenge winners
 * @param {Number} id
 * @returns {Promise}
 */
export function getWinners(id) {
    return AdminApi.get(`/v1/challenge/${id}/winners`);
}

/**
 * Delete by ids
 * @param {Array} ids
 * @returns {Promise}
 */
export function challengesDelete(ids) {
    return AdminApi.post('/v1/challenge/delete', { ids });
}

/**
 * Get One By Id
 * @param {Number} id
 * @returns {Promise}
 */
export function getById(id) {
    return AdminApi.get(`/v1/challenge/${id}`);
}

/**
 * Notify winners
 * @param {Number} id
 * @returns {Promise}
 */
export function notifyWinners(id) {
    return AdminApi.post('/v1/challenge/notify-winners', { id });
}

/**
 * Create Review
 * @param {Object} data
 * @returns {Promise}
 */
export function createChallenge(data) {
    return AdminApi.post('/v1/challenge/', data);
}

/**
 * Create Review
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateChallenge(id, data) {
    return AdminApi.post(`/v1/challenge/update/${id}`, data);
}
