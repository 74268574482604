import axios from 'axios';
import { APP_URL, imageEndpoints, LARAVEL_API } from 'config/constants';

const { rootS3Bucket } = imageEndpoints;

/**
 * Upload image to backend
 * @param {Object} image - { uri, fileType }
 * @returns {Promise}
 */
export const uploadImage = async (image) => {
    const preSignedUrl = await getUploadURL({
        extention: 'png',
        contentType: 'image/png'
    });

    await s3Upload(preSignedUrl.data.url, image);

    return `${rootS3Bucket}${preSignedUrl.data.filename}`;
};

/**
 * Upload image to backend
 * @param {String} directory - the directory to upload the file to
 * @param {Object} image - { uri, fileType }
 * @returns {Promise}
 */
export const uploadImageDir = async (directory, image) => {
    const preSignedUrl = await uploadUrlDir({
        directory,
        extention: 'png',
        contentType: 'image/png'
    });

    await s3Upload(preSignedUrl.data.url, image);

    return `${preSignedUrl.data.key}.png`;
};

/**
 * Upload image to backend
 * @param {Object} video - { uri, fileType }
 * @returns {Promise}
 */
export const uploadVideo = async (video) => {
    const preSignedUrl = await getUploadURL({
        extention: video.extention,
        contentType: video.contentType
    });

    await s3Upload(preSignedUrl.data.url, video.uri);

    return `${rootS3Bucket}${preSignedUrl.data.filename}`;
};

/**
 * Upload a file to backend
 * @param {String} fileUri - local file system uri of selected file
 * @returns {Promise}
 */
export function uploadFile(fileUri) {
    return handleUpload(fileUri, 'upload/file');
}

/**
 * Handle the uploading of a file
 * @param {String} uri
 * @param {String} endPoint - the api end point to where the file needs to be uploaded
 * @param {String} fileName
 * @param {String} fileType - mime-type of file to upload
 * @returns {Promise}
 */
async function handleUpload(uri, endPoint, fileName, fileType) {
    const formData = new FormData();

    formData.append('file', {
        uri,
        name: fileName,
        type: fileType
    });

    const response = await fetch(APP_URL + endPoint, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data'
        },
        credentials: 'include',
        body: formData
    });

    if (response.ok) {
        const contentType = response.headers.get('content-type');

        if (contentType && contentType.indexOf('application/json') === -1) {
            return response.text();
        }

        return response.status === 204 ? true : response.json();
    } else {
        const error = new Error(response.statusText);

        error.response = response;
        throw error;
    }
}

/**
 * Get s3 pre-signed URL
 * @param {String} extention
 * @param {String} contentType
 * @returns {Promise}
 */
export function getUploadURL({ extention, contentType }) {
    return axios.get(`${LARAVEL_API}upload/get-upload-url`, { params: { extention, contentType } });
}

/**
 * Upload Image to S3
 * @param {String} uploadUrl
 * @param {String} fileUri
 * @returns {Promise}
 */
export async function s3Upload(uploadUrl, fileUri) {
    const resp = await fetch(fileUri);
    const imageBody = await resp.blob();

    return fetch(uploadUrl, {
        method: 'PUT',
        body: imageBody
    });
}

/**
 * Upload Image to S3
 * @param {Object} data
 * @returns {Promise}
 */
export async function uploadUrlDir(data) {
    return axios.get(`${LARAVEL_API}upload/to-directory`, { params: data });
}
