/* eslint-disable no-useless-escape */
import { imageEndpoints, units } from 'config/constants';
import { startsWithHttp } from 'config/regexPatterns';

/**
 * Imported publishers and regular user publishers has different `image` structure and directory on S3.
 * Due to new functionality recipe upload, regular user can now upload their recipes directly into the app.
 * @param {Object} recipe
 * @returns {String}
 */
export function getRecipePublisherImage(recipe) {
    if (recipe?.publisher?.user?.avatar) {
        return recipe.publisher.user.avatar;
    }

    if (!recipe?.publisher?.image) {
        return null;
    }

    if (recipe?.is_uploaded) {
        const containsHttps = startsWithHttp.test(recipe.publisher?.image);

        return containsHttps ? recipe.publisher?.image : imageEndpoints.publishersUrl + recipe.publisher?.image;
    }

    return imageEndpoints.publishersUrl + recipe.publisher?.image;
}

/**
 * Imported recipe images and app submitted recipes has different `image` structure and directory on S3.
 * @param {Boolean} isUploaded
 * @param {String} recipeImage
 * @param {String} images
 * @returns {String}
 */
export function getRecipeImage(isUploaded, recipeImage, images) {
    if (images) {
        const photos = JSON.parse(images);

        return photos[0];
    }

    if (!recipeImage) {
        return null;
    }

    if (isUploaded) {
        const containsHttps = startsWithHttp.test(recipeImage);

        return containsHttps ? recipeImage : imageEndpoints.recipeUrl + recipeImage;
    }

    return imageEndpoints.recipeUrl + recipeImage;
}

export function getRecipeImages(isUploaded, recipeImage, images, thumbnail) {
    if (images) {
        const photos = JSON.parse(images);

        if (thumbnail) {
            return [...photos, thumbnail];
        }

        return photos;
    }

    if (thumbnail) {
        return [thumbnail];
    }

    if (!recipeImage) {
        return null;
    }

    if (isUploaded) {
        const containsHttps = startsWithHttp.test(recipeImage);

        return containsHttps ? [recipeImage] : [imageEndpoints.recipeUrl + recipeImage];
    }

    return [imageEndpoints.recipeUrl + recipeImage];
}

export const parseRawIngredients = async(ingredients) => {
    const ingridientArr = ingredients.split('\n');
    const arr = [];

    ingridientArr?.forEach((item, index) => {
        const valid = /[a-zA-Z]/.test(item);

        if (!valid) {
            return;
        }

        let commentRaw = item.replace(/[•\t+]/g, '').trim();
        const patt1 = /\d+[\.,]\d+|\d+\b|\d+(?=\w)/g;
        const patt2 = /\d[\/]\d/g;
        let serving = '';
        let digits = '';
        let fraction = '';
        let name = '';

        let comment = '';
        const parseComment = commentRaw.match(/\(([^)]+)\)/);

        if (parseComment) {
            comment = parseComment[1];
            commentRaw = commentRaw.replace(parseComment[0], '');
        }

        // ingredientList.forEach(element => {
        //     if (commentRaw.includes(element) && element.length > name.length) {
        //         name = element;
        //     }
        // });

        // commentRaw = commentRaw.replace(name, '');

        // servings.forEach((elem) => {
        //     if (commentRaw.includes(elem) && elem.length > serving.length) {
        //         serving = elem;
        //     }
        // });

        // commentRaw = commentRaw.replace(serving, '');

        if (patt2.test(commentRaw)) {
            const frac = (commentRaw.match(patt2) || []).shift();
            const split = frac.split('/');
            const result = parseInt(split[0], 10) / parseInt(split[1], 10);

            if (result) {
                fraction = result;
                commentRaw = commentRaw.replace(frac, '');
            }
        }

        if (patt1.test(commentRaw)) {
            const dig = (commentRaw.match(patt1) || []).shift();

            digits = dig.replace(',', '.');

            if (digits && fraction) {
                digits = parseFloat((parseInt(digits, 10) + fraction)?.toFixed(3));
            }

            commentRaw = commentRaw.replace(dig, '');
        } else if (fraction) {
            digits = parseFloat(fraction?.toFixed(3));
        }

        commentRaw.split(' ').forEach(elem => {
            if (units.includes(elem) && elem.length > serving.length) {
                serving = elem;
            }
        });

        commentRaw = commentRaw.replace(serving, '').trim();

        if (serving !== '' && name !== '') {
            if (commentRaw !== '') {
                comment = comment ? comment + ', ' + commentRaw : commentRaw;
            }
        } else if (name !== '') {
            if (commentRaw !== '') {
                serving = commentRaw;
            }
        } else {
            if (commentRaw !== '') {
                name = commentRaw;
            }
        }

        if (name === '') {
            return;
        }

        arr.push({ quantity: digits, serving, name, comment, index, id: 0 });
    });

    return arr;
};

/**
 * Configure recipe ingredients for edit
 * @param {Array} ingredientArray
 * @returns {Array}
 */
export function configureRecipeIngredients(ingredientArray) {
    const parsedArr = [];
    let lastSection = '';

    ingredientArray?.forEach((item) => {
        if (item.section) {
            if (item.section !== lastSection) {
                lastSection = item.section;
                parsedArr.push({ section: item.section });
            }

            const newObj = Object.assign(item, { section: null });

            parsedArr.push(newObj);
        } else {
            parsedArr.push(item);
        }
    });

    return parsedArr;
}

export const validateInstructions = {
    validate: value => {
        if (value && value.length > 0) {
            return value.every((data) => data !== '') ? true : 'Please enter a step';
        }

        return 'Please enter a step';
    }
};

/**
 * Parse Ingredients on Recipe Upload
 * @param {Array} ingredientArray
 * @returns {Array}
 */
export function parseRecipeIngredients(ingredientArray) {
    const arr = JSON.parse(JSON.stringify(ingredientArray)); // deepcopy array
    const parsedArr = [];
    let lastSection = null;

    if (arr[arr.length - 1]?.section) {
        arr.splice(arr.length - 1, 1);
    }

    arr.forEach((item) => {
        if (item.section) {
            lastSection = item.section;
        // eslint-disable-next-line no-empty
        } else if (item.section === '') {

        } else {
            const newObj = Object.assign(item, { section: lastSection });

            parsedArr.push(newObj);
        }
    });

    return parsedArr;
}

export const validateIngredients = {
    validate: value => {
        if (value && value.length > 0) {
            const isSectionValidated = value.every((data) => {
                return data.section === undefined ? true : data.section !== '';
            });

            if (!isSectionValidated) {
                return 'Please enter a section title or delete it';
            }

            // eslint-disable-next-line no-unneeded-ternary
            const isIngredientValidated = value.some((data) => data.section ? false : true);

            if (!isIngredientValidated) {
                return 'Please enter a ingredient';
            }

            const isUnitError = value.some((data) => data.serving ? data.unitError : false);

            if (isUnitError) {
                return 'Please fix the ingredients unit';
            }

            return true;
        }

        return 'Please enter a ingredient';
    }
};

export const validateOrganizer = {
    validate: data => {
        if (data?.name && !data?.image) {
            return 'Please upload an image for the organizer';
        } else if (!data?.name && data?.image) {
            return 'Please enter a name for the organizer';
        }

        return true;
    }
};

const getDecimalPart = (num) => {
    if (Number.isInteger(num)) {
        return 0;
    }

    const decimalStr = num.toString().split('.')[1];

    return Number(decimalStr);
};

export const decimalToFraction = (decimal) => {
    if (decimal) {
        const int = parseFloat(decimal);
        const integer = Math.floor(int);
        const _decimal = getDecimalPart(int);
        const integerStr = integer > 0 ? integer : '';

        switch (_decimal) {
            case (0):
                return `${integerStr}`;
            case (1):
                return `${integerStr} 1/10`;
            case (125):
                return `${integerStr} 1/8`;
            case (167):
                return `${integerStr} 1/6`;
            case (2):
                return `${integerStr} 1/5`;
            case (25):
                return `${integerStr} 1/4`;
            case (3):
                return `${integerStr} 3/10`;
            case (333):
                return `${integerStr} 1/3`;
            case (375):
                return `${integerStr} 3/8`;
            case (4):
                return `${integerStr} 2/5`;
            case (5):
                return `${integerStr} 1/2`;
            case (6):
                return `${integerStr} 3/5`;
            case (625):
                return `${integerStr} 5/8`;
            case (667):
                return `${integerStr} 2/3`;
            case (7):
                return `${integerStr} 7/10`;
            case (75):
                return `${integerStr} 3/4`;
            case (8):
                return `${integerStr} 4/5`;
            case (833):
                return `${integerStr} 5/6`;
            case (875):
                return `${integerStr} 7/8`;
            case (9):
                return `${integerStr} 9/10`;
            default:
                return `${int}`;
        }
    } else {
        return decimal;
    }
};
