import './index.scss';

import { getById, getWinners } from 'api/challenge';
import ConfirmPopup from 'components/ConfirmPopup';
import { imageEndpoints } from 'config/constants';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getParsedDate } from 'utils/helpers';

function Challenge() {
    const { id } = useParams();
    const [challenge, setChallenge] = useState(null);
    const [winners, setWinners] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSending, setSending] = useState(false);
    const history = useHistory();

    const { equalWinners, rankedWinners, topLiked } = useMemo(() => {
        const equalWinners = [];
        const rankedWinners = [];
        const topLiked = [];

        winners.forEach(item => {
            const isRecipe = item.recipe_id !== null;
            const placeholder = isRecipe ? `${item.recipe?.recipe_publisher} | ${item.recipe?.recipename}` : `${item.review?.user?.name} | ${item.review?.comment}`;

            switch (item.winner_category) {
                case 'Equal Winners':
                    equalWinners.push({ placeholder: placeholder, rank: item.rank, winner_category: item.winner_category, id: isRecipe ? item.recipe_id : item.review_id, type: isRecipe ? 'recipe' : 'review' });
                    break;

                case 'Ranking':
                    rankedWinners.push({ placeholder: placeholder, rank: item.rank, winner_category: item.winner_category, id: isRecipe ? item.recipe_id : item.review_id, type: isRecipe ? 'recipe' : 'review' });
                    break;

                case 'Top Votes':
                    topLiked.push({ placeholder: placeholder, rank: item.rank, winner_category: item.winner_category, id: isRecipe ? item.recipe_id : item.review_id, type: isRecipe ? 'recipe' : 'review' });
                    break;

                default:
                    break;
            }
        });

        return { equalWinners, rankedWinners, topLiked };
    }, [winners]);

    const fetchWinners = useCallback(async (id) => {
        try {
            setIsLoading(true);

            const winrs = await getWinners(id);

            if (winrs) {
                setWinners(winrs);
            }
        } catch {
            toast.error('Unable to fetch Challenge Winners', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsLoading(false);
        }
    }, []);

    const fetchChallenge = useCallback(async () => {
        try {
            setIsLoading(true);

            const response = await getById(id);

            if (response) {
                setChallenge(response);
                fetchWinners(response.id);
            }
        } catch {
            toast.error('Unable to fetch Challenge', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsLoading(false);
        }
    }, [fetchWinners, id]);

    const notifyWinners = async () => {
        try {
            setSending(true);
            await notifyWinners(challenge.id);

            toast.success('Notifications sent to all Winners', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } catch {
            toast.error('Unable to send Notifications', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setShowModal(false);
            setSending(false);
        }
    };

    useEffect(() => {
        fetchChallenge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title>{`${challenge?.hashtag} - Admin YoRipe`}</title>
                <meta name='title' content={`${challenge?.hashtag} - Admin YoRipe`} />
                <meta name='description' content={'Challenge Form page to manage all existing challenges and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    {winners?.length === 0 && <div className='winners-form-title'>
                        View Challenge
                        <Link className='choose-winners-button' to={`/challenge-edit/${challenge?.id}?winners=true`}><Button variant='brand-red mx-2'>+ Choose Winners</Button></Link>
                    </div>}
                    {isLoading && <div className='challenge-header'><Spinner animation='border' variant='red-1' /></div>}
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Sharing Description</div>
                        <div className='challenge-section-content'>{challenge?.name}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Hashtag</div>
                        <div className='challenge-section-content'>{challenge?.hashtag}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Organizer Name</div>
                        <div className='challenge-section-content'>{challenge?.organizer?.name}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Organizer Image</div>
                        <img className='challenge-section-media' src={imageEndpoints.organizerUrl + challenge?.organizer?.image} alt={challenge?.organizer?.name} />
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Image</div>
                        <img className='challenge-section-media' src={imageEndpoints.challengeUrl + challenge?.image} alt={challenge?.hashtag} />
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Language</div>
                        <p className='challenge-section-content'>{!challenge?.language && 'All'}{challenge?.language === 'en' && 'English'}{challenge?.language === 'id' && 'Indo'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Type</div>
                        <div className='challenge-section-content'>{challenge?.type === '1' && 'Cooking Story'}{challenge?.type === '2' && 'Recipe'}{challenge?.type === '3' && 'Recipe & Cooking Story'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Category</div>
                        <div className='challenge-section-content'>{challenge?.category?.split(' ').map((item, index) => <span key={index} className='challenge-category'>{item}</span>)}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Short Description</div>
                        <p className='challenge-section-content'>{challenge?.short_description}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Rewards</div>
                        {challenge?.rewards ? <div className='challenge-section-content'>{JSON.parse(challenge?.rewards).map((reward, index) => <><span key={index}>&bull; {reward}</span><br/></>)}</div> : <div className='challenge-section-content'>No rewards</div>}
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Description</div>
                        <p className='challenge-section-content'>{challenge?.description}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Promo Code</div>
                        <div className='challenge-section-content'>{challenge?.promo_code ?? 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Promo Information</div>
                        <div className='challenge-section-content'>{challenge?.promo_info ?? 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Promo Site</div>
                        {challenge?.promo_website ? <a href={challenge?.promo_website} className='challenge-section-content'>{challenge?.promo_website}</a> : <div className='challenge-section-content'>null</div>}
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Start Date</div>
                        <div className='challenge-section-content'>{challenge?.start_date ? getParsedDate(challenge.start_date) : 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>End Date</div>
                        <div className='challenge-section-content'>{challenge?.end_date ? getParsedDate(challenge.end_date) : 'null'}</div>
                    </div>
                    {winners?.length > 0 && <div className='winners-form-title'>
                        Winners List
                        <Link className='edit-winners-button' to={`/challenge-edit/${challenge?.id}?winners=true`} >Edit Winners</Link>
                    </div>}

                    {equalWinners?.length > 0 && <><div className='challenge-section'>
                        <div className='challenge-section-title'>Category</div>
                        <div className='challenge-section-content'>Equal winners</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Winners</div>
                        <div className='challenge-section-content'>{equalWinners?.map((item, index) => <><span key={index}><strong>&bull;</strong> {item.placeholder}</span><br/></>)}</div>
                    </div></>}

                    {rankedWinners?.length > 0 && <><div className='challenge-section'>
                        <div className='challenge-section-title'>Category</div>
                        <div className='challenge-section-content'>Ranking</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Winners</div>
                        <div className='challenge-section-content'>{rankedWinners?.map((item, index) => <><span key={index}>{index + 1}. {item.placeholder}</span><br/></>)}</div>
                    </div></>}

                    {topLiked?.length > 0 && <><div className='challenge-section'>
                        <div className='challenge-section-title'>Category</div>
                        <div className='challenge-section-content'>Top Votes</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Winners</div>
                        <div className='challenge-section-content'>{topLiked?.map((item, index) => <><span key={index}>{index + 1}. {item.placeholder}</span><br/></>)}</div>
                    </div></>}

                    <div className='challenge-footer space-between'>
                        <div className='flex-center'>
                            <Button variant='brand-gray mx-2' onClick={history.goBack}>Back</Button>
                            <Link to={`/challenge-edit/${challenge?.id}`}><Button variant='brand-red mx-2'>Edit Challenge</Button></Link>
                        </div>
                        <Button variant='brand-red-hollow' disabled={isSending} onClick={() => setShowModal(true)}>Notify participants</Button>
                    </div>
                </div>
                <ConfirmPopup
                    isVisible={showModal}
                    onClose={() => setShowModal(false)}
                    confirmTitle='Yes, notify participants'
                    isLoading={isSending}
                    onConfirm={notifyWinners}
                    title='Are you sure want notify all participants?'
                    description='Once you notify, YoRipe app will send a notification to all participants (including the winner) of this challenge.'
                    type={2} />
            </div>
        </>
    );
}

export default memo(Challenge);
