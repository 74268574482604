import { AdminApi } from './api';

/**
 * Get searched products
 * @param {String} query
 * @param {Number} page
 * @param {Number} perPage
 * @returns {Promise}
 */
export function searchProduct(query, page, perPage = 15) {
    return AdminApi.get('/v1/products/search', { params: { query, page, perPage } });
}

/**
 * Delete by ids
 * @param {Array} ids
 * @returns {Promise}
 */
export function productsDelete(ids) {
    return AdminApi.post('/v1/products/delete', { ids });
}

/**
 * Get One By Id
 * @param {Number} id
 * @returns {Promise}
 */
export function getById(id) {
    return AdminApi.get(`/v1/products/${id}`);
}

/**
 * Create products
 * @param {Object} data
 * @returns {Promise}
 */
export function createProduct(data) {
    return AdminApi.post('/v1/products/', data);
}

/**
 * Create products
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateProduct(id, data) {
    return AdminApi.post(`/v1/products/update/${id}`, data);
}
