import { createSeller, getById, updateSeller } from 'api/seller';
import BrandPreview from 'components/BrandPreview';
import React, { memo, useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import DataForm from './components/DataForm';

function BannerCreate() {
    const [showPreview, setShowPreview] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const { id } = useParams();
    const [parsedData, setParsedData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();
    const { register, watch, setValue, clearErrors, trigger, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            name: '',
            description: '',
            brand_portal_logo_image: null,
            brand_portal_image: null,
            delivery_time: '',
            discount_code: '',
            language: null,
            discount_percentage: '',
            expiration_date: new Date(),
            brand_link: '',
            veg_only: 0
        }
    });

    const fields = watch();

    useEffect(() => {
        register('name', { required: 'This field is Required' });
        register('brand_portal_image', { required: 'This field is Required' });
        register('brand_portal_logo_image', { required: 'This field is Required' });
        register('description', { required: 'This field is Required' });
        register('delivery_time');
        register('discount_code');
        register('discount_percentage');
        register('expiration_date');
        register('language');
        register('brand_link', { required: 'This field is Required' });
        register('veg_only');
    }, [register]);

    const fetchData = useCallback(async () => {
        try {
            const data = await getById(id);

            if (data) {
                const expire = data?.expiration_date?.split('-').map(item => parseInt(item));
                const expirationDate = data?.expiration_date ? new Date(expire[0], expire[1] - 1, expire[2]) : new Date();

                setValue('id', id);
                setValue('name', data?.name);
                setValue('brand_portal_image', data?.brand_portal_image);
                setValue('brand_portal_logo_image', data?.brand_portal_logo_image);
                setValue('description', data?.description);
                setValue('language', data?.language);
                setValue('expiration_date', expirationDate);
                setValue('delivery_time', data?.delivery_time);
                setValue('discount_code', data?.discount_code);
                setValue('discount_percentage', data?.discount_percentage);
                setValue('brand_link', data?.brand_link);
                setValue('veg_only', data?.veg_only ? parseInt(data?.veg_only) : 0);
            }
        } catch {
            toast.error('Unable to fetch Brand portal', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }, [id, setValue]);

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [fetchData, id]);

    const parseChallengeData = useCallback(() => {
        const parsedData = {
            name: fields?.name,
            brand_portal_image: fields?.brand_portal_image,
            brand_portal_logo_image: fields?.brand_portal_logo_image,
            description: fields?.description,
            language: fields?.language,
            delivery_time: fields?.delivery_time,
            discount_code: fields?.discount_code,
            discount_percentage: fields?.discount_percentage,
            expiration_date: fields?.expiration_date,
            brand_link: fields?.brand_link,
            veg_only: `${fields?.veg_only}`
        };

        return parsedData;
    }, [fields]);

    const previewHandler = useCallback(async () => {
        setParsedData(parseChallengeData());
        setShowPreview(true);
    }, [parseChallengeData]);

    const saveHandler = useCallback(async () => {
        clearErrors();

        const isValid = await trigger(['name', 'brand_portal_image', 'brand_portal_logo_image', 'delivery_time', 'discount_code', 'discount_percentage', 'expiration_date', 'brand_link']);

        if (isValid) {
            previewHandler();
            setIsValidated(true);
        }
    }, [clearErrors, previewHandler, trigger]);

    const editSaveHandler = useCallback(async () => {
        try {
            setIsSubmitting(true);

            const data = await updateSeller(id, parsedData);

            if (data.id) {
                toast.success('Thank you. Your Changes has been saved.', {
                    position: toast.POSITION.BOTTOM_LEFT
                });

                history.push(`/brand-portal/${data.id}`);
            }
        } catch {
            toast.error('Unable to save the changes ', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsSubmitting(false);
        }
    }, [history, id, parsedData]);

    const submitBrandHandler = useCallback(async () => {
        if (id) {
            editSaveHandler();
        } else {
            try {
                setIsSubmitting(true);

                const data = await createSeller(parsedData);

                if (data.id) {
                    toast.success('Thank you. Your Brand has been submitted.', {
                        position: toast.POSITION.BOTTOM_LEFT
                    });

                    history.push(`/brand-portal/${data.id}`);
                }
            } catch {
                toast.error('Unable to publish the Brand ', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            } finally {
                setIsSubmitting(false);
            }
        }
    }, [editSaveHandler, history, id, parsedData]);

    return (
        <>
            <Helmet>
                <title>Create Brands - YoRipe</title>
                <meta name='title' content='Create Brands - YoRipe' />
                <meta name='description' content={'Brands Form page to manage all existing Brands and create more!'} />
            </Helmet>
            <div className={`page-wrapper ${isSubmitting ? 'disabled' : ''}`}>
                {showPreview
                    ? <BrandPreview
                        data={parsedData}
                        isValidated={isValidated}
                        onBack={() => setShowPreview(false)}
                        onSubmit={submitBrandHandler} />
                    : <DataForm
                        fields={fields}
                        errors={errors}
                        setValue={setValue}
                        saveHandler={saveHandler}
                        showPreview={previewHandler}/>}
            </div>
        </>
    );
}

export default memo(BannerCreate);
