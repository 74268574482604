import './index.scss';

import Profile from 'images/Profile.svg';
import { memo, useMemo } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { deleteLoginToken } from 'utils/helpers';
import { loggingOut } from 'utils/redux/modules/authentication';

const mapStateToProps = ({ authentication }) => {
    return {
        profileData: authentication.profileData
    };
};

function TopHeader() {
    const location = useLocation();
    const dispatch = useDispatch();
    const { profileData } = useSelector(mapStateToProps);
    const title = useMemo(() => {
        const path = location.pathname.split('/');

        return path[1].replace('-', ' ');
    }, [location]);

    const logoutHandler = async () => {
        await deleteLoginToken();

        dispatch(loggingOut());
    };

    const renderTitle = () => {
        return (
            <>
                <img src={profileData.avatar ?? Profile} alt='User profile' className='profile-image' />
                <span className='profile-text'>{profileData.name}</span>
            </>
        );
    };

    return (
        <div className='topheader-container'>
            <div className='topheader-title'>{title}</div>
            <div className='topheader-profile-wrapper'>
                <DropdownButton id='dropdown-item-button' title={renderTitle()}>
                    <Dropdown.Item onClick={logoutHandler} as='button'><span className='color-brand'>Log out</span></Dropdown.Item>
                </DropdownButton>
            </div>
        </div>
    );
}

export default memo(TopHeader);
