import { getById } from 'api/review';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function Story() {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            const response = await getById(id);

            if (response) {
                setData(response);
            }
        } catch {
            toast.error('Unable to fetch Cooking story', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title>{`${data?.title} - Admin YoRipe`}</title>
                <meta name='title' content={`${data?.title} - Admin YoRipe`} />
                <meta name='description' content={'Admin page to manage all existing Cooking Stories and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>View Cooking Story</div>
                    {isLoading && <div className='challenge-header'><Spinner animation='border' variant='red-1' /></div>}
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Upload Assets</div>
                        <div className='image-grid-container'>{data?.photos?.map((img, index) => <img key={index} src={img} alt='recipe media' />)}</div>
                    </div>
                    {data?.video && <div className='challenge-section'>
                        <div className='challenge-section-title'>Video</div>
                        <div className='challenge-section-video'><VideoPlayer videoUrl={data?.video} thumbnail={data?.video_thumbnail} /></div>
                    </div>}
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Title</div>
                        <p className='challenge-section-content'>{data?.title}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Caption</div>
                        <p className='challenge-section-content'>{data?.comment}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Challenge Tag</div>
                        <p className='challenge-section-content'>{data?.challenges[0]?.hashtag ?? 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Recipe Tag</div>
                        <p className='challenge-section-content'>{data?.recipe?.recipename ?? 'null'}</p>
                    </div>
                    <div className='challenge-footer'>
                        <Link to={`/cooking-story-edit/${data?.id}`}><Button variant='brand-red mx-2'>Edit Story</Button></Link>
                        <Button variant='brand-gray mx-2' onClick={history.goBack}>Back</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(Story);
