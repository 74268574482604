import { imageEndpoints } from 'config/constants';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import Helmet from 'react-helmet';

function Product({ data, isValidated, onSubmit, onBack }) {
    const images = useMemo(() => {
        return data?.images;
    }, [data]);

    const dynamicSection = useMemo(() => {
        const arr = [];

        if (data?.dynamic_title1) {
            arr.push({
                title: data?.dynamic_title1,
                body: data?.dynamic_body1
            });
        }

        if (data?.dynamic_title2) {
            arr.push({
                title: data?.dynamic_title2,
                body: data?.dynamic_body2
            });
        }

        if (data?.dynamic_title3) {
            arr.push({
                title: data?.dynamic_title3,
                body: data?.dynamic_body3
            });
        }

        if (data?.dynamic_title4) {
            arr.push({
                title: data?.dynamic_title4,
                body: data?.dynamic_body4
            });
        }

        if (data?.dynamic_title5) {
            arr.push({
                title: data?.dynamic_title5,
                body: data?.dynamic_body5
            });
        }

        return arr;
    }, [data]);

    return (
        <>
            <Helmet>
                <title>{`${data?.name} - Admin YoRipe`}</title>
                <meta name='title' content={`${data?.name} - Admin YoRipe`} />
                <meta name='description' content={'Products page to manage all existing Products and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>View Product</div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Seller Name</div>
                        <div className='challenge-section-content'>{data?.seller?.name}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Equipment Name</div>
                        <div className='challenge-section-content'>{data?.equipment?.name}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Ingredient Name</div>
                        <div className='challenge-section-content'>{data?.ingredient?.name ?? 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Recipe Publisher</div>
                        <div className='challenge-section-content'>{data?.recipe_publisher?.name ?? 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Product Name</div>
                        <div className='challenge-section-content'>{data?.name ?? 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Price ({data?.currency === 'Rp' ? 'IDR' : 'SGD'})</div>
                        <div className='challenge-section-content'>{data?.price ?? 'null'}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Description</div>
                        <p className='challenge-section-content'>{data?.description}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Product Link</div>
                        <a href={data?.link} rel='noreferrer' target='_blank'
                            className='challenge-section-content'>{data?.link ?? 'null'}</a>
                    </div>
                    <div className='data-section-heading'>Product Dynamic</div>
                    {dynamicSection.map((section, index) => {
                        return (
                            <>
                                <div key={index} className='challenge-section'>
                                    <div className='challenge-section-title'>Title {index + 1}</div>
                                    <p className='challenge-section-content'>{section?.title}</p>
                                </div>
                                <div className='challenge-section'>
                                    <div className='challenge-section-title'>Description {index + 1}</div>
                                    <p className='challenge-section-content'>{section?.body}</p>
                                </div>
                            </>
                        );
                    })}

                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Linked Recipes</div>
                        <p className='challenge-section-content'>{data?.recipes}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Image</div>
                        <div className='image-grid-container'>{images?.map((img, index) => <img key={index} src={imageEndpoints.brandPortalProductUrl + img} alt='recipe media' />)}</div>
                    </div>
                    <div className='challenge-footer'>
                        <Button variant='brand-red mx-2' onClick={onSubmit} disabled={!isValidated}>Save Product</Button>
                        <Button variant='brand-gray mx-2' onClick={onBack}>Edit Form</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

Product.propTypes = {
    data: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isValidated: PropTypes.bool.isRequired
};

export default memo(Product);
