import { useFetchChallengeSubmissions } from 'hooks/challenge';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { RiDeleteBinFill } from 'react-icons/ri';

function ordinalSuffixOf(i) {
    const j = i % 10;
    const k = i % 100;

    if (j === 1 && k !== 11) {
        return i + 'st';
    }

    if (j === 2 && k !== 12) {
        return i + 'nd';
    }

    if (j === 3 && k !== 13) {
        return i + 'rd';
    }

    return i + 'th';
}

function WinnerSelect(props) {
    const [showList, setShowList] = useState(false);
    const [query, setQuery] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const { data: submissions } = useFetchChallengeSubmissions(props.challengeId, query);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setQuery(searchQuery);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [searchQuery]);

    const selectHandler = useCallback((data) => {
        const placeholder = data.submission_type === 'recipe' ? `${data.recipe_publisher} | ${data.recipename}` : `${data.user?.name} | ${data.comment}`;

        props.winnerUpdate({ ...props.data, is_recipe: data.submission_type === 'recipe', id: data.id, placeholder }, props.index);
        setSearchQuery(placeholder);
        setShowList(false);
    }, [props]);

    useEffect(() => {
        if (props.data?.winner_category === 'Top Votes' && searchQuery === '' && !props.data?.id) {
            if (submissions?.length > props.index) {
                selectHandler(submissions[props.index]);
            } else {
                props.winnerDelete(props.index);
            }
        }
    }, [submissions, props, searchQuery, selectHandler]);

    useEffect(() => {
        setSearchQuery(props.data?.placeholder ?? '');
    }, [props.data?.placeholder]);

    const renderSubmissionList = useCallback((item) => {
        return <Dropdown.Item disabled={props.selectedWinners?.includes(item.id)} key={item.id} onClick={() => selectHandler(item)}>{item.submission_type === 'recipe' ? `${item.recipe_publisher} | ${item.recipename}` : `${item.user?.name} | ${item.comment}`}</Dropdown.Item>;
    }, [props.selectedWinners, selectHandler]);

    const renderTitle = () => {
        switch (props.data?.winner_category) {
            case 'Equal Winners':
                return 'Winner';

            case 'Ranking':
                return `Winner ${props.index + 1}`;

            case 'Top Votes':
                return `${ordinalSuffixOf(props.index + 1)} Top Votes`;

            default:
                break;
        }
    };

    return (
        <Form.Group className='mb-3' controlId='challengeHashtag'>
            <Form.Label className='form-label'>{renderTitle()}</Form.Label>
            <div className='winner-dropdown'>

                <div className='winner-dropdown-wrapper'><Dropdown onToggle={setShowList}>
                    <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>
                        <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                            className='organizer-input'
                            type='text'
                            placeholder='Choose name | title of the submission' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu show={showList}>{submissions?.map(renderSubmissionList)}</Dropdown.Menu>
                </Dropdown></div>
                <RiDeleteBinFill className='hoverable' onClick={() => props.winnerDelete(props.index)} color='#C2C2C2'
                    size={25} />
            </div>
        </Form.Group>
    );
}

WinnerSelect.propTypes = {
    winnerUpdate: PropTypes.func.isRequired,
    challengeId: PropTypes.number.isRequired,
    winnerDelete: PropTypes.func.isRequired,
    selectedWinners: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
};

export default memo(WinnerSelect);
