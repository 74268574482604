import { searchOrganizers } from 'api/misc';
import { uploadImageDir } from 'api/upload';
import ConfirmPopup from 'components/ConfirmPopup';
import Media from 'components/Media';
import { challengeCategories, imageEndpoints } from 'config/constants';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { AiFillEye } from 'react-icons/ai';
import { BiCalendarEvent } from 'react-icons/bi';
import { BsDashLg, BsFillImageFill } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import Resizer from 'react-image-file-resizer';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import WinnersCategory from './WinnersCategory';

function ChallengeForm(props) {
    const [organizers, setOrganizers] = useState([]);
    const [showList, setShowList] = useState(false);
    const organizerImageRef = useRef();
    const challengeImageRef = useRef();
    const [showModal, setShowModal] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const history = useHistory();
    const search = useLocation().search;
    const winnerFormRef = useRef();
    const showWinners = new URLSearchParams(search).get('winners');

    useEffect(() => {
        if (showWinners) {
            winnerFormRef.current?.scrollIntoView({ behavious: 'smooth' });
        }
    }, [showWinners]);

    const selectedWinners = useMemo(() => {
        let winnerIds = [];

        props.winnersList?.forEach((item) => {
            const arr = item?.winners?.map(winr => winr.id);

            winnerIds = [...winnerIds, ...arr];
        });

        return winnerIds;
    }, [props.winnersList]);

    const fetchOrganizers = useCallback(async (query) => {
        try {
            const organzr = await searchOrganizers(query, 1, 15);

            setOrganizers(organzr);

            if (query) {
                setShowList(true);
            }
        } catch {
            toast.error('Unable to fetch Organizers', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchOrganizers(props.fields?.organizer?.name);
        }, 500);

        return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fields?.organizer?.name]);

    const organizerChangeHandler = useCallback((e) => {
        props.setValue('organizer', { name: e.target.value, image: '' });
    }, [props]);

    const addCategory = (category) => {
        props.setCategories((prev) => [...prev, category]);
    };

    const removeCategory = useCallback((category) => {
        props.setCategories((prev) => prev.filter((item) => item !== category));
    }, [props]);

    const uploadOrganizerImage = useCallback(async (image) => {
        try {
            setUploading(true);

            const uploadResponse = await uploadImageDir('organizer', image);

            props.setValue('organizer', { ...props.fields.organizer, image: uploadResponse });
        } finally {
            setUploading(false);
        }
    }, [props]);

    const uploadChallengeImage = useCallback(async (image) => {
        try {
            setUploading(true);

            const uploadResponse = await uploadImageDir('challenges', image);

            props.setValue('image', uploadResponse);
        } finally {
            setUploading(false);
        }
    }, [props]);

    const organizerImageHandler = useCallback(async (event) => {
        if (event.target.files[0]) {
            try {
                Resizer.imageFileResizer(event.target.files[0], 2048, 2048, 'PNG', 85, 360, (uri) => uploadOrganizerImage(uri));
            } catch (err) {
            }
        }
    }, [uploadOrganizerImage]);

    const challengeImageHandler = useCallback(async (event) => {
        if (event.target.files[0]) {
            try {
                Resizer.imageFileResizer(event.target.files[0], 2048, 2048, 'PNG', 85, 360, (uri) => uploadChallengeImage(uri));
            } catch (err) {
            }
        }
    }, [uploadChallengeImage]);

    const renderCategories = useCallback(() => {
        if (props.categories.length === 0) {
            return <span className='challenge-type'>Choose Challenge Category</span>;
        }

        return props.categories.map((category, index) => (
            <div className='selected-category' key={index}>
                <span>{category}</span>
                <MdClose color='#fff' size={18} onClick={() => removeCategory(category)} />
            </div>
        ));
    }, [props.categories, removeCategory]);

    const renderType = useCallback(() => {
        if (props.currentType === 0) {
            return <div className='challenge-type'>Choose Challenge Type</div>;
        } else if (props.currentType === 1) {
            return <div className='challenge-type selected-type-text'>Cooking Story</div>;
        } else if (props.currentType === 2) {
            return <div className='challenge-type selected-type-text'>Recipe</div>;
        } else if (props.currentType === 3) {
            return <div className='challenge-type selected-type-text'>Recipe & Cooking Story</div>;
        }
    }, [props.currentType]);

    const organizerSelectHandler = useCallback((organizer) => {
        props.setValue('organizer', organizer);
        props.setValue('challenge_organizer_id', organizer.id);
        setShowList(false);
    }, [props]);

    const renderOrganizers = useCallback(() => {
        if (organizers.length === 0) {
            return <span className='challenge-type'>Choose Challenge Category</span>;
        }

        return organizers.map((organizer) => {
            return (
                <Dropdown.Item key={organizer.id} onClick={() => organizerSelectHandler(organizer)}>{organizer.name}</Dropdown.Item>
            );
        });
    }, [organizerSelectHandler, organizers]);

    const renderLanguage = useCallback(() => {
        if (props.fields?.language === 'en') {
            return <div className='challenge-type selected-type-text'>English</div>;
        } else if (props.fields?.language === 'id') {
            return <div className='challenge-type selected-type-text'>Indo</div>;
        } else {
            return <div className='challenge-type selected-type-text'>All</div>;
        }
    }, [props.fields?.language]);

    const renderWinnerCategory = (item, index) => {
        return <WinnersCategory
            challengeId={props.fields?.id}
            setWinnersList={props.setWinnersList}
            selectedWinners={selectedWinners}
            winnersList={props.winnersList}
            key={index}
            item={item}
            index={index} />;
    };

    return (
        <div className='form-wrapper'>
            <Form>
                <Form.Group className='mb-3' controlId='challengeName'>
                    <Form.Label className='form-label'>Sharing Description</Form.Label>
                    <input value={props.fields?.name} onChange={(e) => props.setValue('name', e.target.value)}
                        className='form-input' type='text' placeholder='Enter sharing description' />
                    {props.errors?.name?.message && <Form.Text className='form-error-message'>{props.errors?.name?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Hashtag <span className='form-label-info'>(Only special charachter allowed is "#" as prefix)</span></Form.Label>
                    <input value={props.fields?.hashtag} onChange={(e) => props.setValue('hashtag', e.target.value)}
                        className='form-input' type='text' placeholder='Enter Hashtag' />
                    {props.errors?.hashtag?.message && <Form.Text className='form-error-message'>{props.errors?.hashtag?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Organizer</Form.Label>
                    <Dropdown onToggle={setShowList}>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>
                            <input value={props.fields?.organizer?.name} onChange={organizerChangeHandler}
                                className='organizer-input'
                                type='text'
                                placeholder='Choose Origanizer' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu show={showList}>
                            {renderOrganizers()}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Form.Text className='text-muted'>If left empty, YoRipe is the default organizer.</Form.Text>
                </Form.Group>

                {props.fields?.organizer?.name && <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Organizer Image <span className='form-label-info'>(Less than 1mb, 1:1 or Square)</span></Form.Label>
                    {props.fields?.organizer?.image
                        ? <Media onClose={() => props.setValue('organizer', { ...props.fields.organizer, image: '' })} src={imageEndpoints.organizerUrl + props.fields?.organizer?.image} alt='organizer'
                            className='form-input-image' />
                        : <div onClick={() => organizerImageRef.current.click()} className={`image-placeholder-wrapper hoverable ${isUploading ? 'disabled' : ''}`}>
                            <BsFillImageFill color='#bdbdbd' size={40} />
                            <span>Upload imgae.</span>
                        </div>}
                    <input type='file' multiple={false} ref={organizerImageRef}
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={organizerImageHandler} />
                    {props.errors?.organizer?.message && <Form.Text className='form-error-message'>{props.errors?.organizer?.message}</Form.Text>}
                </Form.Group>}

                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Image <span className='form-label-info'>(Less than 1mb, 1:1 or Square)</span></Form.Label>
                    {props.fields?.image
                        ? <Media onClose={() => props.setValue('image', '')} src={imageEndpoints.challengeUrl + props.fields?.image} alt='Challenge'
                            className='form-input-image' />
                        : <div onClick={() => challengeImageRef.current.click()} className={`image-placeholder-wrapper hoverable ${isUploading ? 'disabled' : ''}`}>
                            <BsFillImageFill color='#bdbdbd' size={40} />
                            <span>Upload imgae.</span>
                        </div>}
                    <input type='file' multiple={false} ref={challengeImageRef}
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={challengeImageHandler} />
                    {props.errors?.image?.message && <Form.Text className='form-error-message'>{props.errors?.image?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeType'>
                    <Form.Label className='form-label'>Language</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>{renderLanguage()}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => props.setValue('language', null)}><span className={!props.fields?.language ? 'color-brand-imp' : ''}>All</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setValue('language', 'en')}><span className={props.fields?.language === 1 ? 'color-brand-imp' : ''}>English</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setValue('language', 'id')}><span className={props.fields?.language === 2 ? 'color-brand-imp' : ''}>Indo</span></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeType'>
                    <Form.Label className='form-label'>Type</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>{renderType()}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => props.setCurrentType(1)}><span className={props.currentType === 1 ? 'color-brand-imp' : ''}>Cooking Story</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setCurrentType(2)}><span className={props.currentType === 2 ? 'color-brand-imp' : ''}>Recipe</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setCurrentType(3)}><span className={props.currentType === 3 ? 'color-brand-imp' : ''}>Recipe & Cooking Story</span></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {props.typeError && <Form.Text className='form-error-message'>{props.typeError}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeCategory'>
                    <Form.Label className='form-label'>Category</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>{renderCategories()}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {challengeCategories.map((category, index) => <Dropdown.Item key={index} onClick={() => addCategory(category.name)}><span className={props.categories.includes(category.name) ? 'color-brand-imp' : ''}>{category.name}</span></Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                    {props.categoryError && <Form.Text className='form-error-message'>{props.categoryError}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeShortDescription'>
                    <Form.Label className='form-label'>Short Description</Form.Label>
                    <textarea value={props.fields?.short_description} onChange={(e) => props.setValue('short_description', e.target.value)}
                        rows={2} type='text'
                        className='form-textarea'
                        placeholder='Write Short Description' />
                    {props.errors?.short_description?.message && <Form.Text className='form-error-message'>{props.errors?.short_description?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeRewards'>
                    <Form.Label className='form-label'>Rewards <span className='form-label-info'>(Use bullet points, one reward per line)</span></Form.Label>
                    <textarea value={props.fields?.rewards} onChange={(e) => props.setValue('rewards', e.target.value)}
                        rows={2} type='text'
                        className='form-textarea'
                        placeholder='Enter Reward ' />
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeShortDescription'>
                    <Form.Label className='form-label'>Description <span className='form-label-info'>(Numbered list on how to join)</span></Form.Label>
                    <textarea value={props.fields?.description} onChange={(e) => props.setValue('description', e.target.value)}
                        rows={5} type='text'
                        className='form-textarea'
                        placeholder='Write Description' />
                    {props.errors?.description?.message && <Form.Text className='form-error-message'>{props.errors?.description?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Promo Code</Form.Label>
                    <input value={props.fields?.promo_code} onChange={(e) => props.setValue('promo_code', e.target.value)}
                        className='form-input' type='text' placeholder='Enter Promo Code' />
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Promo Information</Form.Label>
                    <input value={props.fields?.promo_info} onChange={(e) => props.setValue('promo_info', e.target.value)}
                        className='form-input' type='text' placeholder='Enter Promo Information' />
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Promo Site <span className='form-label-info'>(Optional)</span></Form.Label>
                    <input value={props.fields?.promo_website} onChange={(e) => props.setValue('promo_website', e.target.value)}
                        className='form-input' type='text' placeholder='Enter Promotional Website' />
                </Form.Group>

                <div className='dates-input-wrapper'>
                    <Form.Group className='mb-3' controlId='challengeHashtag'>
                        <Form.Label className='form-label'>Start Date</Form.Label>
                        <div className='date-wrapper'>
                            <DatePicker className='date-wrapper-input' selected={props.fields?.start_date}
                                onSelect={(date) => props.setValue('start_date', date)}
                                onChange={(date) => props.setValue('start_date', date)} />
                            <BiCalendarEvent size={18} color='#838383' />
                        </div>
                        {props.errors?.start_date?.message && <Form.Text className='form-error-message'>{props.errors?.start_date?.message}</Form.Text>}
                    </Form.Group>
                    <BsDashLg size={18} color='#838383' />
                    <Form.Group className='mb-3' controlId='challengeHashtag'>
                        <Form.Label className='form-label'>End Date</Form.Label>
                        <div className='date-wrapper'>
                            <DatePicker className='date-wrapper-input' selected={props.fields?.end_date}
                                onSelect={(date) => props.setValue('end_date', date)}
                                onChange={(date) => props.setValue('end_date', date)} />
                            <BiCalendarEvent size={18} color='#838383' />
                        </div>
                    </Form.Group>
                </div>

                {props.winnersList?.length > 0 && <div ref={winnerFormRef} className='winners-form-title'>Add Challenge Winners</div>}
                {props.winnersList?.map(renderWinnerCategory)}
            </Form>
            <div className='form-footer'>
                <Button variant='brand-red-hollow' onClick={props.showPreview} ><AiFillEye color='#FF7268' />  Preview</Button>
                <div>
                    <Button variant='brand-gray mx-2' onClick={() => setShowModal(true)}>Cancel</Button>
                    <Button variant='brand-red mx-2' onClick={props.saveHandler}>Save Challenge</Button>
                </div>
            </div>
            <ConfirmPopup
                isVisible={showModal}
                onClose={() => setShowModal(false)}
                confirmTitle='Leave'
                onConfirm={history.goBack}
                title='Unsaved Changes'
                description='Are you sure want to discard this changes? Changes you made may not be saved.'
                type={1} />
        </div>
    );
}

ChallengeForm.propTypes = {
    fields: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    saveHandler: PropTypes.func.isRequired,
    categoryError: PropTypes.string,
    categories: PropTypes.array.isRequired,
    setCategories: PropTypes.func.isRequired,
    currentType: PropTypes.number.isRequired,
    setCurrentType: PropTypes.func.isRequired,
    winnersList: PropTypes.array.isRequired,
    setWinnersList: PropTypes.func.isRequired,
    typeError: PropTypes.string,
    showPreview: PropTypes.func.isRequired
};

export default memo(ChallengeForm);
