import { AdminApi } from './api';

/**
 * Get One By Id
 * @param {Number} id
 * @returns {Promise}
 */
export function getAll() {
    return AdminApi.get('/v1/occasions/all');
}

/**
 * Get searched occasion
 * @param {String} query
 * @param {Number} page
 * @param {Number} perPage
 * @returns {Promise}
 */
export function searchOccasion(query, page, perPage = 15) {
    return AdminApi.get('/v1/occasions/search', { params: { query, page, perPage } });
}

/**
 * Delete by ids
 * @param {Array} ids
 * @returns {Promise}
 */
export function occasionsDelete(ids) {
    return AdminApi.post('/v1/occasions/delete', { ids });
}

/**
 * Get One By Id
 * @param {Number} id
 * @returns {Promise}
 */
export function getById(id) {
    return AdminApi.get(`/v1/occasions/${id}`);
}

/**
 * Create occasion
 * @param {Object} data
 * @returns {Promise}
 */
export function createOccasion(data) {
    return AdminApi.post('/v1/occasions/', data);
}

/**
 * Create occasion
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateOccasion(id, data) {
    return AdminApi.post(`/v1/occasions/update/${id}`, data);
}
