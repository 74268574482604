import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

import authentication from './modules/authentication';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    authentication
});

const store = createStore(appReducer, applyMiddleware(routerMiddleware(history), thunk));

export default store;
