import { AdminApi } from './api';

/**
 * Get searched brands
 * @param {String} query
 * @param {Number} page
 * @param {Number} perPage
 * @returns {Promise}
 */
export function searchSellers(query, page, perPage = 15) {
    return AdminApi.get('/v1/sellers/search', { params: { query, page, perPage } });
}

/**
 * Delete by ids
 * @param {Array} ids
 * @returns {Promise}
 */
export function sellersDelete(ids) {
    return AdminApi.post('/v1/sellers/delete', { ids });
}

/**
 * Get One By Id
 * @param {Number} id
 * @returns {Promise}
 */
export function getById(id) {
    return AdminApi.get(`/v1/sellers/${id}`);
}

/**
 * Create brands
 * @param {Object} data
 * @returns {Promise}
 */
export function createSeller(data) {
    return AdminApi.post('/v1/sellers/', data);
}

/**
 * Create brands
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateSeller(id, data) {
    return AdminApi.post(`/v1/sellers/update/${id}`, data);
}
